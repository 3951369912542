import { getCurrentYMD, ColorCodes } from '../../helpers';
import { schemaProps, stringTypeDefaultValidation } from './schemaMasterData';

export const riskFormSchema = {
  name: 'Risk',
  icon: 'add',
  client: 'tHA-HPP',
  filterField: 'probability',
  filterOptions: ['Rare', 'Unlikely', 'Possible', 'Likely', 'Almost Certain'],
  collectionPath: '/projects/#/risks',
  subCollection: 'risks',
  isSubCollection: true,
  requiresProjects: true,
  hasProjectsDropdown: true,
  hasElementItems: true,
  schema: {
    name: {
      label: 'Risk Name',
      name: 'name',
      ...schemaProps,
      sm: 4,
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter risk name',
        },
      },
    },
    description: {
      label: 'Risk Description',
      name: 'description',
      ...schemaProps,
      sm: 4,
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter risk description',
        },
      },
    },
    probability: {
      label: 'Probability',
      name: 'probability',
      ...schemaProps,
      select: true,
      fieldIndex: 0,
      sm: 4,
      options: ['Rare', 'Unlikely', 'Possible', 'Likely', 'Almost Certain'],
      chipStyle: (chipItem) => {
        return {
          padding: '0px',
          fontSize: '1em',
          color: 'white',
          background: Object.keys(ColorCodes)
            .filter((key) => key.includes(chipItem))
            .reduce((obj, key) => {
              return ColorCodes[key];
            }, ''),
        };
      },
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter risk probability',
        },
      },
    },
    impact: {
      label: 'Impact',
      name: 'impact',
      ...schemaProps,
      select: true,
      fieldIndex: 1,
      options: ['Insignificant', 'Minor', 'Medium', 'High', 'Severe'],
      chipStyle: (chipItem) => {
        return {
          padding: '0px',
          fontSize: '1em',
          color: 'white',
          background: Object.keys(ColorCodes)
            .filter((key) => key.includes(chipItem))
            .reduce((obj, key) => {
              return ColorCodes[key];
            }, ''),
        };
      },
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter risk impact',
        },
      },
    },
    mitigation: {
      label: 'Mitigation',
      name: 'mitigation',
      ...schemaProps,
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter risk mitigation',
        },
      },
    },
    owner: {
      label: 'Owner',
      name: 'owner',
      ...schemaProps,
      type: 'dropdown',
      fieldIndex: 2,
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter risk owner',
        },
        minLength: {
          value: 1,
          message: 'Please select at least one risk owner',
        },
      },
    },
    riskDate: {
      label: 'Date',
      name: 'riskDate',
      ...schemaProps,
      type: 'date',
      fieldIndex: 3,
      defaultValue: getCurrentYMD(),
      InputLabelProps: { shrink: true },
      InputProps: {
        inputProps: {
          min: '1970-01-01',
          max: '',
        },
      },
      validation: {
        required: { value: true, message: 'Please pick the risk date' },
      },
    },
  },
};
