import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  MenuItem,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Icon,
} from '@material-ui/core';
import { TabPanel } from './TabPanel';
import FormBuilder from '../form_builder/FormBuilder';
import Loading from '../app/components/Loading';
import { projectFormSchema } from '../form_builder/configs/projectFormSchema';
import { taskFormSchema } from '../form_builder/configs/taskFormSchema';
import { riskFormSchema } from '../form_builder/configs/riskFormSchema';
import FormSubmissionViewer from '../form_submission_viewer/FormSubmissionViewer';
import { getUnixTime } from '../helpers';

const globalConfigs = {
  'tHA-HPP': [projectFormSchema, taskFormSchema, riskFormSchema],
  demoClient: [],
};

const projectDetailsStyle = { margin: '20px 0px 15px 8px', padding: '15px' };

export const AddElementItem = ({ onAddNewItem, itemText }) => (
  <div onClick={onAddNewItem} style={{ display: 'flex', marginTop: '25px' }}>
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
      }}
    >
      <Icon>add</Icon>
      {itemText}
    </div>
  </div>
);

export const ProjectTracker = (props) => {
  const { dimensions, user, logServiceMount, logServiceUnmount, firestore } =
    props.appContext;
  const client = user.client;
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [users, setUsers] = useState({});
  const [projects, setProjects] = useState({});
  const [projectSchema, setProjectSchema] = useState({});
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [selectedProjectDetails, setSelectedProjectDetails] = useState({});
  const [selectedId, setSelectedId] = useState('');
  const [configs, setConfigs] = useState([]);
  const sortedProjectSchema = [];

  useEffect(() => {
    const unixTimeMount = getUnixTime();
    firestore
      .collection('users')
      .where('client', '==', client)
      .get()
      .then((querySnapshot) => {
        const users = {};
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            users[doc.id] = doc.data();
          });
          setUsers({ ...users, ...users });
        }
      });
    const configsTemp = globalConfigs[client];
    setConfigs(configsTemp);
    setProjectSchema(globalConfigs[client][0].schema);
    const unsubProjectsListener = firestore
      .collection(configsTemp[tabIndex].collectionPath)
      .onSnapshot((querySnapshot) => {
        const updatedProjects = {};
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            updatedProjects[doc.id] = doc.data();
          });
          setProjects({ ...projects, ...updatedProjects });
        }
        setLoading(false);
      });

    // logServiceMount(
    //   unixTimeMount,
    //   user.uid,
    //   user.client,
    //   props.ms.componentName,
    //   {
    //     msid: props.ms.id,
    //     title: props.ms.title,
    //     tabIndex,
    //     tabName: configs[tabIndex].name,
    //   }
    // );

    return () => {
      const currentUnixTime = getUnixTime();
      logServiceUnmount(unixTimeMount, currentUnixTime, user.uid);
      unsubProjectsListener();
    };

    // logServiceMount(
    //   unixTimeMount,
    //   user.uid,
    //   user.client,
    //   props.ms.componentName,
    //   {
    //     msid: props.ms.id,
    //     title: props.ms.title,
    //     tabIndex,
    //     tabName: configs[tabIndex].name,
    //   }
    // );
  }, []);

  useEffect(() => {
    if (tabIndex > -1 && tabIndex <= configs.length) {
      console.log('Im here...');
    }

    if (selectedProjectId !== '') {
      setSelectedProjectDetails(projects[selectedProjectId]);
    }
  }, [selectedProject, tabIndex]);

  const usersList = useMemo(
    () =>
      Object.entries(users).map(([key, value]) => ({
        value: key,
        label: value.displayName,
      })),
    [users]
  );

  const handleChangeTab = (event, tabIndex) => {
    setTabIndex(tabIndex);
    setSelectedId('');
  };

  const handleSelectedProject = (event) => {
    event.preventDefault();
    setSelectedId('');
    Object.keys(projects).forEach((key) => {
      if (projects[key].name === event.target.value) {
        setSelectedProject(projects[key].name);
        setSelectedProjectId(key);
      }
    });
  };

  const addElementItem = () => {
    setSelectedId('');
    !tabIndex && setSelectedProject('');
    !tabIndex && setSelectedProjectId('');
    // TODO: Clear the form when clicked
  };

  const submitProjectData = (submissionData, docRef, selectedProjectId) => {
    if (!selectedProjectId) {
      return docRef.doc().set(submissionData);
    } else {
      return docRef.doc(selectedProjectId).set(submissionData);
    }
  };

  const submitProjectTrackerData = (
    config,
    projectData,
    collectionRef,
    setSuccess
  ) => {
    if (!config.isSubCollection) {
      submitProjectData(projectData, collectionRef, selectedProjectId);
      setSuccess(
        `${!selectedProjectId ? 'Created new' : 'Updated'} project "${
          projectData.name
        }" successfully`
      );
    } else {
      if (selectedId) {
        collectionRef
          .doc(selectedProjectId)
          .collection(config.subCollection)
          .doc(selectedId)
          .set(projectData);
      } else {
        collectionRef
          .doc(selectedProjectId)
          .collection(config.subCollection)
          .doc()
          .set(projectData);
      }
      setSuccess(
        `${config.name} "${projectData.name}" in project "${selectedProject}" ${
          selectedId === '' ? 'created' : 'updated'
        } successfully`
      );
    }
  };

  if (loading) {
    return <Loading text={`Loading ${client}`} />;
  } else {
    const projectArray = Object.keys(projects).map((key) => projects[key]);
    Object.keys(projectSchema).forEach((projectKey) => {
      if (projectSchema[projectKey].fieldIndex > -1) {
        sortedProjectSchema[projectSchema[projectKey].fieldIndex] =
          projectSchema[projectKey];
      }
    });
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '50px',
          overflow: 'auto',
          width: dimensions.mainAreaWidth,
          height: dimensions.mainAreaHeight,
        }}
      >
        <div style={{ width: '80%' }}>
          <AppBar position='static' color='default'>
            <Tabs
              value={tabIndex}
              onChange={handleChangeTab}
              indicatorColor='primary'
              textColor='primary'
            >
              {configs.map(({ name, icon, requiresProjects }) => (
                <Tab
                  key={name}
                  label={name}
                  icon={<Icon>{icon}</Icon>}
                  disabled={
                    requiresProjects && Object.keys(projects).length < 1
                  }
                />
              ))}
            </Tabs>
          </AppBar>

          {configs.map((config, index) => {
            return (
              <TabPanel key={index} tabValue={tabIndex} index={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  {config.client === client && config.hasProjectsDropdown && (
                    <Grid
                      style={{ padding: '5px 5px', marginTop: '20px' }}
                      item
                      xs={12}
                      sm={4}
                    >
                      <TextField
                        fullWidth
                        select
                        label='Select Project'
                        name='projectName'
                        size='small'
                        type='text'
                        onChange={(e) => handleSelectedProject(e)}
                        value={selectedProject}
                        variant='outlined'
                        disabled={projectArray.length < 1}
                      >
                        {projectArray.map((project) => (
                          <MenuItem key={project.name} value={project.name}>
                            {project.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  {!config.hasElementItems && selectedProject && (
                    <AddElementItem
                      onAddNewItem={addElementItem}
                      itemText={`Add New ${config.name}`}
                    />
                  )}
                </div>
                {selectedProject && (
                  <>
                    <Paper style={projectDetailsStyle}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {sortedProjectSchema.map((projectDetail) => {
                          return (
                            <label key={projectDetail.label}>
                              <b>{projectDetail.label}: </b>
                              {!Array.isArray(
                                selectedProjectDetails[projectDetail.name]
                              )
                                ? selectedProjectDetails[projectDetail.name]
                                : selectedProjectDetails[projectDetail.name]
                                    .map((item) => item)
                                    .join('; ')}
                            </label>
                          );
                        })}
                      </div>
                    </Paper>
                  </>
                )}
                {selectedProject && tabIndex && (
                  <FormSubmissionViewer
                    appContext={props.appContext}
                    ptConfig={config}
                    users={usersList}
                    selectedProjectId={selectedProjectId}
                  />
                )}

                {!tabIndex && (
                  <FormBuilder
                    appContext={props.appContext}
                    config={config}
                    users={usersList}
                    submitProjectData={submitProjectData}
                    projectData={selectedProjectDetails}
                    submitProjectTrackerData={submitProjectTrackerData}
                  />
                )}
              </TabPanel>
            );
          })}
        </div>
      </div>
    );
  }
};

ProjectTracker.propTypes = {
  appContext: PropTypes.object.isRequired,
};
