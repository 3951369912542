import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
});

const TextFieldComponent = (props) => {
  const {
    fieldName,
    fieldObject,
    isAutoChange,
    submissionValue,
    setSubmissionWithEvent,
    textFieldExtraProps,
    errorValue
  } = props;

  // console.log(`In Text Field "${fieldName}"`);
  // console.log(props);

  return (
    <Grid
      item
      xs={fieldObject.xs}
      sm={fieldObject.sm}
    >
      <TextField
        onChange={setSubmissionWithEvent}
        value={submissionValue}
        disabled={fieldObject.disabled || isAutoChange}
        size={fieldObject.size}
        variant={fieldObject.variant}
        fullWidth={fieldObject.fullWidth}
        multiline={fieldObject.multiline}
        label={!fieldObject.type.includes('date') && fieldObject.label}
        error={Boolean(errorValue)}
        helperText={errorValue}
        {...textFieldExtraProps}
      />
    </Grid>
  );
};


TextFieldComponent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldObject: PropTypes.object.isRequired,
  isAutoChange: PropTypes.bool.isRequired,
  submissionValue: PropTypes.string.isRequired,
  setSubmissionWithEvent: PropTypes.func.isRequired,
  textFieldExtraProps: PropTypes.object.isRequired,
  errorValue: PropTypes.string.isRequired,
};

export default withStyles(styles)(TextFieldComponent);
