import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  Icon,
  Popover,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DropdownComponent from '../form_builder/DropdownComponent';
import RadioGroupComponent from '../form_builder/RadioGroupComponent';
import FormEngine from '../app/components/FormEngine';

const styles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'centre',
  },
  title: {
    flex: '0 0 auto',
  },
  popover: {
    padding: (theme.spacing.unit * 5),
    width: '50%'
  },
  actionLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  clearButton: {
    marginTop: (theme.spacing.unit * 3),
  },
});

const EnhancedTableToolbar = (props) => {
  const {
    appContext,
    fieldsObject,
    filterObject,
    setFilterObject,
    handleCreateNew,
    handleDelete,
    disableDelete,
    tableTitle,
    numSelected,
    classes,
  } = props;

  const [filterPopupAnchorEl, setFilterPopupAnchorEl] = useState(null);
  const [filterErrors, setFilterErrors] = useState({});

  const filterPopupOpen = Boolean(filterPopupAnchorEl);
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {tableTitle}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
      <span className={classes.actionLabel}>
          <Typography color="inherit">
            Add
          </Typography>
        </span>
        <Tooltip title="Add">
          <IconButton
            aria-label="Add"
            onClick={handleCreateNew}
          >
            <Icon>
              add
            </Icon>
          </IconButton>
        </Tooltip>
        <span className={classes.actionLabel}>
          <Typography color="inherit">
            {numSelected > 0 && !disableDelete ? 'Delete' : 'Filter'}
          </Typography>
        </span>
        {numSelected > 0 && !disableDelete ? (
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete"
              onClick={handleDelete}
            >
              <Icon>
                delete
              </Icon>
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton
              onClick={(event) => setFilterPopupAnchorEl(event.currentTarget)}
              aria-label="Filter list"
            >
              <Icon>
                filter_list
              </Icon>
            </IconButton>
          </Tooltip>
        )}
        <Popover
          id="simple-popper"
          open={filterPopupOpen}
          anchorEl={filterPopupAnchorEl}
          onClose={() => setFilterPopupAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ paper: classes.popover }}
        >
          <FormEngine
            appContext={appContext}
            existingFields={fieldsObject}
            existingSubmission={filterObject}
            setExistingSubmission={setFilterObject}
          />
          {/* <Grid container spacing={16}>
            <Grid item container justify='space-between'>
              <Typography color="inherit" variant="h5">
                Filter Data
              </Typography>
              <Tooltip title="Close Filter">
                <IconButton
                  onClick={(event) => setFilterPopupAnchorEl(null)}
                  aria-label="Close Filter"
                >
                  <Icon fontSize='large'>
                    close
                  </Icon>
                </IconButton>
              </Tooltip>
            </Grid>
            {fieldsObject &&
              Object.keys(fieldsObject).length > 0 &&
              Object.entries(fieldsObject).map(([fieldName, fieldObject]) => {

                const updateErrors = () => {
                  const filterErrorsTemp = JSON.parse(JSON.stringify(filterErrors));
                  delete filterErrorsTemp[fieldName];
                  setFilterErrors(filterErrorsTemp);
                };

                const updateFilterObject = (event) => {
                  updateErrors();
                  const filterObjectTemp = JSON.parse(JSON.stringify(filterObject));
                  const oldValue = filterObjectTemp[fieldName];
                  filterObjectTemp[fieldName] = event.target.value;
                  // console.log(`Old value: "${oldValue}" | New value "${filterObjectTemp[fieldName]}" | test: ${oldValue === filterObjectTemp[fieldName]}`)
                  if (filterObjectTemp[fieldName] === '' || filterObjectTemp[fieldName] === oldValue) {
                    delete filterObjectTemp[fieldName];
                  }
                  setFilterObject(filterObjectTemp);
                };

                const isControlled = fieldObject.hasOwnProperty('controlField') &&
                  fieldObject.controlField &&
                  fieldObject.controlType;
                const isAutoChange = isControlled && fieldObject.controlType === 'autoChange';
                const fieldObjectProperties = {
                  fieldName,
                  fieldObject: {
                    ...fieldObject,
                    xs: 12,
                    sm: 12,
                    defaultLabel: `Filter ${fieldObject.label}`,
                  },
                  isAutoChange,
                  isControlled,
                  submissionValue: filterObject.hasOwnProperty(fieldName) ? filterObject[fieldName] : '',
                  updateSubmissionValue: updateFilterObject,
                  errorValue: filterErrors.hasOwnProperty(fieldName) ? filterErrors[fieldName] : '',
                };

                if (fieldObject.type.includes('text')) {
                  // TODO: add search
                  return <></>;
                } else if (fieldObject.type.includes('date')) {
                  // TODO: add start and and date selection
                  // TODO: change date fields to be a Date object or unix time integer so we can easily preform operations on it (maybe?)
                  return <></>;
                } else if (fieldObject.type === 'dropdown') {
                  return (<DropdownComponent {...fieldObjectProperties} isControlled={false} />);
                } else if (fieldObject.type.includes('radio')) {
                  return (<RadioGroupComponent {...fieldObjectProperties} />);
                } else {
                  // TODO: add switches
                  // TODO: add sliders
                  // TODO: add checkboxes
                  console.log(`Unknown type: ${fieldObject.type}`);
                  return <></>;
                }
              })
            }
          </Grid> */}
          {/* <Button className={classes.clearButton} onClick={() => setFilterObject({})}>Clear Filter</Button> */}
        </Popover>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  appContext: PropTypes.object.isRequired,
  fieldsObject: PropTypes.object.isRequired,
  filterObject: PropTypes.object.isRequired,
  setFilterObject: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func,
  handleDelete: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool.isRequired,
  tableTitle: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTableToolbar);
