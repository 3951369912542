import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Scrollbars } from 'react-custom-scrollbars';
import { DatePicker } from 'material-ui-pickers';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import Loading from '../app/components/Loading';
import ErrorPage from '../app/components/ErrorPage';
import MultiSelectDropdown from './MultiSelectDropdown';

// TODO
// Fix remove all on uncheck

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  temporalDiv: {
    display: 'flex',
    width: '80%',
    height: '90px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  temporalPaper: {
    flexGrow: 1,
    display: 'flex',
    height: '90px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  temporalSelectionDiv: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  column: {
    maxWidth: '33.33%',
    flexBasis: '33.33%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.sizes.itemSeparation,
  },
  frequencyDiv: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listPaper:{
    width: '80%',
  },
  headingDiv: {
    marginTop: theme.sizes.itemSeparation,
    display: 'flex',
    width: '80%',
    height: '36px',
    flexDirection: 'row',
  },
  footer: {
    width: '80%',
    height: '36px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.sizes.itemSeparation,
  },
  csvLink: {
    textDecoration: 'none',
    color: 'white',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  helper: {
    borderRight: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  divider: {
    margin: theme.sizes.itemHeadingMargin,
  },
  detailsHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  detailsFooter: {
    marginTop: theme.sizes.itemSeparation,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  spaceBetweenColumn: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexStartColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexEndColumn: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  checkbox: {
    height: '100%',
  },
  selectionDiv: {
    flexGrow: 1,
    width: '100%',
    marginTop: theme.sizes.itemSeparation,
  },
  warningCaption: {
    fontSize: '1rem',
  }
});

class DownloadData extends React.Component {
  state = {
    user: this.props.appContext.user,
    apiUrl: this.props.appContext.apiUrl,
    earliestSelectionDate: this.props.appContext.earliestSelectionDate,
    startDate: moment().startOf('week'),
    endDate: moment().startOf('day'),
    frequency: 'D',
    selectionList: [],
    error: false,
    errorCode: null,
    loading: true,
    loadingText: 'Loading Metadata',
    data: undefined,
    expanded: null,
  };

//Dont allow end date to be after current time
  handleStartDateChange = (date) => {
    this.setState({startDate: moment(date)});
  };

  handleEndDateChange = (date) => {
    this.setState({endDate: moment(date)});
  };

  handleFrequencyChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleExpandedChange = panel => (event, expanded) => {
    this.setState({expanded: expanded ? panel : false});
  };

  handleChecked = (index) => () => {
    let newSelectionList = this.state.selectionList.slice(0);
    if (newSelectionList[index].numValues > 0) {
      if (newSelectionList[index].selectedValues === newSelectionList[index].numValues) {
        newSelectionList[index] = {...newSelectionList[index], selectedValues: [], values: newSelectionList[index].originalValues};
      } else {
        newSelectionList[index] = {...newSelectionList[index], selectedValues: newSelectionList[index].originalValues, values: []};
      }
    } else {
      newSelectionList[index] = {...newSelectionList[index], selectedValues: !newSelectionList[index].selectedValues};
    }
    this.setState({selectionList: newSelectionList});
  };

  handleAddAll = (index) => () => {
    let newSelectionList = this.state.selectionList.slice(0);
    newSelectionList[index] = {...newSelectionList[index], selectedValues: newSelectionList[index].originalValues, values: []};
    this.setState({selectionList: newSelectionList});
  };

  handleRemoveAll = (index) => () => {
    let newSelectionList = this.state.selectionList.slice(0);
    newSelectionList[index] = {...newSelectionList[index], selectedValues: [], values: newSelectionList[index].originalValues};
    this.setState({selectionList: newSelectionList});
  };

  handleAdd = (index, field) => () => {
    let newSelectionList = this.state.selectionList.slice(0);
    newSelectionList[index] = {...newSelectionList[index], selectedValues: [...newSelectionList[index].selectedValues, field], values: newSelectionList[index].values.filter(elem => elem !== field)};
    this.setState({selectionList: newSelectionList});
  };

  handleRemove = (index, field) => () => {
    let newSelectionList = this.state.selectionList.slice(0);
    if (newSelectionList[index].values.indexOf(field) === -1) {
      newSelectionList[index] = {...newSelectionList[index], selectedValues: newSelectionList[index].selectedValues.filter(elem => elem !== field), values: [...newSelectionList[index].values, field]};
    } else {
      newSelectionList[index] = {...newSelectionList[index], selectedValues: newSelectionList[index].selectedValues.filter(elem => elem !== field)};
    }
    this.setState({selectionList: newSelectionList});
  };

  handleSelectAdd = index => value => {
    let newSelectionList = this.state.selectionList.slice(0);
    newSelectionList[index] = {...newSelectionList[index], selectedValues: value.map(elem => elem.value)};
    this.setState({selectionList: newSelectionList});
  };

  handleSelectRemove = index => value => {
    let newSelectionList = this.state.selectionList.slice(0);
    newSelectionList[index] = {...newSelectionList[index], selectedValues: value.map(elem => elem.value)};
    this.setState({selectionList: newSelectionList});
  };

  formatWeekSelectLabel = (date, invalidLabel) => {
    if (date === null) {
      return '';
    } else if (date instanceof moment) {
      return date.format('D MMMM YYYY');
    } else {
      return invalidLabel;
    }
  };

  requestCSVData = () => {
    const {user, startDate, endDate, frequency, selectionList, apiUrl} = this.state;
    const requestBody = {
      client: user.client,
      temporal: {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        frequency : frequency,
      },
      dimensions: selectionList.filter(elem => elem.type === 'DIMENSION' && elem.selectedValues.length > 0).map(elem => ({name: elem.name, filters: elem.selectedValues.length === elem.numValues ? [] : elem.selectedValues})),
      measures: selectionList.filter(elem => elem.type === 'MEASURE' && elem.selectedValues).map(elem => ({name: elem.name})),
    };
    this.setState({loading: true, loadingText: 'Downloading selected data'});
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      fetch(`${apiUrl}/download/results`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify(requestBody),
      })
      .then(this.handleError)
      .then(response => response.json().then(responseJSON => {
        this.setState({loading: false, loadingText: ''});
        if (responseJSON.csv.length > 0) {
          const csv = responseJSON.csv;
          const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
          saveAs(blob, 'data.csv');
        } else {
          alert('Your query returned 0 rows');
        }
      }))
      .catch(({error, errorCode}) => this.setState({error, errorCode, loading: false, loadingText: ''}));
    })
    .catch(({error, errorCode}) => this.setState({error, errorCode, loading: false, loadingText: ''}));
    this.serverTimeout();
  };

  serverTimeout = () => {
    if (this.state.loading) {
      this.requestTimeout = undefined;
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        const {loading, error} = this.state;
        if (loading && !error) {
          this.setState({
            error: 'The request to Download Data API has timed out.',
            errorCode: 408,
            loading: false,
            loadingText: '',
          });
        }
      }, 45000);
    }
  };

  handleError = (response) => {
    if (!response.ok) {
      if (response.statusText.endsWith('was not found in location US')) {
        this.props.appContext.checkClient();
        const errorObj = {error: 'Client Error'};
        throw errorObj;
      } else {
        const errorObj = {
          error: response.statusText,
          errorCode: response.status,
        };
        throw errorObj;
      }
    }
    return response;
  };

  processMetadata = (metadataList) => {
    const selectionList = metadataList.filter(elem => elem.type !== 'TEMPORAL').map(elem => ({
      ...elem,
      numValues: elem.values.length,
      originalValues: elem.values,
      selectedValues: elem.values.length > 0 ? [] : false,
      multiSelectValues: elem.values.map(field => ({label: field, value: field}))
    }));
    this.setState({selectionList, loading: false, loadingText: ''});
  };

  componentDidMount() {
    const {user, apiUrl}= this.state;
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      fetch(`${apiUrl}/download/metadata`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify({client: user.client})
      })
      .then(this.handleError)
      .then(response => response.json().then(responseJSON => this.processMetadata(responseJSON.rows)))
      .catch(({error, errorCode}) => {
        if (error === 'Client Error') {
          this.setState({loadingText: 'Updating Client'});
        } else if (error && errorCode) {
          this.setState({error, errorCode, loading: false, loadingText: ''});
        } else {
          this.setState({error: 'Download data API not available', errorCode: 503, loading: false, loadingText: ''});
        }
      });
    })
    .catch(({error, errorCode}) => this.setState({error, errorCode, loading: false, loadingText: ''}));
    this.serverTimeout();
  };

  componentWillUnmount() {
    clearTimeout(this.requestTimeout);
  };

  render() {
    const {classes, appContext} = this.props;
    const {dimensions} = appContext;
    
    const {startDate, endDate, frequency, selectionList, error, errorCode, loading, expanded, earliestSelectionDate, loadingText} = this.state;
    
    const numScrollbarItems = selectionList.length;
    const availableScrollbarSize = (dimensions.height - ( dimensions.topPanel + 36 + 90 + 36 + 36 + (dimensions.itemSeparation * 4)));
    let minScrollbarSize = numScrollbarItems * 48; //Harcoded need to fix!
    if (availableScrollbarSize < minScrollbarSize) {
      minScrollbarSize = availableScrollbarSize;
    }
    if (!error && !loading && selectionList.length > 0) {
      return (
        <div className={classes.container}>
          <div className={classes.headingDiv}>
            <Typography variant="headline"  className={classes.text}>{'Time Selection'}</Typography>
          </div>
          <div className={classes.temporalDiv}>
            <Paper className={classes.temporalPaper}>
              <div className={classes.temporalSelectionDiv}>
                <div >
                  <DatePicker
                    label="Start"
                    value={startDate}
                    onChange={this.handleStartDateChange}
                    maxDate={moment().format('YYYY-MM-DD')}
                    minDate={earliestSelectionDate}
                    labelFunc={this.formatWeekSelectLabel}
                  />
                </div>
                <div>
                  <DatePicker
                    label="End"
                    value={endDate}
                    onChange={this.handleEndDateChange}
                    maxDate={moment().format('YYYY-MM-DD')}
                    minDate={earliestSelectionDate}
                    labelFunc={this.formatWeekSelectLabel}
                  />
                </div>
                <div className={classes.frequencyDiv}>
                  <InputLabel htmlFor="freq-select">Frequency</InputLabel>
                  <Select
                    value={frequency}
                    onChange={this.handleFrequencyChange}
                    inputProps={{
                      name: 'frequency',
                      id: 'freq-select',
                    }}
                  >
                    <MenuItem value='D'>Daily</MenuItem>
                    <MenuItem value='W'>Weekly</MenuItem>
                    <MenuItem value='M'>Monthly</MenuItem>
                    <MenuItem value='Q'>Quarterly</MenuItem>
                    <MenuItem value='Y'>Yearly</MenuItem>
                  </Select>
                </div>
              </div>
            </Paper>
          </div>
          <div className={classes.headingDiv}>
            <div className={classes.column}>
              <Typography variant="headline"  className={classes.text}>{'Data Selection'}</Typography>
            </div>
            <div className={classes.column}>
              <Typography variant="headline"  className={classes.text}>{'Type'}</Typography>
            </div>
          </div>
          <Paper className={classes.listPaper}>
            <Scrollbars
              autoHeight
              autoHeightMin={`${minScrollbarSize}px`}
              renderThumbVertical={props => <div {...props} className={classes.scrollbar}/>}
              renderThumbHorizontal={props => <div {...props} className={classes.scrollbar}/>}
            >
              {selectionList.map((value, index) => {
                const name = 'panel' + index;
                return (
                  <ExpansionPanel expanded={expanded === name} onChange={value.type === 'MEASURE' ? this.handleChecked(index) : this.handleExpandedChange(name)}>
                    <ExpansionPanelSummary expandIcon={value.type === 'MEASURE' ? () => {} : <Icon color='primary'>expand_more</Icon>}>
                      <div className={classes.column}>
                        <Typography className={classes.heading}>{value.name.toUpperCase()}</Typography>
                      </div>
                      <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>{value.type}</Typography>
                      </div>
                      
                        {value.originalValues.length > 0 && value.selectedValues.length > 0 ?
                          <div className={classes.spaceBetweenColumn}>
                            <Typography variant="subheading"  className={classes.text}>{value.selectedValues.length > 1 ? value.selectedValues.length + ' Fields' : '1 Field' }</Typography>
                            <Checkbox
                              checked={value.selectedValues === true || value.selectedValues.length === value.numValues}
                              indeterminate={value.selectedValues.length > 0 && value.selectedValues.length < value.numValues}
                              onChange={this.handleChecked(index)}
                              value='name'
                              color='primary'
                              classes={{root: classes.checkbox}}
                              indeterminateIcon={<Icon classes={{root: classes.checkbox}} color='primary'>indeterminate_check_box</Icon>}
                            />
                          </div>
                        :
                          <div className={classes.flexEndColumn}>
                            <Checkbox
                              checked={value.selectedValues === true || value.selectedValues.length === value.numValues}
                              indeterminate={value.selectedValues.length > 0 && value.selectedValues.length < value.numValues}
                              onChange={this.handleChecked(index)}
                              value='name'
                              color='primary'
                              classes={{root: classes.checkbox}}
                              indeterminateIcon={<Icon classes={{root: classes.checkbox}} color='primary'>indeterminate_check_box</Icon>}
                            />
                          </div>
                        }
                        
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                      {value.numValues < 1000 ?
                        <div className={classes.column} />
                      :
                        <div className={classNames(classes.column, classes.helper)}>
                          <Icon color="error">warning</Icon>
                          <Typography variant="caption" classes={{root: classes.warningCaption}}>{`Please allow a few seconds for the ${value.numValues} fields to load.`}</Typography>
                        </div>
                      }

                      <div className={classes.column}>
                        {value.originalValues.length > 0 ?
                          <React.Fragment>
                            <div className={classes.detailsHeading}>
                              <Typography variant="subheading"  className={classes.text}>{'Available Fields'}</Typography>
                            </div>
                            <div className={classes.selectionDiv}>
                              {value.values.length < 26 ?
                                value.values.map(field => <Chip label={field} clickable onClick={this.handleAdd(index, field)} />)
                              :
                                <MultiSelectDropdown
                                  placeholder='Add a Field'
                                  options={value.multiSelectValues}
                                  onChange={this.handleSelectAdd(index)}
                                />
                              }
                            </div>
                            <div className={classes.detailsFooter}>
                              <Button variant="contained" color="primary" className={classes.button} onClick={this.handleRemoveAll(index)}>
                                Remove All
                              </Button>
                            </div>
                          </React.Fragment>
                        : null
                        }
                      </div>

                      <div className={classes.column}>
                        {value.originalValues.length > 0 ? 
                          <React.Fragment>
                            <div className={classes.detailsHeading}>
                              <Typography variant="subheading"  className={classes.text}>{'Selected Fields'}</Typography>
                            </div>
                            <div className={classes.selectionDiv}>
                              {value.selectedValues.length < 26 ?
                                value.selectedValues.map(field => <Chip label={field} clickable onClick={this.handleRemove(index, field)} onDelete={this.handleRemove(index, field)} />)
                              :
                                <MultiSelectDropdown
                                  placeholder='Remove a Field'
                                  options={value.selectedValues.map(elem => ({label: elem, value: elem}))}
                                  onChange={this.handleSelectRemove(index)}
                                />
                              }
                            </div>
                            <div className={classes.detailsFooter}>
                              <Button variant="contained" color="primary" className={classes.button} onClick={this.handleAddAll(index)}>
                                Add All
                              </Button>
                            </div>
                          </React.Fragment>
                        :
                          null
                        }
                      </div>
                    </ExpansionPanelDetails>

                  </ExpansionPanel>
                );
              })}
            </Scrollbars>
          </Paper>
          <div className={classes.footer}>
            <Button variant="contained" color="primary" onClick={this.requestCSVData} className={classes.button}>
              Download CSV
            </Button>
          </div>
        </div>
      );
    } else if (error) {
      return <ErrorPage errorCode={errorCode} error={error}/>;
    } else if (loading) {
      return <Loading text={loadingText}/>;
    } else {
      return <ErrorPage error={'Unknown Error'}/>;
    }
  };
}

DownloadData.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DownloadData);
