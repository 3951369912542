import React from 'react';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {format} from 'd3-format';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import {CSVLink} from 'react-csv';
import 'firebase/firestore';
import { DatePicker } from 'material-ui-pickers';
import ReactTable from 'react-table';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import MultiChart from '../app/components/MultiChart';
import Loading from '../app/components/Loading';

const numXAxisLabels = 8;
const reg = new RegExp('^\\d*?[.]?\\d*?\\d$', 'mi');

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    minWidth: '800px',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  graphContainer: {
    display: 'flex',
    width: '80%',
    height: 'calc((100% - 84px)/2)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  graphHeading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  graphPaper: {
    flexGrow: 1,
    width: '100%',
    height: '60%',
  },
  tableContainer: {
    display: 'flex',
    width: '80%',
    height: 'calc((100% - 84px)/2)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  halfContainer: {
    display: 'flex',
    height: '100%',
    width: '45%',
    minWidth: '380px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  halfContainerLeft: {
    alignItems: 'flex-start',
  },
  halfContainerRight: {
    alignItems: 'flex-end',
  },
  tableHeadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '20px',
  },
  singleTableContainer: {
    width: '100%',
    height: 'calc(100% - 20px)',
  },
  csvLink: {
    textDecoration: 'none',
    color: 'white',
  },
  textField: {
    flexBasis: 200,
    margin: theme.spacing.unit,
  },
  footer: {
    width: '80%',
    height: '36px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  snackbarDrawerOpen: {
    marginLeft: theme.sizes.openSideNav/2,
  },
  snackbarDrawerClosed: {
    marginLeft: theme.sizes.collapsedSideNav/2,
  },
  fiscalYearStartSelect: {
    width: '140px',
  },
});

class BudgetingTool extends React.Component {
  state = {
    loading: true,
    snackbarOpen: false,
    earliestSelectionDate: this.props.appContext.earliestSelectionDate,
    startDate: moment().startOf('day'),
    endDate: moment().add(1, 'years').startOf('day'),
    fiscalYearStartMonth: 4,
    months: 12,
    tableEdited: false,
    historicTableData: [],
    historicGraphData: [],
    futureTableData: [],
    futureGraphData: [],
    historicColumns: [],
    futureColumns: [],
    xAxisValues: [],
    xAxisGridLines: [],
    historicExpanded: {},
    futureExpanded: {},
    adjustValue: undefined,
    isAdjustValueSet: false,
    lastDrawLocation: null,
    highlightedHistoric: null,
    highlightedHistoricIndex: null,
    highlightedFuture: null,
    highlightedFutureIndex: null,
    crosshairValues: {
      line: [],
    },
  };

  serverTimeout = () => {
    if (this.state.loading) {
      this.requestTimeout = undefined;
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        const {loading, error} = this.state;
        if (loading && !error) {
          this.setState({
            error: 'The request to Budgeting Tool API has timed out.',
            errorCode: 408,
            loading: false,
            loadingText: '',
          });
        }
      }, 30000);
    }
  };

  halfContainer = React.createRef();

  // Function passed to the DatePicker component to format the way it displays the date
  formatDateSelectLabel = (date, invalidLabel) => {
    if (date === null) {
      return '';
    } else if (date instanceof moment) {
      return date.format('D MMMM YYYY');
    } else {
      return invalidLabel;
    }
  };

  // Function passes to graph to set the cursor location on X Axis
  setLastDrawLocation = (lastDrawLocation) => {
    this.setState({lastDrawLocation});
  };

  // Function to control hover state of historic graph
  highlightXHistoric = (highlighted, {index}) => {
    if (highlighted === null) {
      this.setState({
        highlightedHistoric: highlighted,
        highlightedHistoricIndex: index,
        crosshairValues: {line: [],}
      });
    } else if (index === this.state.highlightedHistoricIndex) {
      this.setState({
        highlightedHistoric: highlighted,
        highlightedHistoricIndex: index,
      });
    } else {
      this.setState({
        highlightedHistoric: highlighted,
        highlightedHistoricIndex: index,
        crosshairValues: {line: [this.state.historicGraphData[index]],}
      });
    }
  };

  // Function to control hover state of future graph
  highlightXFuture = (highlighted, {index}) => {
    if (highlighted === null) {
      this.setState({
        highlightedFuture: highlighted,
        highlightedFutureIndex: index,
        crosshairValues: {line: [],}
      });
    } else if (index === this.state.highlightedFutureIndex) {
      this.setState({
        highlightedFuture: highlighted,
        highlightedFutureIndex: index,
      });
    } else {
      this.setState({
        highlightedFuture: highlighted,
        highlightedFutureIndex: index,
        crosshairValues: {line: [this.state.futureGraphData[index]],}
      });
    }
  };

  // Function used to close the snackbar
  handleSnackbarClose = () => this.setState({snackbarOpen: false});

  handleChangeFiscalYearStartMonth = (event) => {
    const {historicTableData, futureTableData, endDate, adjustValue, isAdjustValueSet} = this.state;
    const newFiscalYearStartMonth = event.target.value;
    this.setState({fiscalYearStartMonth: newFiscalYearStartMonth}, () => {
      if (isAdjustValueSet) {
        this.processData(historicTableData, futureTableData, newFiscalYearStartMonth, endDate, adjustValue, isAdjustValueSet);
      } else {
        this.processData(historicTableData, futureTableData, newFiscalYearStartMonth, endDate, undefined, false);
      }
    });
  };

  // Performs all necessary updates to state when endDate is changed
  handleEndDateChange = (date) => {
    let check = false;
    if (this.state.tableEdited) { // First confirm the user wants to reset tableData
      if (window.confirm("This will reset future data, continue?")) {
        check = true;
      }
    } else {
      check = true;
    }
    if (check) {
      const {queryData, historicTableData, futureTableData, fiscalYearStartMonth, startDate, adjustValue, isAdjustValueSet, stores} = this.state;
      const endDate = moment(date);
      const months = Math.round(moment.duration(endDate.diff(startDate)).asMonths());
      let newFutureTableData = [];
      if (months > 12) { // If the new endDate is more than 12 months from startDate
        const extraMonths = months - 12;
         // Get the date one year before the end of originally queried data
        const lastYearStartDateUT = moment(queryData[stores[0]][queryData[stores[0]].length - 13].month, 'YYYY-MM-DD').valueOf();
        stores.forEach(storeNum => { // We are fundamentally changing the structure, so we recreate it from queryData
          let futureData = []; // Array to keep the data we are generating for current storeNum
          queryData[storeNum].forEach(elem => { // We must create a new set of data for each store
            // If the date is within the 12 months year of queryData, we can use it to create the first 12 months futureData
            if (moment(elem.month, 'YYYY-MM-DD').valueOf() > lastYearStartDateUT) {
              futureData.push({
                storekey: parseInt(elem.storekey, 10),
                month: moment(elem.month, 'YYYY-MM-DD').add(1, 'years').valueOf(),
                net_sales_amount: parseFloat(elem.net_sales_amount),
                editedSales: null,
                originalSales: parseFloat(elem.net_sales_amount),
              });
            }
          });
          // After the first 12 months for this store, create the extra months using the values from 12 months ago
          for (let i = 0; i < extraMonths; i++) {
            futureData.push({
              storekey: parseInt(storeNum, 10),
              month: moment(futureData[i].month).add(12, 'months').valueOf(),
              net_sales_amount: parseFloat(futureData[i].net_sales_amount),
              editedSales: null,
              originalSales: parseFloat(futureData[i].originalSales)
            });
          }
          newFutureTableData.push(...futureData); // Add data generated for this store to the futureTableData array
        });
      } else { // If the new endDate is 12 months or less from startDate, just reset the tableData to maintain consistency
        newFutureTableData = futureTableData.map(elem => elem.net_sales_amount = elem.originalSales);
      }
      this.setState({futureTableData: newFutureTableData, endDate, months});
      this.processData(historicTableData, newFutureTableData, fiscalYearStartMonth, endDate, adjustValue, isAdjustValueSet); // After updating table, update graph
    }
  };

  // Reset futureTableData to display originalSales, reset adjustValue, reset table state, keep endDate as is
  handleResetData = () => {
    const {futureTableData, historicTableData, fiscalYearStartMonth, endDate} = this.state;
    const newFutureTableData = futureTableData.map(elem => ({
      ...elem,
      net_sales_amount: elem.originalSales,
      editedSales: null,
    }));
    this.setState({
      futureTableData: newFutureTableData,
      adjustValue: 0,
      historicExpanded: {},
      futureExpanded: {},
      tableEdited: false,
      isAdjustValueSet: false,
    });
    this.processData(historicTableData, newFutureTableData, fiscalYearStartMonth, endDate, undefined, false);
  };

  // Capture adjustValue from the event and if necessary, set it to null instead of empty string
  handleChangeAdjustValue = (event) => {
    const value = event.target.value;
    if (value === '') {
      this.changeAdjustValue(null, true);
    } else {
      this.changeAdjustValue(value, true);
    }
  };

// Applies the adjustValue to net_sales_amount & editedSales
  changeAdjustValue = (value, isAdjustValueSet) => {
    let newFutureTableData = [];
    const adjustValue = parseFloat(value, 10);
    const {historicTableData, futureTableData, fiscalYearStartMonth, startDate, endDate} = this.state;
    // Test adjustValue for valid number
    if (reg.test(value) && !isNaN(adjustValue)) {
      // Map over futureTableDate and calculate the new net_sales_amount & editedSales, year on year for each elem
      newFutureTableData = futureTableData.map(elem => {
        // Number of years this elem is from startDate (used for year on year calc)
        const years = Math.round(moment.duration(moment(elem.month).diff(startDate)).asYears());
        // Use editedSales if it is valid, otherwise use original sales
        let newNetSalesAmount = elem.editedSales != null ? elem.editedSales : elem.originalSales;
        for (let i = 0; i < years + 1; i++) { // For each year, adjust the value accordingly
          newNetSalesAmount = parseInt(newNetSalesAmount + ((newNetSalesAmount / 100) * adjustValue), 10);
        }
        return {...elem, net_sales_amount: newNetSalesAmount};
      });
      this.setState({futureTableData: newFutureTableData, adjustValue: value, isAdjustValueSet});
    } else {
      // If adjustValue is not valid, display originalSales/editedSales
      newFutureTableData = futureTableData.map(elem => ({
        ...elem,
        net_sales_amount: elem.editedSales != null ? elem.editedSales : elem.originalSales,
      }));
      this.setState({futureTableData: newFutureTableData, adjustValue: value, isAdjustValueSet});
    }
    this.processData(historicTableData, newFutureTableData, fiscalYearStartMonth, endDate, value, isAdjustValueSet); // After updating table, update graph
  };

  // Creates an object with each month as a property, aggregates the values for that month across all stores, returns the object
  graphObjFromTableData = (tableData) => {
    let graphObj = {};
    tableData.forEach(elem => {
      if (graphObj[elem.month]) {
        graphObj[elem.month] = graphObj[elem.month] + parseFloat(elem.net_sales_amount);
      } else {
        graphObj[elem.month] = parseFloat(elem.net_sales_amount);
      }
    });
    return graphObj;
  };

  // Creates the data, xAxis values and gridLine Arrays from tableData
  // Will calculate adjustValue and and call changeAdjustValue() if one is not provided (Only used on mount & reset)
  processData = (historicTableData, futureTableData, fiscalYearStartMonth, endDate, adjustValue, isAdjustValueSet) => {
    // Get an object that can be converted into the new historicGraphData
    const historicGraphDataObj = this.graphObjFromTableData(historicTableData);
    // Get an object that can be converted into the new futureGraphData
    const futureGraphDataObj = this.graphObjFromTableData(futureTableData);
    const historicDates = Object.keys(historicGraphDataObj);
    const futureDates = Object.keys(futureGraphDataObj);
    // Map over the dates to create the historicGraphData array
    const historicGraphData = historicDates.map((date, i) => ({x: parseInt(date, 10), y: historicGraphDataObj[date], i}));
    // Map over the dates to create the futureGraphData array
    const futureGraphData = futureDates.map((date, i) => ({x: parseInt(date, 10), y: futureGraphDataObj[date], i: i + 1}));
    // Add an elem to the start of futureGraphData to connect it to historicGraphData
    futureGraphData.unshift({...historicGraphData[historicGraphData.length - 1], i: 0});
    // No adjust value provided, use average change of last fiscal year
    if (!isAdjustValueSet && adjustValue === undefined) {
      // Find the index of the beginning of the current fiscal year in historicGraphData
      let currentFiscalYearStartMonthIndex = historicGraphData.length - 1;
      while (currentFiscalYearStartMonthIndex >= 0) {
        if (moment(historicGraphData[currentFiscalYearStartMonthIndex].x).month() === fiscalYearStartMonth) {
          break;
        } else {
          currentFiscalYearStartMonthIndex--;
        }
      }
      // Calculate YoY % change to use as adjustValue
      let accumulatedChange = 0;
      let validMonths = 0;
      for (let i = currentFiscalYearStartMonthIndex; i < historicGraphData.length; i++) {
        // Check if we can compare this month to data from a year ago
        if (i >= 12) {
          // If we can, add this months change % to the total in accumulatedChange
          validMonths++;
          const thisYearValue = historicGraphData[i].y;
          const lastYearValue = historicGraphData[i - 12].y;
          accumulatedChange = accumulatedChange + (((thisYearValue - lastYearValue) / lastYearValue) * 100);
        }
      }
      // Divide accumulatedChange by the number of validMonths to get the average change for the last fiscal year
      // Add Number.EPSILON & scale to avoid floating point problems
      const avgChange = Math.round(((accumulatedChange / validMonths) + Number.EPSILON) * 100) / 100;
      this.changeAdjustValue(avgChange, isAdjustValueSet);
    } else {
      // Create xAxis values by joining the x values from historic & future, calculating delta between each xAxis value,
      // then pushing the relevant xAxis values to the array
      let xAxisValues = [];
      let xAxisDates = [];
      historicGraphData.forEach(elem => xAxisDates.push(elem.x));
      futureGraphData.forEach(elem => xAxisDates.push(elem.x));
      const delta = Math.floor(xAxisDates.length / numXAxisLabels);
      for (let i = 0; i < xAxisDates.length; i = i + delta) {
        xAxisValues.push(xAxisDates[i]);
      }
      // Create vertical gridlines to show seperation of fiscal years
      const xAxisGridLines = xAxisDates.filter(elem => moment(elem).month() === fiscalYearStartMonth - 1);
      this.setState({historicGraphData, futureGraphData, xAxisValues, xAxisGridLines, loading: false});
    }
  };

  // render function for the editable net_sales_amount cells in the futureTableData
  renderEditable = (cellInfo) => {
    if (!cellInfo.aggregated) { // Only use this render function on non-pivoted net_sales_amount cells
      return (
        <div
          contentEditable
          suppressContentEditableWarning
          onBlur={event => { // Called when focus on the cell is lost
            event.persist();
            this.handleSnackbarClose();
            const {historicTableData, fiscalYearStartMonth, endDate, adjustValue, isAdjustValueSet, futureTableData} = this.state;
            let newFutureTableData = futureTableData.slice();
            // Get the current value of the cell in the table
            const oldValue = futureTableData[cellInfo.index].net_sales_amount;
            // Get only the number from the edited cell
            const newValue = parseInt(event.target.innerHTML.match(/\d+/g), 10);
            // Check that input is valid to prevent changing the value to NaN
            if (!isNaN(newValue)) {
              // If the values are different, update futureTableData and the graph
              if (newValue !== oldValue) {
                newFutureTableData[cellInfo.index].editedSales = newValue;
                newFutureTableData[cellInfo.index].net_sales_amount = newValue;
                this.setState({futureTableData: newFutureTableData, tableEdited: true});
                this.processData(historicTableData, newFutureTableData, fiscalYearStartMonth, endDate, adjustValue, isAdjustValueSet);
              }
            } else {
              // Force the table to re-render to clear the invalid input from the cell
              event.target.innerHTML = 'R ' + oldValue + '.00';
            }
          }}
          onFocus={() => this.setState({snackbarOpen: true})}
          dangerouslySetInnerHTML={{__html: 'R ' + this.state.futureTableData[cellInfo.index][cellInfo.column.id] + '.00'}}
        />
      );
    } else {
      return ('R ' + cellInfo.value + '.00');
    }
  };

  // Queries for the budgeting tool data from firestore, and creates necessary data structures
  componentDidMount() {
    this.props.appContext.firestore.collection('clients').doc(this.props.appContext.user.client).get().then(clientSnapshot => {
      const {fiscalYearStartMonth, adjustValue, isAdjustValueSet} = this.state;
      const queryData = clientSnapshot.get('budgetingToolData');
      const historicTableData = [];
      const futureTableData = [];
      const stores = Object.keys(queryData);
      // Get the date one year before the end of queried data
      const lastYearStartDateUT = moment(queryData[stores[0]][queryData[stores[0]].length - 13].month, 'YYYY-MM-DD').valueOf();
      const dates = queryData[stores[0]].map(elem => moment(elem.month, 'YYYY-MM-DD').valueOf()); // Create an array of all the dates
      const startDate = moment(parseInt(dates[dates.length - 1], 10)); // Set the startDate to the last date in queried data
      const endDate = moment(startDate).add(1, 'years'); // Set the end date to 12 months after the last date in queried data
      // Map over the properties of an entry in queried data to create a column for each property
      const historicColumns = [...(Object.keys(queryData[stores[0]][0]).map(elem => ({
        Header: elem, // Header of the column
        accessor: elem, // Accessor of the column
        minWidth: Math.round((this.halfContainer.clientHeight - 20)/3), // Min-width of the column
        // Logic to apply to the cell if the column is being aggregated due to a pivot
        aggregate: elem === 'net_sales_amount'
          ? (values, rows) => Math.round(values.reduce((acc, elem) => acc + elem))
          : elem === 'month'
            ? (values, rows) => Math.round(moment.duration(moment(values[values.length - 1]).diff(values[0])).asYears())
            : false,
        // Changes to apply to the display of the cell if the column in being aggregated due to a pivot
        Aggregated: elem === 'net_sales_amount'
          ? row => (<span>R {row.value}.00</span>)
          : elem === 'month'
            ? row => (<span>{row.value} Year(s)</span>)
            : false,
        // Changes to apply to the display of the cell
        Cell: elem === 'month'
          ? row => (<span>{moment(row.value).format('DD/MM/YYYY')}</span>)
          : elem === 'net_sales_amount'
            ? row => (<span>R {row.value}.00</span>)
            : undefined,
      })))];
      // Following same methodology as above
      const futureColumns = [...(Object.keys(queryData[stores[0]][0]).map(elem => ({
        Header: elem,
        accessor: elem,
        minWidth: Math.round((this.halfContainer.clientHeight - 20)/3),
        aggregate: elem === 'net_sales_amount'
          ? (values, rows) => Math.round(values.reduce((acc, elem) => acc + elem))
          : elem === 'month'
            ? (values, rows) => Math.round(moment.duration(moment(values[values.length - 1]).diff(values[0])).asYears())
            : false,
        Aggregated: elem === 'net_sales_amount'
          ? row => (<span>R {row.value}.00</span>)
          : elem === 'month'
            ? row => (<span>{row.value} Year(s)</span>)
            : false,
        Cell: elem === 'month'
          ? row => (<span>{moment(row.value).format('DD/MM/YYYY')}</span>)
          : elem === 'net_sales_amount'
            ? this.renderEditable
            : undefined,
      })))];
      // Map over the stores and for each date per store, create an entry for historicTableData
      stores.forEach(storeNum => {
        queryData[storeNum].forEach(elem => {
          historicTableData.push({
            storekey: parseInt(elem.storekey, 10),
            month: moment(elem.month, 'YYYY-MM-DD').valueOf(),
            net_sales_amount: parseFloat(elem.net_sales_amount)
          });
          // If the date is within the 12 months year of queryData, we can use it to create the first 12 months futureData
          if (moment(elem.month, 'YYYY-MM-DD').valueOf() > lastYearStartDateUT) {
            futureTableData.push({
              storekey: parseInt(elem.storekey, 10),
              month: moment(elem.month, 'YYYY-MM-DD').add(1, 'years').valueOf(),
              net_sales_amount: parseFloat(elem.net_sales_amount),
              editedSales: null,
              originalSales: parseFloat(elem.net_sales_amount)
            });
          }
        });
      });
      this.setState({
        queryData,
        historicTableData,
        historicColumns,
        futureTableData,
        futureColumns,
        stores,
        startDate,
        endDate,
      });
      // After creating table data, create graph data
      this.processData(historicTableData, futureTableData, fiscalYearStartMonth, endDate, adjustValue, isAdjustValueSet);
    });
    this.serverTimeout();
  };

  render() {
    const {classes} = this.props;
    const {
      adjustValue,
      lastDrawLocation,
      crosshairValues,
      futureTableData,
      historicTableData,
      futureColumns,
      historicColumns,
      historicExpanded,
      futureExpanded,
      futureGraphData,
      historicGraphData,
      earliestSelectionDate,
      endDate,
      fiscalYearStartMonth,
      months,
      xAxisValues,
      xAxisGridLines,
      stores,
      loading,
      snackbarOpen
    } = this.state;
    const csvData = historicTableData.concat(futureTableData).map(({storekey, month, net_sales_amount}) => ({storekey, month, net_sales_amount}));
    if (!loading) {
      return (
        <div className={classes.mainContainer}>
          <div className={classes.graphContainer}>
            <div className={classes.graphHeading}>
              <div>
                 <FormControl className={classes.fiscalYearStartSelect}>
                  <InputLabel htmlFor="fiscalYearStartMonth-select">Fiscal Year Start</InputLabel>
                  <Select
                    value={fiscalYearStartMonth}
                    onChange={this.handleChangeFiscalYearStartMonth}
                    inputProps={{
                      name: 'fiscalYearStartMonth',
                      id: 'fiscalYearStartMonth-select',
                    }}
                  >
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>Febuary</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <DatePicker
                  label='End Date'
                  value={endDate}
                  onChange={this.handleEndDateChange}
                  maxDate={moment().add(3, 'years').format('YYYY-MM-DD')}
                  minDate={earliestSelectionDate}
                  labelFunc={this.formatDateSelectLabel}
                />
              </div>
              <TextField
                value={adjustValue}
                label='Adjust Future Data By'
                id='simple-start-adornment'
                // Will show in error state when evaluated to true (adjustValue == null or invalid number)
                error={(adjustValue != null && (!reg.test('' + adjustValue) || isNaN(adjustValue)))}
                className={classNames(classes.margin, classes.textField)}
                onChange={this.handleChangeAdjustValue}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                }}
              />
            </div>
            <Paper className={classes.graphPaper}>
              <MultiChart
                interaction={{
                  setLastDrawLocation: this.setLastDrawLocation,
                  lastDrawLocation: lastDrawLocation,
                  highlightXArr: [this.highlightXHistoric, this.highlightXFuture]
                }}
                data={{lineSeriesArr: [historicGraphData, futureGraphData]}}
                format={{
                  colors: {line: ['#029fe5', '#ffa500']},
                  horGridLines: true,
                  verGridLines: true,
                  horGridLineValues: null,
                  verGridLineValues: xAxisGridLines,
                }}
                xAxis={{
                  tickSize: 3,
                  tickAlign: 0,
                  tickTotal: 12,
                  values: xAxisValues,
                  tickFormat: (label) => moment(new Date(label)).format('DD/MM/YY')
                }}
                yAxis={{
                  tickSize: 3,
                  tickAlign: 0,
                  tickTotal: 7,
                  tickFormat: (label) => format('.2s')(label)
                }}
                crosshair={{
                  values: crosshairValues.line,
                  titleFormat: (array) => ({title: moment(new Date(array[0].x)).format('DD/MM/YY'), value: null}),
                  itemsFormat:(array) => ([
                    {title: 'Budget', value: 'R ' + format('.2s')(array[0].y.toFixed(2))},
                  ])
                }}
              />
            </Paper>
          </div>
          <div className={classes.tableContainer}>
            <div className={classNames(classes.halfContainer, classes.halfContainerLeft)} ref={this.halfContainer}>
              <div className={classes.tableHeadingContainer}>
                <Typography variant="subheading">Historic Store Level Data</Typography>
              </div>
              <div className={classes.singleTableContainer}>
                <ReactTable
                  data={historicTableData}
                  columns={historicColumns}
                  defaultPageSize={stores.length}
                  expanded={historicExpanded}
                  onExpandedChange={expanded => this.setState({historicExpanded: expanded})}
                  showPagination={false}
                  className='-striped -highlight'
                  style={{height: '100%'}}
                  pivotBy={['storekey']}
                  defaultSorted={[
                    {
                      id: 'storekey',
                      desc: false
                    },
                    {
                      id: 'month',
                      desc: false
                    }
                  ]}
                />
              </div>
            </div>
            <div className={classNames(classes.halfContainer, classes.halfContainerRight)}>
              <div className={classes.tableHeadingContainer}>
                <Typography variant="subheading">Future Store Level Data</Typography>
              </div>
              <div className={classes.singleTableContainer}>
                <ReactTable
                  // We need to filter out extra data if number of months < 12
                  data={months >= 12 ? futureTableData : futureTableData.filter(elem => elem.month < endDate.valueOf())}
                  columns={futureColumns}
                  defaultPageSize={stores.length}
                  expanded={futureExpanded}
                  onExpandedChange={expanded => this.setState({futureExpanded: expanded})}
                  showPagination={false}
                  className='-striped -highlight'
                  style={{height: '100%'}}
                  pivotBy={['storekey']}
                  defaultSorted={[
                    {
                      id: 'storekey',
                      desc: false
                    },
                    {
                      id: 'month',
                      desc: false
                    }
                  ]}
                />
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Button variant='contained' color='primary' onClick={() => this.handleResetData()}>
              Reset Data
            </Button>
            <CSVLink data={csvData} filename={'budget-totals.csv'} className={classes.csvLink}>
              <Button variant='contained' color='primary'>
                Download CSV
              </Button>
            </CSVLink>
          </div>
          <Snackbar
            anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
            open={snackbarOpen}
            ContentProps={{'aria-describedby': 'message-id',}}
            message={<span id="message-id">Click outside the table to save</span>}
            classes={{root: this.props.appContext.drawerOpen ? classes.snackbarDrawerOpen : classes.snackbarDrawerClosed}}
          />
        </div>
      );
    }
    else {
      return <Loading text={'Loading historic data'}/>
    }
  }
}

BudgetingTool.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BudgetingTool);
