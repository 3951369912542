import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const styles = {
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '25px',
  },
  paperContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
};

const Embedded = (props) => {
	const {classes, appContext, ms} = props;
  const {dimensions} = appContext;
  const sidePanelWidth = appContext.drawerOpen ? dimensions.openSideNav : dimensions.collapsedSideNav;
  const paperHeight = dimensions.height - (dimensions.topPanel + 50 /*2x top and bottom margins*/);
  const paperWidth = dimensions.width - (50 + sidePanelWidth);
  // console.log('in Embedded');
  // console.log(ms);
  return (
    <div className={classes.outerDiv}>
      <Paper className={classes.paperContainer} elevation={14} square>
        <iframe
          title={ms.title}
          src={ms.data}
          frameborder='0'
          width={paperWidth}
          height={paperHeight}
        />
      </Paper>
    </div>
  );
};

Embedded.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  ms: PropTypes.object.isRequired,
};

export default withStyles(styles)(Embedded);
