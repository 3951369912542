import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from '../app/components/Loading';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  containerDiv: {
    marginTop: '16px',
  },
  gridContainerDiv: {
    overflow: 'auto',
    flexGrow: 1,
    marginLeft: '16px',
    marginRight: '16px',
    paddingBottom:'16px',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  noScrollbar: {
    display: 'none',
    opacity: '0.0',
  },
});

class DataQualityReport extends React.Component {
  state = {
    report : '',
    loading: true,
  };

  serverTimeout = () => {
    if (this.state.loading) {
      this.requestTimeout = undefined;
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        const {loading, error} = this.state;
        if (loading && !error) {
          this.setState({
            error: 'The request to Data Quality Report API has timed out.',
            errorCode: 408,
            loading: false,
            loadingText: '',
          });
        }
      }, 30000);
    }
  };
  
  componentDidMount() {
    this.props.appContext.firestore.collection('clients').doc(this.props.appContext.user.client).get().then(clientSnapshot => {
      const DQRHTML = clientSnapshot.get('dataQualityReport');
      this.setState({report: DQRHTML, loading: false});
    });
    this.serverTimeout();
  };

  render() {
    const {classes, appContext} = this.props;
    const {report, loading} = this.state;
    if (!loading) {
      return (
        <div className={classes.containerDiv}>
          <Scrollbars
            autoHeight
            autoHeightMin={`calc(100vh - ${appContext.dimensions.topPanel + 16}px)`}
            renderThumbVertical={props => <div {...props} className={classes.scrollbar}/>}
            renderThumbHorizontal={props => <div {...props} className={classes.noScrollbar}/>}
          >
            <div dangerouslySetInnerHTML={{__html: report}} />
          </Scrollbars>
        </div>
      );
    }
    else {
      return <Loading text={'Loading Data Quality Report'}/>
    }
  }
}

DataQualityReport.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataQualityReport);
