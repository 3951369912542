import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-evenly',
    margin: '25px',
  },
  normalDiv: {
    flexGrow: 1,
    display: 'flex',
  },
});

class DragAndDrop extends React.Component {
  state = {
    drag: false
  }
  dragCounter = 0;
  dropRef = React.createRef();

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.props.handleDrag(true);
      this.setState({drag: true});
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter < 1) {
      this.setState({drag: false});
      this.props.handleDrag(false);
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({drag: false});
    this.props.handleDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  };

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  };

  render() {
    const {classes, children} = this.props;
    return (
      <div className={classes.outerDiv} ref={this.dropRef}>
        <div className={classes.normalDiv}>
          {children}
        </div>
      </div>
    )
  }
}

DragAndDrop.propTypes = {
  drag: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DragAndDrop);
