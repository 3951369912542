import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/List';
import ButtonBase from '@material-ui/core/ButtonBase';
import Fade from '@material-ui/core/Fade';
import EchoLogo from '../assets/img/echo_logo_draft_1.png';
import EchoIcon from '../assets/img/echo_icon_draft_1.png';
import Icon from '@material-ui/core/Icon';
//import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {Link} from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
//import Menu from '@material-ui/core/Menu';
import { Scrollbars } from 'react-custom-scrollbars';

const styles = theme => ({
  drawerPaper: {
    display: 'flex',
    height: '100%',
    width: theme.sizes.openSideNav,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    display: 'flex',
    height: '100%',
    width: theme.sizes.collapsedSideNav,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  menuContainer: {
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  menuItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  menuItemContainerSelected: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.secondary.dark,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  menuItemChildContainer: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.secondary.light,
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  listItem: {
    height: theme.sizes.listItemHeight,
    width: theme.sizes.listItemWidth,
  },
  listItemText: {
    whiteSpace: 'nowrap',
    color: theme.customColors.sideNavText,
    fontSize: '15px',
  },
  listItemTextSelected: {
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    fontSize: '15px',
  },
  panelIcon: {
    marginLeft: '5px',
    fontSize: '28px',
  },
  panelIconDemo: {
    marginLeft: '5px',
    fontSize: '28px',
    color: theme.customColors.demoIcon,
  },
  panelIconDisabled: {
    marginLeft: '5px',
    fontSize: '28px',
    color: 'grey',
  },
  expandIcon: {
    alignSelf: 'flex-start',
    flexGrow: 1,
  },
  expandButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: theme.sizes.expandButton,
  },
  toggleDrawer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    background: theme.palette.secondary.main,
  },
  toggleDrawerText: {
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
  },
  toggleDrawerItem: {
    height: theme.sizes.listItemHeight,
    background: theme.palette.secondary.dark,
  },
});

class SideNav extends Component {
  state = {
    currentMS: {},
    currentMSChild: {},
    collapsed: '',
    lastHover: null,
    currentHoverTitle: '',
    drawerHover: false,
    isHovering: false,
    hoverTimeout: null,
  };

  toggleCollapsed = (title) => {
    if (this.state.collapsed === title) {
      this.setState({collapsed: ''});
    } else {
      this.setState({collapsed: title,});
    }
  };

  currentHover = (target, title='') => {
    if (target && title !== '') {
      this.setState({lastHover: target, currentHoverTitle: title});
    } else {
      this.setState({currentHoverTitle: ''});
    }
  };

  postSetHover = () => {
    const {isHovering, hoverTimeout} = this.state;
    if (isHovering) {
      const timeoutID = setTimeout(() => {this.setState({drawerHover:true})},30);
      this.setState({hoverTimeout: timeoutID});
    } else {
      clearTimeout(hoverTimeout);
      this.setState({isHovering: false, drawerHover: false});
    }
  };

  setDrawerHover = (hover) => {
    this.setState({isHovering: hover}, this.postSetHover);
  };

  updateDrawer = () => {
    this.props.appContext.toggleDrawer();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const currentMS = nextProps.appContext.microservices.find((elem) => {return window.location.pathname.includes(elem.path)});
    let currentMSChild = {};
    if (currentMS && currentMS.hasOwnProperty('children')) {
      currentMSChild = currentMS.children.find((elem) => {return window.location.pathname === elem.path});
    }
    if (currentMS && prevState.currentMS !== currentMS) {
      return {
        currentMS,
        currentMSChild,
        collapsed: currentMS.title,
        lastHover: null,
        currentHoverTitle: '',
      };
    } else if (!currentMS) {
      if (prevState.currentMS) {
        return {
          currentMS,
          currentMSChild,
          collapsed: '',
          lastHover: null,
          currentHoverTitle: '',
        };
      } else {
        return prevState;
      }
    }
    return {currentMSChild};
  };

  render() {
    const {classes, appContext} = this.props;
    const {microservices, drawerOpen, dimensions} = appContext;
    const {drawerHover} = this.state;
    const currentPath = window.location.pathname;
    const collapsedMS = appContext.microservices.find((elem) => {return elem.title === this.state.collapsed});
    const numScrollbarItems =(this.state.collapsed !== '' && collapsedMS.children) ? microservices.length + collapsedMS.children.length : microservices.length;
    const availableScrollbarSize = (dimensions.height - dimensions.topPanel) - dimensions.listItemHeight;
    let minScrollbarSize = numScrollbarItems * dimensions.listItemHeight;
    if (availableScrollbarSize < minScrollbarSize) {
      minScrollbarSize = availableScrollbarSize;
    }
    const drawerStatus = drawerHover || drawerOpen;
    return (
      <Drawer
        variant={"permanent"}
        onMouseEnter={(event) => {!drawerOpen && this.setDrawerHover(true)}}
        onMouseLeave={(event) => {!drawerOpen && this.setDrawerHover(false)}}
        classes={{docked: drawerStatus
                          ? classes.drawerPaper
                          : classes.drawerPaperClose,
                  paper: drawerStatus
                          ? classes.drawerPaper
                          : classes.drawerPaperClose
                }}
        >
        <div onMouseEnter={(event) => this.currentHover(null)}>
          <Link to='/'>
            <div className={classes.logoDiv}>
              <Fade in={!drawerStatus} timeout={300}>
                <img src={EchoIcon} height="82px" alt=''/>
              </Fade>
              <Fade in={drawerStatus} timeout={300}>
                <img src={EchoLogo} height="82px" alt=''/>
              </Fade>
            </div>
          </Link>
        </div>
        <div className={classes.menuContainer}>
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={`${minScrollbarSize}px`}
            renderThumbVertical={props => <div {...props} className={classes.scrollbar}/>}
            renderThumbHorizontal={props => <div {...props} className={classes.scrollbar}/>}
            autoHideTimeout={500}
            autoHideDuration={300}
          >
            {microservices.map((item, index) => {
              const isSelected = item === this.state.currentMS;
              return (
                <React.Fragment key={index}>
                  <div
                    className={isSelected ? classes.menuItemContainerSelected : classes.menuItemContainer}
                    onMouseEnter={(event) => this.currentHover(event.currentTarget, item.title)}
                    aria-owns={'subMenu-' + item.title}
                    aria-haspopup='true'
                  >
                    <ListItem component={Link} to={!(item.data || item.componentName === 'LinkCardSelector') || item.disabled ? currentPath : item.path} className={classes.listItem}>
                      <Icon
                        className={!(item.data || item.componentName === 'LinkCardSelector') || item.disabled ? classes.panelIconDisabled : item.componentName === 'Demo' ? classes.panelIconDemo : classes.panelIcon}
                        color='primary'
                        // classes={(!item.disabled || (item.data && this.state.currentHoverTitle === item.title)) ? {root: classes.panelIcon} : {root: classes.panelIconDisabled}}
                        // className={item.componentName === 'Demo' && !item.disabled ? classes.panelIconDemo : classes.panelIcon}
                        // color={!(item.data || item.componentName === 'LinkCardSelector') || item.disabled ? 'disabled' : 'primary'}
                      >
                        {item.icon}
                      </Icon>
                      {drawerStatus && <ListItemText primary={item.title} classes={isSelected ? {primary: classes.listItemTextSelected} : {primary: classes.listItemText}}/>}
                    </ListItem>
                    {/*!drawerOpen &&
                      <ClickAwayListener onClickAway={(event) => this.currentHover(null)}>
                        <Menu
                          id={'subMenu-' + item.title}
                          anchorEl={this.state.currentHoverTitle === item.title ? this.state.lastHover : null}
                          getContentAnchorEl={null}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                          transformOrigin={{vertical: 'top', horizontal: 'left'}}
                          open={this.state.currentHoverTitle === item.title}
                          MenuListProps={{disablePadding: true}}
                        >
                          <div onMouseLeave={(event) => this.currentHover(null)}>
                            <div className={isSelected ? classes.menuItemContainerSelected : classes.menuItemContainer}>
                              <ListItem component={Link} to={item.disabled ? currentPath : item.path} className={classes.listItem}>
                                <ListItemText primary={item.title} classes={isSelected ? {primary: classes.listItemTextSelected} : {primary: classes.listItemText}}/>
                              </ListItem>
                            </div>
                            {item.hasOwnProperty('children') && item.children.length > 0 ? (
                              item.children.map((elem, ind) => {
                                const isChildSelected = elem === this.state.currentMSChild;
                                return (
                                  <div key={ind} className={isChildSelected ? classes.menuItemContainerSelected : classes.menuItemChildContainer}>
                                    <ListItem component={Link} to={!(elem.data || elem.componentName === 'LinkCardSelector') || elem.disabled ? currentPath : elem.path}>
                                      <Icon
                                        className={!(elem.data || elem.componentName === 'LinkCardSelector') || elem.disabled ? classes.panelIconDisabled : elem.componentName === 'Demo' ? classes.panelIconDemo : classes.panelIcon}
                                        color='primary'
                                        // classes={!elem.disabled ? {root: classes.panelIcon} : {root: classes.panelIconDisabled}}
                                        // className={item.componentName === 'Demo' && !item.disabled ? classes.panelIconDemo : classes.panelIcon}
                                        // color={!(item.data || item.componentName === 'LinkCardSelector') || item.disabled ? 'disabled' : 'primary'}
                                      >
                                        {elem.icon}
                                      </Icon>
                                      <ListItemText inset primary={elem.title} classes={isChildSelected ? {primary: classes.listItemTextSelected} : {primary: classes.listItemText}}/>
                                    </ListItem>
                                  </div>
                                );
                              })
                            ) : null}
                          </div>
                        </Menu>
                      </ClickAwayListener>
                    */}
                    {item.hasOwnProperty('children') && item.children.length > 0 && drawerStatus &&
                      <ButtonBase className={classes.expandButton} onClick={() => this.toggleCollapsed(item.title)}>
                        <Icon color='primary' classes={{root: classes.expandIcon}}>
                          {this.state.collapsed === item.title ? 'expand_less' : 'expand_more'}
                        </Icon>
                      </ButtonBase>
                    }
                  </div>
                  {item.hasOwnProperty('children') && item.children.length > 0 &&
                    <Collapse in={this.state.collapsed === item.title} timeout="auto" unmountOnExit>
                      {item.hasOwnProperty('children') ? (
                        item.children.map((elem, ind) => {
                          const isChildSelected = elem === this.state.currentMSChild;
                          return (
                            <div key={ind} className={isChildSelected ? classes.menuItemContainerSelected : classes.menuItemChildContainer}>
                              <ListItem component={Link} to={!(elem.data || elem.componentName === 'LinkCardSelector') || elem.disabled ? currentPath : elem.path}>
                                <Icon
                                  className={!(elem.data || elem.componentName === 'LinkCardSelector') || elem.disabled ? classes.panelIconDisabled : elem.componentName === 'Demo' ? classes.panelIconDemo : classes.panelIcon}
                                  color='primary'
                                  // color='primary'
                                  // classes={!elem.disabled ? {root: classes.panelIcon} : {root: classes.panelIconDisabled}}
                                >
                                  {elem.icon}
                                </Icon>
                                {drawerStatus && <ListItemText inset primary={elem.title} classes={isChildSelected ? {primary: classes.listItemTextSelected} : {primary: classes.listItemText}}/>}
                              </ListItem>
                            </div>
                          );
                        })
                      ) : null}
                    </Collapse>
                  }
                </React.Fragment>
              )
            })}
          </Scrollbars>
        </div>
        <div className={classes.toggleDrawer} onClick={this.updateDrawer} onMouseEnter={(event) => this.currentHover(null)}>
          <ListItem classes={{root: classes.toggleDrawerItem}}>
            <ListItemIcon>
              <Icon color='primary' classes={{root: classes.panelIcon}}>
                {drawerOpen ? 'chevron_left' : 'chevron_right'}
              </Icon>
            </ListItemIcon>
            {drawerOpen && <ListItemText inset primary='Close Drawer' classes={{primary: classes.toggleDrawerText}}/>}
            {!drawerOpen && drawerHover && <ListItemText inset primary='Open Drawer' classes={{primary: classes.toggleDrawerText}}/>}
          </ListItem>
        </div>
      </Drawer>
    );
  }
}

SideNav.defaultProps = {
  microservices: [],
};

SideNav.propTypes = {
  microservices: PropTypes.array.isRequired,
};

export default withStyles(styles)(SideNav);
