import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from './BreadCrumb';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import toysrus from '../assets/img/toysrus.png';
import massbuild from '../assets/img/massbuild.png';
import footgear from '../assets/img/footgear.png';
import telkom from '../assets/img/telkom.svg';
import bcx from '../assets/img/bcx.png';
import strategicinsights from '../assets/img/strategicinsights.png';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import firebase from 'firebase/app';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const prettyCamelCase = (str) => {
  const upperFirstChar = str[0].toUpperCase() + str.slice(1);
  return upperFirstChar.replace(/([a-z])([A-Z])/g, '$1 $2')
}

const styles = theme => ({
  button: {
    textTransform: 'none',
    height: '40px',
  },
  outer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: theme.sizes.topPanel,
    minHeight: theme.sizes.topPanel,
    zIndex: 1,
  },
  affiliateLogo: {
    height: '55px',
    marginLeft: '3%',
    marginRight: '3%',
  },
  affiliateLogoSmall: {
    height: '24px',
    width: '32px',
    objectFit: 'contain',
    marginLeft: '5%',
    marginRight: '5%',
  },
  clientMenu: {
    marginRight: '64px'
  },
  clientMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%'
  },
  expandIcon: {
    alignSelf: 'flex-start',
    flexGrow: 1,
    color: theme.palette.primary.main,
  },
  avatar: {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    width: 26,
    height: 26,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  }
});

class TopPanel extends React.Component {
  state = {
    selectedClient: this.props.appContext.user.client,
    selectedTheme: this.props.appContext.theme,
    clientsArr: [],
    appContext: this.props.appContext,
    loading: true,
    clientSelectAnchorEl: null,
    userProfileAnchorEl: null,
  };

  handleOpenClientSelectMenu = event => {
    this.setState({ clientSelectAnchorEl: event.currentTarget });
  };

  handleCloseClientSelectMenu = () => {
    this.setState({ clientSelectAnchorEl: null });
  };

  handleOpenUserProfileMenu = event => {
    this.setState({ userProfileAnchorEl: event.currentTarget });
  };

  handleCloseUserProfileMenu = () => {
    this.setState({ userProfileAnchorEl: null });
  };

  updateSelectedClient = (client) => {
    if (client === this.props.appContext.user.client) return;

    this.handleCloseClientSelectMenu(); 
    
    this.setState({loading: true});

    const {appContext} = this.state;
    firebase.auth().currentUser.getIdTokenResult(true).then((idTokenResult) => {
      const claims = idTokenResult.claims;
      const claimsArr = Object.keys(claims);
      const clientsArr = claimsArr.filter(claim => claims[claim] === true && !this.props.appContext.adminClaims.find(elem => elem === claim));
      if (clientsArr.some(elem => elem === client)) {
        appContext.setClient(appContext.user.uid, client);
      } else {
        this.setState({clientsArr});
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  componentDidMount() {
    // Check if user has claims for an admin function
    firebase.auth().currentUser.getIdTokenResult(true).then((idTokenResult) => {
      const claims = idTokenResult.claims;
      // Create an array of all the clients this user has claims for (claim must be true and not and admin claim)
      const claimsArr = Object.keys(claims);
      const clientsArr = claimsArr.filter(claim => claims[claim] === true && !this.props.appContext.adminClaims.find(elem => elem === claim));
      this.setState({clientsArr, loading: false});
    })
    .catch((error) => {
      console.log(error);
    });
  }

  render() {
    const {
      classes,
      appContext,
      onLogoutClick
    } = this.props;
    const getAffiliateLogo = client => {
      switch (client) {
        case 'toysrus':
          return toysrus;
        case 'massbuild':
          return massbuild;
        case 'footgear':
          return footgear;
        case 'amic':
          return toysrus;
        case 'telkom':
          return telkom;
        case 'fraudAndForensics':
          return telkom;
        case 'bcx':
          return bcx;
        default:
          return strategicinsights;
      }
    };
    return (
      <AppBar color='inherit' className={classes.outer}>
        <BreadCrumb microservices={appContext.microservices}/>
        <Toolbar className={classes.toolbar}>
          <Button 
            disabled={this.state.loading} 
            onClick={this.handleOpenClientSelectMenu} 
            variant='outlined' 
            aria-owns='menu-appbar' 
            aria-haspopup='true'
            style={{textTransform: 'none'}}
          >
            {this.state.loading
              ? <CircularProgress size={20} /> 
              : <img src={getAffiliateLogo(appContext.user.client)} alt='' className={classes.affiliateLogoSmall}/>}
            {prettyCamelCase(appContext.user.client).replace(/\s+/g, "\u00A0")}
            <Icon classes={{root: classes.expandIcon}}>
              expand_more
            </Icon>
          </Button>
          <Menu
            id='menu-appbar'
            anchorEl={this.state.clientSelectAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
            open={Boolean(this.state.clientSelectAnchorEl)}
            onClose={this.handleCloseClientSelectMenu}
            MenuListProps={{disablePadding: true, className: classes.clientMenu}}
            role='menu'
          >
            {this.state.clientsArr.map(client => {
              return (
                <MenuItem selected={client === appContext.user.client} key={client} onClick={() => this.updateSelectedClient(client)}>
                  <Typography variant='subheading' className={classes.clientMenuItem}>
                    <img src={getAffiliateLogo(client)} alt='' className={classes.affiliateLogoSmall}/>
                    {prettyCamelCase(client)}
                  </Typography>
                </MenuItem>
              );
            })}
          </Menu>

          <Button color='inherit' classes={{root: classes.button}} onClick={this.handleOpenUserProfileMenu} aria-owns='menu-appbar' aria-haspopup='true'>
            <Avatar className={classes.avatar}>{appContext.user && appContext.user.displayName && appContext.user.displayName.charAt(0)}</Avatar>
            <Icon classes={{root: classes.expandIcon}}>
              expand_more
            </Icon>
          </Button>
          <Menu
            id='menu-appbar'
            anchorEl={this.state.userProfileAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
            open={Boolean(this.state.userProfileAnchorEl)}
            onClose={this.handleCloseUserProfileMenu}
            MenuListProps={{disablePadding: true}}
            role='menu'
          >
            <MenuItem>
              <FormControlLabel
                control={
                  <Switch
                    checked={appContext.theme === 'dark'}
                    onChange={(v) =>appContext.setTheme(v.target.checked ? 'dark' : 'light')}
                  />
                }
                label={appContext.theme === 'dark' ? 'Use Light Theme' : 'Use Dark Theme'}
              />
            </MenuItem>
            <MenuItem onClick={() => onLogoutClick()}>
              <Typography variant='subheading'>
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
};

TopPanel.propTypes = {
  appContext: PropTypes.object.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(TopPanel);
