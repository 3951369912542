import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  MenuItem,
  Chip,
  Icon,
  Paper,
} from '@material-ui/core';
import { Creatable } from 'react-select';
import { generateID } from '../helpers';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
});

const DropdownComponent = (props) => {
  let {
    fieldName,
    fieldObject,
    isAutoChange,
    isControlled,
    submissionValue,
    setSubmissionWithEvent,
    setSubmissionWithOutEvent,
    errorValue,
    classes,
  } = props;

  // console.log('In dropdown')
  // console.log(fieldObject.options);

  const [options, setOptions] = useState([]);
  const [optionsAdded, setOptionsAdded] = useState([]);
  const [useFsOptions, setUseFsOptions] = useState(false);
  const [isGrouped, setIsGrouped] = useState(false);

  const requiresObjectOptions = fieldObject.type === 'dropdownText';

  useEffect(() => {
    // console.log('checking dropdown with fieldObject');
    // console.log(fieldObject);
    if (fieldObject.hasOwnProperty('options')) {
      if (requiresObjectOptions) {
        // console.log('processing options into objects');
        let optionsTemp = fieldObject.options.map((option) => ({
          id: generateID(),
          label: option,
          value: option
        }));
        const useFsOptionsTemp = optionsTemp.length === 0 && fieldObject.hasOwnProperty('fsOptions');
        if (useFsOptionsTemp) {
          optionsTemp = fieldObject.fsOptions.map((option) => ({ ...option, label: option.value }));
        }
        const optionsNew = optionsTemp.concat(optionsAdded);
        let optionsSet = [];
        let optionsValues = [];
        optionsNew.forEach((opt) => {
          if (!optionsValues.includes(opt.value) && opt.value !== '') {
            optionsSet.push(opt);
            optionsValues.push(opt.value);
          }
        });
        // console.log('processed options');
        // console.log(optionsSet);
        setOptions(optionsSet);
        setUseFsOptions(useFsOptionsTemp);
      } else {
        const isGroupedTemp = fieldObject.hasOwnProperty('options') && Array.isArray(fieldObject.options) ? false : true;
        if (!isGroupedTemp) {
          const optionsUnique = [...new Set(fieldObject.options)];
          const spaceIndex = optionsUnique.findIndex((element) => element === '');
          if (spaceIndex > -1) {
            optionsUnique.splice(spaceIndex, 1);
          }
          setOptions(optionsUnique);
        } else {
          setOptions(fieldObject.options);
        }
        setIsGrouped(isGroupedTemp);
      }
    }
  }, [fieldObject, optionsAdded]);

  if (Array.isArray(submissionValue)) {
    submissionValue = submissionValue.length > 0 ? submissionValue[0].id : '';
  }

  const isDisabled = fieldObject.disabled || (isAutoChange && isControlled && !fieldObject.controlReturnedToUser);
  const placeHolderText = fieldObject.hasOwnProperty('defaultLabel') ? fieldObject.defaultLabel : `Please select a ${fieldObject.label}`;

  let optionSelected = requiresObjectOptions ? options.find((option) => option.value === submissionValue) : submissionValue;
  optionSelected = optionSelected ? optionSelected : "";

  // console.log(`options for "${fieldName}"`);
  // console.log(options);
  // console.log('submissionValue');
  // console.log(`"${submissionValue}"`);
  // console.log('optionSelected');
  // console.log(optionSelected);

  const NoOptionsMessage = (props) => {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }

  const inputComponent = ({ inputRef, ...props }) => {
    return <div ref={inputRef} {...props} />;
  }

  const Control = (props) => {
    return (
      <TextField
        fullWidth
        disabled={isDisabled}
        error={Boolean(errorValue)}
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }

  const Option = (props) => {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  const Placeholder = (props) => {
    return (
      <Typography
        color={Boolean(errorValue) ? "error" : "textSecondary"}
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }

  const SingleValue = (props) => {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  const ValueContainer = (props) => {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }

  const MultiValue = (props) => {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<Icon {...props.removeProps}>cancel</Icon>}
      />
    );
  }

  const Menu = (props) => {
    return (
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }

  const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  };

  const selectStyles = {
    input: base => ({
      ...base,
    }),
  };
  return (
    <Grid
      item
      xs={fieldObject.xs}
      sm={fieldObject.sm}
    >
      {/* TODO: Implement fieldObject.type !== 'dropdownMulti'*/}
      <FormControl fullWidth error={Boolean(errorValue)}>
        {fieldObject.type === 'dropdownText' && <Creatable
          classes={classes}
          styles={selectStyles}
          components={components}

          id={fieldName}
          defaultValue={fieldObject.defaultValue}
          isDisabled={isDisabled}
          isClearable
          isSearchable
          fullWidth={fieldObject.fullWidth}
          placeholder={placeHolderText}
          value={optionSelected}
          options={options}
          onChange={(optionChanged) => {
            // Handle inconsistent return types from `react-select`
            if (optionChanged === null) {
              setSubmissionWithOutEvent('');
            } else if (optionChanged.hasOwnProperty('value')) {
              setSubmissionWithOutEvent(optionChanged.value);
            } else {
              setSubmissionWithOutEvent(optionChanged);
            }
          }}
          onCreateOption={(optionCreated) => {
            const optionsAddedTemp = JSON.parse(JSON.stringify(optionsAdded));
            optionsAddedTemp.push({
              id: generateID(),
              label: optionCreated,
              value: optionCreated
            });
            setOptionsAdded(optionsAddedTemp);
            setSubmissionWithOutEvent(optionCreated);
          }}
        />}
        {fieldObject.type !== 'dropdownText' && <>
          <InputLabel htmlFor={fieldName}>
            {placeHolderText}
          </InputLabel>
          <Select
            native
            disabled={fieldObject.disabled || (isAutoChange && isControlled)}
            id={fieldName}
            MenuProps={{ PaperProps: { sx: { maxHeight: 10 } } }}
            onChange={setSubmissionWithEvent}
            value={submissionValue}
            size={fieldObject.size}
            variant={fieldObject.variant}
            fullWidth={fieldObject.fullWidth}
          >
            <option aria-label="None" value="" />
            {isGrouped && Object.keys(options).length ?
              Object.entries(options).map(([key, value]) => {
                return (
                  <optgroup label={key} key={key}>
                    {value.map((item, index) => (
                      <option value={item} key={index}>{item}</option>
                    ))}
                  </optgroup>
                );
              })
              : options && options.length ?
                options.map((item, index) => {
                  let label = item.label;
                  let value = item.value;
                  let key = item.id;
                  if (useFsOptions) {
                    label = item.value;
                    value = item.id;
                    key = item.id;
                  }
                  if (item != null && item.constructor.name !== "Object") {
                    label = item;
                    value = item;
                    key = index;
                  }
                  return (
                    <option
                      // disabled={submissionValue !== item}
                      value={value}
                      key={key}
                    >
                      {label}
                    </option>
                  );
                })
                : <></>
            }
          </Select>
        </>}
        {errorValue && <FormHelperText>{errorValue}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

DropdownComponent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldObject: PropTypes.object.isRequired,
  isAutoChange: PropTypes.bool.isRequired,
  isControlled: PropTypes.bool.isRequired,
  submissionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  setSubmissionWithEvent: PropTypes.func.isRequired,
  errorValue: PropTypes.string.isRequired,
};

export default withStyles(styles)(DropdownComponent);
