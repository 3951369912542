import { getCurrentYMD } from '../../helpers';
import { schemaProps } from './schemaMasterData';

const buLevel = {
  BCX: [
    'Octane',
    'CEB',
    'BCX Head Office',
    'Octane - Forklifts',
    'BCX Centurion',
    'Head Office TFS Sites',
    'TFS BCX',
    'CEB & Octane',
    'Tshebo Sites',
    'TFS Sites',
  ],
  Telkom: ['Openserve', 'Telkom', 'Bulk Diesel', 'Head Office'],
  YEP: ['YEP', 'Bruma', 'Yellow Pages', 'Fleet', 'Stationary'],
};

const kpi = {
  'Refirgerant Gas': [
    'Refirgerant Gas (C2H2)',
    'Refirgerant Gas (HFC134A)',
    'Refirgerant Gas (Nitrogen)',
    'Refirgerant Gas (R22)',
    'Refirgerant Gas (R404A)',
    'Refirgerant Gas (R407C)',
    'Refirgerant Gas (R410A)',
  ],
  'Waste Generated': [
    'Waste Generated: Battery',
    'Waste Generated: Copper',
    'Waste Generated: Metal',
    'Waste Generated: Optic Fibre',
  ],
  'Waste Recycled': [
    'Waste Recycled: Battery',
    'Waste Recycled: Copper',
    'Waste Recycled: Metal',
    'Waste Recycled: Optic Fibre',
  ],
  'Other': [
    'Diesel Consumption',
    'E-Waste Generated',
    'E-Waste Recycled',
    'Electricity Consumption',
    'Fleet Diesel Consumption',
    'Fleet Petrol Consumption',
    'Paper Recycled',
    'Waste to Landfill',
    'Water Consumption',
  ],
};

// For autoChange type control fields
// Map value in parent field to a value in this field
// `[value in parent field]: [value in this field]`
const unitObject = {
  'Diesel Consumption': 'litres',
  'Fleet Diesel Consumption': 'litres',
  'Fleet Petrol Consumption': 'litres',
  'Electricity Consumption': 'kwh',
  'E-Waste Generated': 'kg',
  'E-Waste Recycled': 'kg',
  'Paper Recycled': 'kg',
  'Refirgerant Gas (C2H2)': 'kg',
  'Refirgerant Gas (HFC134A)': 'kg',
  'Refirgerant Gas (Nitrogen)': 'kg',
  'Refirgerant Gas (R22)': 'kg',
  'Refirgerant Gas (R404A)': 'kg',
  'Refirgerant Gas (R407C)': 'kg',
  'Refirgerant Gas (R410A)': 'kg',
  'Waste Generated: Battery': 'kg',
  'Waste Generated: Copper': 'kg',
  'Waste Generated: Metal': 'kg',
  'Waste Generated: Optic Fibre': 'kg',
  'Waste Recycled: Battery': 'kg',
  'Waste Recycled: Copper': 'kg',
  'Waste Recycled: Metal': 'kg',
  'Waste Recycled: Optic Fibre': 'kg',
  'Waste to Landfill': 'kg',
  'Water Consumption': 'kl',
};

export const esgFormSchema = {
  name: 'ESG',
  icon: 'diversity_2',
  client: 'eSG',
  collectionPath: 'esgForm',
  subCollection: '',
  isSubCollection: false,
  rowsPerPageDefault: 10,
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  orderByDefault: 'reportMonth',
  schema: {
    reportMonth: {
      label: 'Report Month',
      name: 'reportMonth',
      ...schemaProps,
      xs: 12,
      sm: 12,
      type: 'date',
      defaultValue: getCurrentYMD(),
      inputAttributes: {
        min: '1970-01-01',
        max: getCurrentYMD()
      },
      validation: {
        required: {
          value: true,
          message: 'Please select date',
        },
      },
    },
    buLevelOne: {
      label: 'BU Level One',
      name: 'buLevelOne',
      ...schemaProps,
      xs: 12,
      sm: 12,
      type: 'dropdown',
      options: ['BCX', 'Telkom', 'YEP'],
      validation: {
        required: {
          value: true,
          message: 'Please select BU Level one',
        },
        allowedValues: {
          value: ['BCX', 'Telkom', 'YEP'],
          message: 'Please select from the list provided',
        },
      },
    },
    buLevelTwo: {
      label: 'BU Level Two',
      name: 'buLevelTwo',
      ...schemaProps,
      xs: 12,
      sm: 12,
      type: 'radio',
      controlField: 'buLevelOne',
      controlType: 'autoOption',
      data: buLevel,
      isHidden: 'controlBased',
      validation: {
        required: {
          value: true,
          message: 'Please select BU Level two',
        },
        allowedValues: {
          value: Object.values(buLevel).flat(),
          message: 'Please select a value from the list provided',
        },
      },
    },
    kpi: {
      label: 'KPI',
      name: 'kpi',
      ...schemaProps,
      xs: 12,
      sm: 12,
      type: 'dropdown',
      options: kpi,
      controlField: 'buLevelTwo',
      controlType: 'visibility',
      data: {},
      isHidden: 'controlBased',
      defaultValue: "",
      defaultLabel: "Please select a KPI",
      validation: {
        required: {
          value: true,
          message: 'Please select a KPI',
        },
        allowedValues: {
          value: Object.values(kpi).flat(),
          message: 'Please select from the list provided',
        },
      },
    },
    totalAmount: {
      label: 'Total Amount',
      name: 'totalAmount',
      ...schemaProps,
      controlField: 'kpi',
      controlType: 'visibility',
      isHidden: 'controlBased',
      validation: {
        required: {
          value: true,
          message: 'Please enter total amount',
        },
        numeric: {
          value: true,
          message: 'Please enter valid decimal amount',
        },
      },
    },
    unit: {
      label: 'Unit',
      name: 'unit',
      ...schemaProps,
      type: 'dropdown',
      controlField: 'kpi',
      controlType: 'autoChange',
      data: unitObject,
      isHidden: 'controlBased',
      variant: 'standard',
      defaultValue: '',
      validation: {
        required: {
          value: true,
          message: 'Please select unit',
        },
        allowedValues: {
          value: Object.values(unitObject),
          message: 'Please select unit from the list',
        },
      },
    },
    price: {
      label: 'Price per unit (Rands)',
      name: 'price',
      ...schemaProps,
      controlField: 'kpi',
      controlType: 'visibility',
      data: {
        'Diesel Consumption': 'litres',
        'Fleet Diesel Consumption': 'litres',
        'Fleet Petrol Consumption': 'litres',
        'Electricity Consumption': 'kwh',
        'Water Consumption': 'kl',
      },
      isHidden: 'controlBased',
      xs: 12,
      sm: 12,
      validation: {
        required: {
          value: true,
          message: 'Please enter price per unit (Rands)',
        },
        numeric: {
          value: true,
          message: 'Please enter valid decimal amount',
        }
      },
    },
  },
};
