import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const TabPanel = (props) => {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      {...other}
    >
      {tabValue === index && (
        <Typography component="span">{children}</Typography>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tabValue: PropTypes.any.isRequired,
};
