import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { withStyles } from '@material-ui/core/styles';

// Constants

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  tableWrapper: {
    overflow: 'auto',
    height: 'calc(100% - 120px)',
  },
  table: {
    height: '100%',
    minWidth: 1020,
  },
  tableBody: {
    // height: 'calc(100% - 56px)',
    // display: 'block',
    // overflow: 'auto',
  },
  tableRow: {
    // height: 'calc(100% - (56px + 56px))',
    // display: 'table',
    // overflowY: 'auto',
  },
});

// Functions

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (arr, comparator) => {
  const stabilizedThis = arr.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const EnhancedTable = (props) => {

  const {
    appContext,
    docItems,
    fieldsObject,
    selected,
    setSelected,
    orderByDefault,
    rowsPerPageDefault,
    rowsPerPageOptions,
    disableDelete,
    handleCreateNew,
    tableTitle,
    collectionRef,
    classes,
  } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(orderByDefault);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault);
  const [filterObject, setFilterObject] = useState({});

  const handleRequestSort = (event, columnName) => {
    let orderNew = 'desc';

    if (orderBy === columnName && order === 'desc') {
      orderNew = 'asc';
    }

    setOrderBy(columnName);
    setOrder(orderNew);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected('___all___');
    } else {
      setSelected('');
    }
  };

  const handleClick = (event, id) => {
    // console.log('selected');
    // console.log(selected);
    // console.log('id');
    // console.log(id);
    const selectedIsEmpty = selected.length === 0 || (selected.indexOf('') === 0 && selected.length === 1);
    // console.log('selectedIsEmpty');
    // console.log(selectedIsEmpty);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      if (selectedIsEmpty) {
        newSelected = [id];
      } else {
        newSelected = newSelected.concat(selected, id);
      }
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected)
  };

  const handleDelete = () => {
    let idsToDelete = selected;
    if (idsToDelete === '___all___') {
      idsToDelete = docItems.map((doc) => doc.id);
    }
    const deletePromises = [];
    idsToDelete.forEach((id) => {
      deletePromises.push(collectionRef.doc(id).delete());
    });
    Promise.all(deletePromises).then(() => {
      setSelected([]);
    })
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const docItemsFiltered = useMemo(
    () => Object.keys(filterObject).length === 0
      ? docItems
      : docItems.filter((item) => {
        console.log('filtering docs!');
        console.log(filterObject);
        return Object.entries(filterObject).reduce((acc, [fieldName, filterValue]) => {
          if (fieldsObject[fieldName].type.includes('text')) {
            // TODO: add search
          } else if (fieldsObject[fieldName].type.includes('date')) {
            // TODO: add start and and date selection
            // TODO: change date fields to be a Date object or unix time integer so we can easily preform operations on it (maybe?)
          } else if (['dropdown', 'radio'].includes(fieldsObject[fieldName].type)) {
            acc = acc && item[fieldName] === filterValue;
          } else {
            console.log(`Unknown type: ${fieldsObject[fieldName].type}`);
          }
          return acc;
        }, true);
      }),
    [docItems, filterObject]
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, docItemsFiltered.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <EnhancedTableToolbar
        appContext={appContext}
        fieldsObject={fieldsObject}
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        handleDelete={handleDelete}
        handleCreateNew={handleCreateNew}
        disableDelete={disableDelete}
        tableTitle={tableTitle}
        numSelected={selected.length}
      />
      <Grid container className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            fieldsObject={fieldsObject}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={docItemsFiltered.length}
            filterObject={filterObject}
          />
          <TableBody className={classes.tableBody}>
            {stableSort(docItemsFiltered, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(n => {
                // console.log('Rendering n');
                // console.log(n);
                const docIsSelected = isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, n.id)}
                    role="checkbox"
                    aria-checked={docIsSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={docIsSelected}
                    className={classes.tableRow}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={docIsSelected} />
                    </TableCell>
                    {/* {console.log(`Mapping over fieldsObject`)} */}
                    {Object.entries(fieldsObject).map(([fieldName, fieldObject], index) => {
                      let fieldValue = n[fieldName];
                      // console.log(`On fieldName "${fieldName}" with index "${index}" and fieldValue`);
                      // console.log(fieldValue);
                      // console.log('fieldObject');
                      // console.log(fieldObject);
                      if (fieldObject.type === 'dynamicField') {
                        //Combine fields within dynamicField into 1 string
                        fieldValue = '';
                        let hasAutoId = false;
                        n[fieldName].forEach((fieldValueElem, fieldValueIndex) => {
                          if (fieldValueIndex > 0) {
                            fieldValue += `\n\n`;
                          }
                          Object.values(fieldObject.dynamicSchema).forEach((dynamicSchemaElem, dynamicSchemaIndex) => {
                            if (dynamicSchemaElem.type === 'autoId') {
                              hasAutoId = true;
                            } else if (dynamicSchemaElem.type === 'dropdownMulti') {
                              if (Array.isArray(fieldValueElem[dynamicSchemaElem.name]) && fieldValueElem[dynamicSchemaElem.name].length > 0) {
                                // console.log('fieldValueElem[dynamicSchemaElem.name]');
                                // console.log(fieldValueElem[dynamicSchemaElem.name]);
                                fieldValue += `${dynamicSchemaElem.label}: "${fieldValueElem[dynamicSchemaElem.name][0].value}"`;
                              }
                            } else {
                              fieldValue += `${dynamicSchemaElem.label}: "${fieldValueElem[dynamicSchemaElem.name]}"`;
                              // Assumes autoId will always be at index 0
                              if (
                                dynamicSchemaIndex !== (Object.keys(fieldObject.dynamicSchema).length - (1 + hasAutoId ? 1 : 0))
                                && (dynamicSchemaIndex > 0 || (hasAutoId && dynamicSchemaIndex > 1))
                              ) {
                                // console.log(dynamicSchemaIndex);
                                fieldValue += `, `;
                              }
                            }
                          });
                        });
                      } else if (fieldObject.hasOwnProperty('fsOptions') && Array.isArray(n[fieldName])) {
                        let isFirst = true;
                        fieldValue = '';
                        n[fieldName].forEach((fieldValueElem) => {
                          // console.log('On fieldValueElem');
                          // console.log(fieldValueElem);
                          const selectedElems = fieldObject.fsOptions.filter((opt) => opt.id === fieldValueElem.id);
                          selectedElems.forEach((selectedElem) => {
                            // console.log('On selectedElem');
                            // console.log(selectedElem);
                            if (!isFirst) {
                              fieldValue += ', ';
                            } else {
                              isFirst = false;
                            }
                            // console.log('Adding value');
                            // console.log(selectedElem.value);
                            fieldValue += `${selectedElem.value}`;
                          });
                        });
                      } else if (fieldObject.hasOwnProperty('fsData') && Array.isArray(n[fieldName])) {
                        let isFirst = true;
                        fieldValue = '';
                        n[fieldName].forEach((fieldValueElem) => {
                          const selectedElems = fieldObject.fsData.filter((opt) => opt.id === fieldValueElem.id);
                          selectedElems.forEach((selectedElem) => {
                            if (!isFirst) {
                              fieldValue += ', ';
                            } else {
                              isFirst = false;
                            }
                            fieldValue += `${selectedElem.value}`;
                          });
                        });
                      }
                      // console.log('Final fieldValue');
                      // console.log(fieldValue);
                      if (index === 0) {
                        return (
                          <TableCell key={index} component="th" scope="row" padding="none">
                            {fieldValue}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={index}
                            align={fieldObject.validation.hasOwnProperty('numeric') ? 'right' : 'left'}
                          >
                            {fieldValue}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={docItemsFiltered.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onChangePage={(event, page) => setPage(page)}
        onChangeRowsPerPage={(event) => setRowsPerPage(event.target.value)}
      />
    </Paper>
  );
};

EnhancedTable.propTypes = {
  docItems: PropTypes.array.isRequired,
  collectionRef: PropTypes.object.isRequired,
  fieldsObject: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  orderByDefault: PropTypes.string.isRequired,
  rowsPerPageDefault: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  disableDelete: PropTypes.bool.isRequired,
  handleCreateNew: PropTypes.func,
  tableTitle: PropTypes.string.isRequired,
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
