export const schemaProps = {
  type: 'text',
  size: 'small',
  variant: 'outlined',
  disabled: false,
  fullWidth: true,
  multiline: false,
  selectUser: false,
  xs: 12,
  defaultValue: '',
  isAutoIncrement: false,
  numberOfDigits: 0,
  defaultFilterValue: '',
  isHidden: false,
  errorMessage: '',
  options: [],
  // fsOptions: {
  //   collectionPath: '',
  //   queryConditions: [
  //     {
  //       property: '',
  //       operator: '',
  //       value: '',
  //     }
  //   ]
  // },
  // data: {},
  // fsData: {
  //   collectionPath: '',
  //   queryConditions: [
  //     {
  //       property: '',
  //       operator: '',
  //       value: '',
  //     }
  //   ]
  // },
  validation: {},
};

export const stringTypeDefaultValidation = {
  required: { value: true, message: 'Required' },
  minLength: { value: 2, message: 'This field requires at least 2 characters' },
  maxLength: {
    value: 350,
    message: 'Exceeded maximum number of characters allowed for this field',
  },
};
