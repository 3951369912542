import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
  fieldset: {
    width: '100%'
  }
});

const RadioGroupComponent = (props) => {
  const {
    fieldName,
    fieldObject,
    submissionValue,
    setSubmissionWithEvent,
    errorValue,
    classes,
  } = props;

  const [options, setOptions] = useState([...fieldObject.options]);

  useEffect(() => {
    if (submissionValue || submissionValue === "") {
      if (options.length === 0 && fieldObject.hasOwnProperty('data')) {
        if (fieldObject.controlType === 'autoOption') {
          setOptions(Object.entries(fieldObject.data).reduce((acc, [controlFieldValue, opts]) => acc.concat(opts), []));
        } else {
          console.log(`RadioGroupComponent for field ${fieldName} has no options`);
        }
      } else if (fieldObject.options && fieldObject.options.length) {
        setOptions([...fieldObject.options]);
      } else if (!options || options.length === 0) {
        console.log(`RadioGroupComponent for field ${fieldName} has no options and no data`);
        // console.log(options);
        // console.log(fieldObject.options);
      }
    } else {
      setOptions([]);
    }
  }, [submissionValue, fieldObject]);


  return (
    <Grid
      container
      item
      xs={fieldObject.xs}
      sm={fieldObject.sm}
      direction="row"
    >
      <FormControl
        component="fieldset"
        className={classes.fieldset}
        error={Boolean(errorValue)}
      >
        <FormLabel component="legend">{fieldObject.label}</FormLabel>
        <RadioGroup
          aria-label={fieldName}
          name={fieldName}
          value={submissionValue}
          onChange={setSubmissionWithEvent}
        >
          <Grid container>
            {options.map((option) =>
              <Grid item md={2} sm={4} xs={6} key={option}>
                <FormControlLabel
                  value={option}
                  control={<Radio checked={submissionValue === option} color="primary" />}
                  label={option}
                />
              </Grid>
            )}
          </Grid>
        </RadioGroup>
        {errorValue && <FormHelperText>{errorValue}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

RadioGroupComponent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldObject: PropTypes.object.isRequired,
  submissionValue: PropTypes.string.isRequired,
  setSubmissionWithEvent: PropTypes.func.isRequired,
  errorValue: PropTypes.string.isRequired,
};

export default withStyles(styles)(RadioGroupComponent);
