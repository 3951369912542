import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getUnixTime } from '../helpers';
import { esgFormSchema } from './configs/esgFormSchema';
import { projectFormSchema } from './configs/projectFormSchema';
import { l2cDataMigrationTaskSchema } from './configs/l2cDataMigrationTaskSchema';
import { l2cDataMigrationTaskSchemaDemo } from './configs/l2cDataMigrationTaskSchemaDemo';
import { ssaSurveySchema } from './configs/ssaSurveySchema';
import FormEngine from '../app/components/FormEngine';

// Constants
const globalConfigs = {
  eSG: esgFormSchema,
  demoClient: projectFormSchema,
  // demoClient: l2cDataMigrationTaskSchemaDemo,
  l2C: l2cDataMigrationTaskSchema,
  stateSecurityAgency: ssaSurveySchema,  
};

const styles = (theme) => ({
  container: {
    height: `calc(100% - ${theme.sizes.topPanel}px)`,
    padding: (theme.spacing.unit * 3),
  },
  formBuilderGrid: {
    height: '100%',
    overflowY: 'auto',
  }
});

// Components
const FormBuilder = (props) => {
  const { ms, appContext, classes } = props;
  const { user, logServiceMount, logServiceUnmount } = appContext;
  const { client, uid } = user;
  const [config, setConfig] = useState({});
  const [unixTimeMount, setUnixTimeMount] = useState(getUnixTime());

  useEffect(() => {
    console.log(`Checking client: "${client}"`);
    console.log('globalConfigs[client]');
    console.log(globalConfigs[client]);
    console.log(`Client: "${client}" | Test ${Boolean(globalConfigs[client])}`);
    if (globalConfigs[client]) {
      setConfig(globalConfigs[client]);
    } else {
      console.log(`Waiting for valid config...`)
    }
    if (ms) {
      console.log(`Form Builder starting as MS...`)
      logServiceMount({
        unixTimeMount,
        uid: uid,
        projectID: client,
        serviceID: ms.componentName,
        serviceMeta: { msid: ms.id, title: ms.title }
      });
      return () => logServiceUnmount({
        unixTimeMount,
        unixTimeUnmount: getUnixTime(),
        uid: uid,
      });
    } else {
      console.log(`Form Builder starting as Component...`)
    }
  }, []);

  return (
    <Grid
      container
      spacing={8}
      className={classes.container}
    >
      <Grid
        item
        xs={12}
        container
        justify='flex-start'
        className={classes.formBuilderGrid}
      >
        <FormEngine
          appContext={appContext}
          submissionEnabled
          formConfig={config}
        />
      </Grid >
    </Grid >
  );
};

FormBuilder.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormBuilder);
