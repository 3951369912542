import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import Loading from '../app/components/Loading';
import ErrorPage from '../app/components/ErrorPage';
import { Route, Switch } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';

const Authentication = (props) => {
  const {children, appContext} = props;
  const {user, loading, loadingText, error, errorText, setLoggedIn} = appContext;
  if (loading) {
    return <Loading text={loadingText}/>;
  } else if (error) {
    return <ErrorPage error={errorText}/>;
  } else if (user) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return (
      <Switch>
        <Route path="/forgotPassword" render={() => <ResetPasswordForm />} />
        <Route path="/" render={() => <LoginForm setLoggedIn={setLoggedIn} />} />
      </Switch>
    );
  }
};

Authentication.propTypes = {
  children: PropTypes.node,
  appContext: PropTypes.object.isRequired
};

export default Authentication;