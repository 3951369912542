import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './app/App';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

ReactDOM.render(
  (
    <React.Fragment>
      <CssBaseline/>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="en">
          <App/>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </React.Fragment>
  ),
  document.getElementById('root')
);
