import React from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ErrorBackground from '../assets/img/error.png';

const styles = theme => ({
  all: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'white',
  },
  other: {
    color: theme.palette.secondary.dark,
    fontSize: '40px',
  },
  subText: {
    fontSize: '25px',
    color: theme.palette.secondary.dark,
  },
  errorText: {
    fontSize: '20px',
    color: theme.palette.secondary.dark,
  }
});

const Maintenance = (props) => {
  const {classes} = props;
  return (
    <div className={classes.all}>
      <img src={ErrorBackground} alt=''/>
      <h1 className={classes.other}>Under Maintenance</h1>
      <Link to='/'>
        <p className={classes.subText}>Click here to head home</p>
      </Link>
    </div>
  );
};

Maintenance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Maintenance);
