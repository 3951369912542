import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Typography,
  IconButton,
  Icon,
  Grid,
} from '@material-ui/core';
import useConfigParser from '../app/hooks/useConfigParser';
import Loading from '../app/components/Loading';
import EnhancedTable from './EnhancedTable';
import { getUnixTime } from '../helpers';
import { esgFormSchema } from '../form_builder/configs/esgFormSchema';
import { projectFormSchema } from '../form_builder/configs/projectFormSchema';
import { l2cDataMigrationTaskSchema } from '../form_builder/configs/l2cDataMigrationTaskSchema';
import { l2cDataMigrationTaskSchemaDemo } from '../form_builder/configs/l2cDataMigrationTaskSchemaDemo';
import { ssaSurveySchema } from '../form_builder/configs/ssaSurveySchema';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormEngine from '../app/components/FormEngine';
// import { FormModal } from './Modal';

// Constants
const styles = (theme) => ({
  buttonNormal: {
    width: '100%',
    border: 'solid lightgrey 1px',
    borderRadius: '10px',
  },
  buttonSelected: {
    width: '100%',
    border: 'solid lightgrey 1px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    height: `calc(100% - ${theme.sizes.topPanel}px)`,
    padding: (theme.spacing.unit * 3),
  },
  tableGrid: {
    // height: '50%',
  },
  formBuilderGrid: {
    height: '50%',
    overflowY: 'auto',
  },
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalHeader: {
    width: '100%',
    padding: (theme.spacing.unit * 3),
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const globalConfigs = {
  eSG: esgFormSchema,
  // demoClient: projectFormSchema,
  demoClient: l2cDataMigrationTaskSchemaDemo,
  l2C: l2cDataMigrationTaskSchema,
  stateSecurityAgency: ssaSurveySchema,
};

const checkStatus = ['Closed', 'Completed'];

// Components
const FormSubmissionViewer = (props) => {
  const {
    ms,
    appContext,
    formConfig,
    classes,
  } = props;
  const {
    drawerOpen,
    dimensions,
    user,
    logServiceMount,
    logServiceUnmount,
    firestore
  } = appContext;
  const client = user.client;
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState({});
  const [selectedDocId, setSelectedDocId] = useState('');
  const [existingSubmission, setExistingSubmission] = useState({});
  const [config, setConfig] = useState({});
  const [unixTimeMount, setUnixTimeMount] = useState(getUnixTime());
  const [fieldsObject, setFieldsObject] = useState({});
  const [selectedMulti, setSelectedMulti] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const resetSelected = () => {
    setExistingSubmission({});
    setSelectedDocId('');
    setSelectedMulti('');
    setModalOpen(false);
  };

  // TODO: Handle errors from configParser
  const {
    fieldsObject: fieldsObjectInitial,
    configParserLoading
  } = useConfigParser(firestore, config, {});

  useEffect(() => {
    if (ms) {
      logServiceMount({
        unixTimeMount,
        uid: user.uid,
        projectID: user.client,
        serviceID: ms.componentName,
        serviceMeta: { msid: ms.id, title: ms.title }
      });
      let tempConfig = {};
      if (formConfig && Object.keys(formConfig).length > 0) {
        console.log('Using passed formConfig');
        tempConfig = formConfig;
      } else if (globalConfigs[client]) {
        console.log('Determining formConfig');
        console.log(globalConfigs[client].client);
        console.log(globalConfigs[client].collectionPath);
        tempConfig = globalConfigs[client];
      }
      setConfig(tempConfig);
      return () => logServiceUnmount({
        unixTimeMount,
        unixTimeUnmount: getUnixTime(),
        uid: user.uid,
      });
    }
  }, []);

  useEffect(() => {
    // console.log(`In use Effect for fieldsObjectInitial with loading ${configParserLoading}`);
    // console.log(fieldsObjectInitial);
    if (!configParserLoading && fieldsObjectInitial) {
      setFieldsObject(fieldsObjectInitial);
      const processFirestoreDoc = (doc, fieldsObject) => {
        // console.log(`processFirestoreDoc CALLED!`);
        // console.log(`fieldsObjectInitial`);
        // console.log(fieldsObjectInitial);
        // console.log(`fieldsObject`);
        // console.log(fieldsObject);
        // console.log(`doc`);
        // console.log(doc);
        Object.keys(doc).forEach((fieldName) => {
          // console.log(`fieldName`);
          // console.log(fieldName);
          // console.log(`fieldsObject[fieldName]`);
          // console.log(fieldsObject[fieldName]);
          if (fieldsObject[fieldName].hasOwnProperty('type') && fieldsObject[fieldName].type === 'dynamicField') {
            const dynamicFieldData = doc[fieldName].map((docElem) => {
              // console.log('recurring processFirestoreDoc with data');
              // console.log(docElem);
              // console.log('recurring processFirestoreDoc with schema');
              // console.log(fieldsObject[fieldName].dynamicSchema);
              processFirestoreDoc(docElem, fieldsObject[fieldName].dynamicSchema);
              // console.log('Post recursion data');
              // console.log(docElem);
              return docElem;
            });
            doc[fieldName] = dynamicFieldData;
            // console.log('Post recursion dynamicFieldData');
            // console.log(doc[fieldName]);
          } else if (fieldsObject[fieldName].type === 'date') {
            const jsDate = new Date(doc[fieldName]);
            doc[fieldName] = jsDate.toISOString().split('T')[0];
          }
        });
      };

      let initialDataLoadRequired = true;
      const unsubDataListener = firestore
        .collection(`clients/${config.client}/${config.collectionPath}`)
        .onSnapshot((querySnapshot) => {
          // console.log(`Got updated snapshot`);
          // console.log(querySnapshot);
          const updatedDocs = {};
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc) => {
              const docData = doc.data();
              // console.log(`Calling processFirestoreDoc with`);
              // console.log(docData);
              processFirestoreDoc(docData, fieldsObjectInitial);
              // console.log(`Post processFirestoreDoc with`);
              // console.log(docData);
              updatedDocs[doc.id] = docData;
            });
            if (initialDataLoadRequired) {
              console.log(`Setting initial docs`);
              setDocs(updatedDocs);
              initialDataLoadRequired = false;
            } else {
              console.log(`Setting updated docs`);
              setDocs({
                ...docs,
                ...updatedDocs,
              });
            }
          } else {
            setDocs({});
          }
          setLoading(false);
        });
      return () => {
        unsubDataListener();
      };
    }
  }, [fieldsObjectInitial, configParserLoading]);

  const docItems = useMemo(
    () => Object.entries(docs).map(([key, value]) => ({
      id: key,
      ...value,
    })),
    [docs]
  );

  const handleSetSelectedMulti = (id) => {
    // console.log('id multi');
    // console.log(id);
    if (id === '___all___') {
      setSelectedMulti(docItems.map((doc) => doc.id));
    } else {
      setSelectedMulti(id);
      if (!Array.isArray(id) || id.length === 1) {
        setSelectedDocId(id);
        setExistingSubmission(docs[id]);
      } else {
        setExistingSubmission({});
        setSelectedDocId('');
      }
    }
  };

  const getModalStyle = () => {
    const adjustHeight = 0;
    const adjustWidth = drawerOpen ? dimensions.openSideNav : dimensions.collapsedSideNav;
    const adjustLeft = (adjustWidth / dimensions.width) * 50; // multiply by 50 (not 100) to get half of the adjust value
    const adjustTop = (adjustHeight / dimensions.height) * 50;
    const top = 50 + adjustTop;
    const left = 50 + adjustLeft;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  const collectionRef = firestore.collection(`clients/${config.client}/${config.collectionPath}`);

  // console.log(`Rendering FSV with`);
  // console.log(fieldsObject);
  // console.log(`selectedDocId`);
  // console.log(selectedDocId);
  // console.log(`existingSubmission`);
  // console.log(existingSubmission);
  if (loading) {
    return config ?
      <Loading text={`Loading Form`} /> :
      <Loading text={`Loading ${config.name} Form`} />;
  } else {
    const existingSubmissionId = selectedDocId.length === 1 ? selectedDocId[0] : '';
    return (
      <Grid
        container
        spacing={8}
        className={classes.container}
      >
        {/* <Typography variant='h4' style={{ margin: '25px 0px 22px 8px' }}>{`${config.name} Submissions`}</Typography> */}
        <Grid
          item
          container
          alignContent='center'
          xs={12}
          className={classes.tableGrid}
        >
          <EnhancedTable
            docItems={docItems}
            collectionRef={collectionRef}
            fieldsObject={fieldsObject}
            selected={selectedMulti}
            setSelected={handleSetSelectedMulti}
            orderByDefault={config.orderByDefault}
            rowsPerPageDefault={config.rowsPerPageDefault}
            rowsPerPageOptions={config.rowsPerPageOptions}
            disableDelete={config.disableDelete}
            handleCreateNew={() => setModalOpen(true)}
            tableTitle={`${config.name} Submissions`}
            appContext={props.appContext}
          />
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={selectedDocId || modalOpen}
            onClose={resetSelected}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <div className={classes.modalHeader}>
                <Typography variant='h3' id="modal-title">{existingSubmissionId ? 'Update' : 'Add'} {config.name}</Typography>
                <Tooltip title="Close">
                  <IconButton
                    onClick={resetSelected}
                    aria-label="Close"
                  >
                    <Icon fontSize='large'>
                      close
                    </Icon>
                  </IconButton>
                </Tooltip>
              </div>
              <FormEngine
                appContext={appContext}
                submissionEnabled={true}
                existingFields={fieldsObject}
                existingSubmission={existingSubmission}
                existingSubmissionId={existingSubmissionId}
                setExistingSubmission={setExistingSubmission}
                existingCollectionRef={collectionRef}
              />
            </div>
          </Modal>
        </Grid >
        {/* <Grid
          item
          xs={12}
          container
          justify='flex-start'
          className={classes.formBuilderGrid}
        >
          <FormEngine
            appContext={appContext}
            submissionEnabled={true}
            existingFields={fieldsObject}
            existingSubmission={existingSubmission}
            existingSubmissionId={existingSubmissionId}
            setExistingSubmission={setExistingSubmission}
            existingCollectionRef={collectionRef}
          />
        </Grid > */}
      </Grid >
    );
  }
};

FormSubmissionViewer.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  ms: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormSubmissionViewer);
