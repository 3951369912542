import { schemaProps, stringTypeDefaultValidation } from './schemaMasterData';

export const projectFormSchema = {
  name: 'Project',
  icon: 'playlist_add',
  client: 'demoClient',
  collectionPath: 'projects',
  subCollection: '',
  isSubCollection: false,
  rowsPerPageDefault: 10,
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  orderByDefault: 'name',
  // requiresProjects: false,
  // hasProjectsDropdown: true,
  // hasElementItems: false,
  // hasControlField: false,
  // controlField: '',
  schema: {
    name: {
      label: 'Project Name',
      name: 'name',
      ...schemaProps,
      xs: 4,
      validation: {
        required: {
          value: true,
          message: 'Please enter project name',
        },
        minLength: {
          value: 2,
          message: 'This field requires at least 2 characters'
        },
        maxLength: {
          value: 350,
          message: 'Exceeded maximum number of characters allowed for this field',
        },
      },
    },
    description: {
      label: 'Description',
      name: 'description',
      ...schemaProps,
      xs: 8,
      multiline: true,
      rows: 1,
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          value: true,
          message: 'Please enter projects description',
        },
      },
    },
    manager: {
      label: 'Project Manager',
      name: 'manager',
      ...schemaProps,
      type: 'dropdownMulti',
      fsOptions: {
        collectionPath: 'users',
        queryConditions: [
          {
            property: 'client',
            operator: '==',
            value: 'tHA-HPP',
          }
        ],
        fieldUsedAsOptionLabel: 'displayName'
      },
      xs: 6,
      validation: {
        required: {
          value: true,
          message: 'Please enter project manager',
        },
        minLength: {
          value: 1,
          message: 'Please select at least one project manager',
        },
      },
    },
    estimatedCost: {
      label: 'Estimated Cost',
      name: 'estimatedCost',
      ...schemaProps,
      xs: 3,
      validation: {
        required: {
          value: false,
          message: '',
        },
        numeric: {
          value: true,
          message: 'Please enter valid decimal amount',
        },
      },
    },
    estimatedValue: {
      label: 'Estimated Value',
      name: 'estimatedValue',
      ...schemaProps,
      xs: 3,
      validation: {
        required: {
          value: false,
          message: '',
        },
        numeric: {
          value: true,
          message: 'Please enter valid decimal amount',
        },
      },
    },
    workstream: {
      label: 'Workstream',
      name: 'workstream',
      ...schemaProps,
      xs: 12,
      type: 'dynamicField',
      validation: {
        required: { value: false, message: '' },
      },
      dynamicSchema: {
        id: {
          label: 'ID',
          name: 'id',
          type: 'autoId'
        },
        workstreamName: {
          label: 'Workstream Name',
          name: 'workstreamName',
          ...schemaProps,
          xs: 4,
          validation: {
            ...stringTypeDefaultValidation,
            required: {
              value: true,
              message: 'Please enter projects description',
            },
          },
        },
        workstreamDescription: {
          label: 'Workstream Description',
          name: 'workstreamDescription',
          ...schemaProps,
          xs: 8,
          multiline: true,
          rows: 1,
          validation: {
            ...stringTypeDefaultValidation,
            required: {
              value: true,
              message: 'Please enter projects description',
            },
          },
        },
        workstreamLead: {
          label: 'Workstream Lead',
          name: 'workstreamLead',
          ...schemaProps,
          type: 'dropdownMulti',
          fsOptions: {
            collectionPath: 'users',
            queryConditions: [
              {
                property: 'client',
                operator: '==',
                value: 'tHA-HPP',
              }
            ],
            fieldUsedAsOptionLabel: 'displayName'
          },
          validation: {
            required: {
              value: true,
              message: 'Please enter project manager',
            },
            minLength: {
              value: 1,
              message: 'Please select at least one project manager',
            },
          },
        },
      },
    },
  },
}
