import { getCurrentYMD, ColorCodes } from '../../helpers';
import { schemaProps, stringTypeDefaultValidation } from './schemaMasterData';

const employmentStatus = [
  'Full-time',
  'Part-time',
  'Seeking opportunities currently',
  'Not seeking employment',
  'Retired',
  'Prefer not to say',
];

const annualIncomeRanges = [
  'R1 - R4,800',
  'R4,801 - R9,600',
  'R9,601 - R19,200',
  'R19,201 - R38,400',
  'R38,401 - R76,800',
  'R76,801 - R153,600',
  'R153,601 - R307,200',
  'R307,200 - R614,400',
  'R614,401 - R1,228,800',
  'R1,228,801 - R2,457,600',
  'R2,457,601 or more',
];

const employmentStatusToAnnualIncomeRanges = {
  'Full-time': annualIncomeRanges,
  'Part-time': annualIncomeRanges,
  'Seeking opportunities currently': [],
  'Not seeking employment': [],
  'Retired': [],
  'Prefer not to say': '',
};

export const ssaSurveySchema = {
  name: 'SSA Survey',
  icon: 'format_list_bulleted',
  client: 'stateSecurityAgency',
  collectionPath: 'surveyDemo',
  subCollection: '',
  isSubCollection: false,
  disableDelete: false,
  rowsPerPageDefault: 10,
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  orderByDefault: 'name',
  // requiresProjects: false,
  // hasProjectsDropdown: true,
  // hasElementItems: false,
  // hasControlField: false,
  // controlField: '',
  schema: {
    firstNames: {
      label: 'First Names',
      name: 'firstNames',
      ...schemaProps,
      defaultValue: '',
      // xs: 4,
      validation: {
        required: {
          value: true,
          message: 'Please enter your First Names',
        },
        regexCheck: {
          pattern: '^[a-zA-Z]+(?:(?:\\\s|\\-|\\\/|\\\')?[a-zA-Z]+)*$',
          message: 'Please ensure your names uses only valid characters with no whitespace at the beginning or end.',
        },
      },
    },
    lastName: {
      label: 'Last Name',
      name: 'lastName',
      ...schemaProps,
      defaultValue: '',
      // xs: 4,
      validation: {
        required: {
          value: true,
          message: 'Please enter your Last Names',
        },
        regexCheck: {
          pattern: '^[a-zA-Z]+(?:[a-zA-Z-]+)*$',
          message: 'Please ensure your name uses only valid characters with no whitespace at the beginning or end.',
        },
      },
    },
    dateOfBirth: {
      label: 'Date Of Birth',
      name: 'dateOfBirth',
      ...schemaProps,
      type: 'date',
      // xs: 4,
      defaultValue: '',
      chipStyle: (chipItem) => {
        return { padding: '0px', fontSize: '1em' };
      },
      InputLabelProps: {},
      InputProps: {
        inputProps: { min: '1900-01-01', max: getCurrentYMD() },
      },
      validation: {
        required: { value: true, message: 'Please pick your date of birth' },
      },
    },
    age: {
      label: 'Age',
      name: 'age',
      ...schemaProps,
      defaultValue: '',
      // xs: 4,
      validation: {
        required: {
          value: true,
          message: 'Please enter your Age',
        },
        numeric: {
          value: true,
          message: 'Please ensure your age uses only valid numbers',
        },
        regexCheck: {
          pattern: '^\\d+$',
          message: 'Please ensure your age uses only valid numbers',
        },
        minValue: {
          value: 0,
          message: 'Please ensure your age is at least 0',
        },
        maxValue: {
          value: 150,
          message: 'Please ensure your age is at most 150',
        },
      },
    },
    employmentStatus: {
      label: 'Employment Status',
      name: 'employmentStatus',
      ...schemaProps,
      // xs: 4,
      type: 'dropdown',
      options: employmentStatus,
      validation: {
        required: {
          value: true,
          message: 'Please select your Employment Status',
        },
        allowedValues: {
          value: employmentStatus,
          message: 'Please select from the list provided',
        },
      },
    },
    annualIncomeRange: {
      label: 'Annual Income Range',
      name: 'annualIncomeRange',
      ...schemaProps,
      // xs: 6,
      type: 'dropdown',
      controlField: 'employmentStatus',
      controlType: 'autoOption',
      isHidden: 'controlBased',
      data: employmentStatusToAnnualIncomeRanges,
      variant: 'standard',
      defaultValue: '',
      validation: {
        required: {
          value: true,
          message: 'Please select a Annual Income Range',
        },
        allowedValues: {
          value: annualIncomeRanges,
          message: 'Please select a Annual Income Range from the list',
        },
      },
    },
  },
}
