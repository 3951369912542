import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  header: {
    position: 'sticky'
  },
});

const EnhancedTableHead = (props) => {
  const {
    fieldsObject,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    filterObject,
    classes,
  } = props;

  return (
    <TableHead classes={{root: classes.header}}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {Object.entries(fieldsObject).map(([fieldName, fieldObject]) => (
          <TableCell
            key={fieldName}
            align={fieldObject.validation.hasOwnProperty('numeric') ? 'right' : 'left'}
            // padding={fieldObject.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === fieldObject.id ? order : false}
          >
            <Tooltip
              title="Sort"
              placement={fieldObject.validation.hasOwnProperty('numeric') ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === fieldName}
                direction={order}
                onClick={(event) => onRequestSort(event, fieldName)}
              >
                {`${fieldObject.label}${filterObject.hasOwnProperty(fieldName) ? ' (filtered)' : ''}`}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  fieldsObject: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  filterObject: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTableHead);
