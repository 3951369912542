import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  mainContainer: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginRight: '20px',
  },
});

const Loading = (props) => {
  const {classes, text} = props;
  return (
    <div className={classes.mainContainer}>
      <Typography variant="subheading"  className={classes.text}>{text}</Typography>
      <CircularProgress mode='indeterminate' size={30} thickness={5}/>
    </div>
  );
};

export default withStyles(styles)(Loading);
