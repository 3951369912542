import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { generateID } from '../helpers';
import FormEngine from '../app/components/FormEngine';


const refreshAutoIds = (submission, fieldObjects) => {
  const submissionTemp = JSON.parse(JSON.stringify(submission));
  Object.keys(submissionTemp).forEach((fieldName) => {
    if (fieldObjects[fieldName].type === 'autoId') {
      submissionTemp[fieldName] = generateID();
    }
  });
  return submissionTemp;
};

const styles = (theme) => ({
  dynamicFieldCard: {
    width: '100%',
    margin: (theme.spacing.unit * 2),
    display: 'flex',
  },  
  dynamicFieldCardContent: {
    width: 'calc(100% - 56px)',
    height: '100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  }
});

const DynamicFieldComponent = (props) => {
  // console.log('DynamicFieldComponent props');
  // console.log(props);
  const {
    appContext,
    fieldName,
    fieldObject,
    submission,
    submissionValue,
    setSubmissionWithEvent,
    setSubmission,
    errorValue,
    setErrorsUpdateField,
    setErrorsRemoveField,
    level,
    classes,
  } = props;

  // console.log(errorValue);

  const addElem = () => {
    const submissionTemp = JSON.parse(JSON.stringify(submission));
    submissionTemp[fieldName].push(fieldObject.defaultValue[0]);
    setSubmission(submissionTemp);
  };

  // const fieldHasErrors = errorValue.hasOwnProperty(fieldName) || (Array.isArray(errorValue) && errorValue.length > 0);
  const fieldHasErrors = Array.isArray(errorValue) && errorValue.length > 0;

  return (
    <Grid
      item
      xs={fieldObject.xs}
      sm={fieldObject.sm}
    >
      <Paper style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
        <Typography variant='subtitle1'>{fieldObject.label}'s</Typography>
        <Grid item container spacing={16}>
          {Object.values(submission[fieldName]).map((submissionDynamicSchemaElem, index) => {
              const updateElem = (newValue) => {
                setErrorsRemoveField(index);
                const submissionTemp = JSON.parse(JSON.stringify(submission));
                // console.log("Old Value");
                // console.log(submissionTemp[fieldName][index]);
                // console.log("New Value");
                // console.log(newValue);
                submissionTemp[fieldName][index] = newValue;
                setSubmission(submissionTemp);
              };
              const dupeElem = () => {
                const submissionTemp = JSON.parse(JSON.stringify(submission));
                submissionTemp[fieldName].splice(index + 1, 0, refreshAutoIds(submissionTemp[fieldName][index], fieldObject.dynamicSchema));
                setSubmission(submissionTemp);
              };
              const deleteElem = () => {
                const submissionTemp = JSON.parse(JSON.stringify(submission));
                submissionTemp[fieldName].splice(index, 1);
                setSubmission(submissionTemp);
              };
              return (
                <Card key={index} classes={{ root: classes.dynamicFieldCard }}>
                  <CardContent classes={{ root: classes.dynamicFieldCardContent }}>
                    <FormEngine
                      appContext={appContext}
                      resetEnabled={fieldHasErrors}
                      existingFields={fieldObject.dynamicSchema}
                      existingErrors={fieldHasErrors && errorValue[index] !== undefined ? errorValue[index] : {}}
                      existingSubmission={submissionDynamicSchemaElem}
                      setExistingSubmission={updateElem}
                      level={level + 1}
                    />
                  </CardContent>
                  <CardActions className={classes.actions} disableActionSpacing>
                    <Tooltip title={`Duplicate this ${fieldObject.label}`}>
                      <IconButton
                        onClick={dupeElem}
                        aria-label={`Duplicate this ${fieldObject.label}`}
                      >
                        <Icon>content_copy</Icon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`Delete this ${fieldObject.label}`}>
                      <IconButton
                        onClick={deleteElem}
                        aria-label={`Delete this ${fieldObject.label}`}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </Card>
              )
            })}
          <Button onClick={addElem}>Click here to add a {fieldObject.label}</Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

DynamicFieldComponent.propTypes = {
  appContext: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldObject: PropTypes.object.isRequired,
  submission:  PropTypes.object.isRequired,
  submissionValue: PropTypes.array.isRequired,
  setSubmission: PropTypes.func.isRequired,
  setSubmissionWithEvent: PropTypes.func.isRequired,
  errorValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  setErrorsUpdateField: PropTypes.func.isRequired,
};

export default withStyles(styles)(DynamicFieldComponent);
