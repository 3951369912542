import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Scrollbars } from 'react-custom-scrollbars';
import {withStyles} from '@material-ui/core/styles';
import Loading from '../app/components/Loading';
import ErrorPage from '../app/components/ErrorPage';

const styles = (theme) => ({
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
    margin: '25px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25px',
  },
});

class Presentations extends React.Component {
  state = {
    user: this.props.appContext.user,
    presentations: this.props.ms.data,
    error: false,
    errorCode: null,
    loading: false,
    expanded: false,
  };

  handleExpandedChange = panelIndex => (event, expanded) => {
    this.setState({expanded: expanded ? panelIndex : false});
  };

  render() {
    const {classes, appContext} = this.props;
    const {presentations, error, errorCode, loading, expanded} = this.state;
    const {dimensions} = appContext;
    const sidePanelWidth = appContext.drawerOpen ? dimensions.openSideNav : dimensions.collapsedSideNav;
    const iFrameHeight = dimensions.height - (16 + dimensions.topPanel + 100 /*2x top and bottom margins*/ + ((presentations.length || 1 ) * 64 /*size of expanded panel*/));
    const iFrameWidth = dimensions.width - (100 + sidePanelWidth);
    
    if (!loading) {
      return (
        <div className={classes.outerDiv}>
          <Scrollbars
            autoHeight
            autoHeightMin={`calc(100vh - ${dimensions.topPanel + 50}px)`}
            renderThumbVertical={props => <div {...props} className={classes.scrollbar}/>}
            renderThumbHorizontal={props => <div {...props} className={classes.noScrollbar}/>}
          >
            <div className={classes.root}>
              {presentations.length && presentations.map((elem, index) => {
                return (
                  <ExpansionPanel expanded={expanded === index} onChange={this.handleExpandedChange(index)}>
                    <ExpansionPanelSummary expandIcon={<Icon color='primary'>expand_more</Icon>}>
                      <Typography>{elem.name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <iframe
                        title={elem.name}
                        src={elem.url}
                        frameborder='0'
                        width={iFrameWidth}
                        height={iFrameHeight}
                        allowfullscreen='true'
                        mozallowfullscreen='true'
                        webkitallowfullscreen='true'
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
              {!presentations.length ? <Typography>No Presentations</Typography> : null}
            </div>
          </Scrollbars>
        </div>
      );
    } else if (error) {
      return <ErrorPage errorCode={errorCode} error={error}/>;
    } else if (loading) {
      return <Loading text='Loading Presentations'/>;
    } else {
      return <ErrorPage error='Error'/>;
    }
  }
}

Presentations.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(Presentations);
