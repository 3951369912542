import { generateID, buildFixedLengthNumber } from '../../helpers';
import { useState, useEffect } from 'react';
import { documentId } from "firebase/firestore";

const useConfigParser = (firestore, formConfig, existingFields) => {

  // console.log('useConfigParser: formConfig');
  // console.log(formConfig);
  // console.log('useConfigParser: existingFields');
  // console.log(existingFields);

  // Build submitObject with the default values
  const buildSubmitObject = (acc, [fieldName, fieldObject]) => {
    // console.log(`In buildSubmitObject with "${fieldName}"`);
    if (fieldObject.type === 'autoId') {
      fieldObject.defaultValue = generateID();
    }

    if (fieldObject.type === 'dynamicField') {
      fieldObject.defaultValue = [Object.entries(fieldObject.dynamicSchema).reduce(buildSubmitObject, {})];
    }

    if (!fieldObject.isHidden) {
      acc[fieldName] = fieldObject.defaultValue; // adding field to submitObject
    }
    
    return acc;
  };

  const doFirestoreQuery = async (fsQueryData) => {
    // TODO: Ensure validations for all fields are done
    // console.log('preforming FS Query with fsQueryData');
    // console.log(fsQueryData);
    const collectionRef = firestore.collection(`${fsQueryData.collectionPath}`);
    const queryToPreform = await fsQueryData.queryConditions.reduce(async (accParam, condition) => {
      const queryAcc = await accParam;
      let conditionProperty = condition.property;
      let conditionValue = condition.value;
      if (conditionValue != null && conditionValue.constructor.name === "Object") {
        conditionValue = await doFirestoreQuery(condition.value);
      }
      if (condition.property === 'id') {
        conditionProperty = '__name__';
        // const refArr = conditionProperty.map(id => firestore.collection("media").doc(id));
      }
      return queryAcc.where(conditionProperty, condition.operator, conditionValue);
    }, collectionRef);
    const querySnapshot = await queryToPreform.get();
    let fsQueryResult = {};
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc) => {
        fsQueryResult[doc.id] = doc.data();
      });
    }
    // console.log('fsQueryResult');
    // console.log(fsQueryResult);

    if (fsQueryData.hasOwnProperty('fieldUsedAsOptionLabel') && fsQueryData.fieldUsedAsOptionLabel) {
      // Converts query result into an Array of option objects ready for the dropdown component
      // console.log('Processing fsQueryResult');
      fsQueryResult = Object.entries(fsQueryResult).map(([docId, docData]) => ({
        id: docId,
        value: docData[fsQueryData.fieldUsedAsOptionLabel],
      }));
    } else if (fsQueryData.hasOwnProperty('postProcess') && fsQueryData.postProcess) {
      // Converts query result into an Array of anything returned by the `postProcess` function
      // console.log('Processing fsQueryResult with `postProcess`');
      fsQueryResult = Object.entries(fsQueryResult).map(fsQueryData.postProcess);
    } else if (fsQueryData.hasOwnProperty('postProcessChildArray') && fsQueryData.postProcessChildArray) {
      // Converts query result into an Array where the values pairs from `postProcess` Array elements are lifted into a single Array. Elements returned by `postProcess` should all be Arrays.
      // console.log('Processing fsQueryResult with `postProcessChildArray`');
      fsQueryResult = Object.entries(fsQueryResult).map(fsQueryData.postProcessChildArray);
      // console.log('fsQueryResult');
      // console.log(fsQueryResult);
      let fsQueryResultTemp = [];
      fsQueryResult.forEach((elem) => fsQueryResultTemp.concat(elem));
      // console.log('fsQueryResultTemp');
      // console.log(fsQueryResultTemp);
      fsQueryResult = fsQueryResultTemp;
    } else if (fsQueryData.hasOwnProperty('postProcessObject') && fsQueryData.postProcessObject) {
      // Converts query result into an Object where the key/value pairs from `postProcessObject` Array elements are lifted into a single object. Elements returned by `postProcessObject` should all be objects.
      // console.log('Processing fsQueryResult with `postProcessObject`');
      // console.log(fsQueryData.postProcessObject);
      // console.log('pre');
      let tempOptionsData = Object.entries(fsQueryResult).map(fsQueryData.postProcessObject);
      fsQueryResult = {};
      tempOptionsData.forEach((option) => {
        Object.keys(option).forEach((key) => {
          fsQueryResult[key] = option[key];
        });
      });
      // console.log('post');
      // console.log(fsQueryResult);
    }
    // console.log('fsQueryResult Processed');
    // console.log(fsQueryResult);
    return fsQueryResult; 
  };

  // Build FormEngine friendly version of schema
  const buildFieldObjects = async (accParam, [fieldName, fieldObject]) => {
    const acc = await accParam;
    if (fieldObject.hasOwnProperty('fsOptions') || fieldObject.hasOwnProperty('fsData')) {
      // TODO: need to re-run this code on update of any docs in Firestore
      let fsQueryData = {};
      let saveAsFieldName = '';
      if (fieldObject.hasOwnProperty('fsOptions')) {
        fsQueryData = fieldObject.fsOptions;
        saveAsFieldName = 'fsOptions';
      } else if (fieldObject.hasOwnProperty('fsData')) {
        fsQueryData = fieldObject.fsData;
        saveAsFieldName = 'data';
      } else {
        console.log(`Bad Firestore query config for field "${fieldName}"`);
      }
      console.log(`getting data from Firestore for: "${fieldName}"`);
      const fsQueryResults = await doFirestoreQuery(fsQueryData);
      const fieldObjectTemp = JSON.parse(JSON.stringify(fieldObject));
      // console.log('fsQueryResults');
      // console.log(fsQueryResults);
      fieldObjectTemp[saveAsFieldName] = fsQueryResults;

      if (fieldObject.isAutoIncrement) {
        const fsOptionsArr = fieldObjectTemp.fsOptions.map(obj => parseInt(obj.value.slice(2)));
        const incrementedValue = Math.max(...fsOptionsArr) + 1;

        // Assigning configured number of leading zeros
        const numberOfDigits = fieldObject.numberOfDigits;
        fieldObjectTemp.defaultValue = buildFixedLengthNumber(incrementedValue, numberOfDigits, fieldObjectTemp.defaultValue);
      }
      acc[fieldName] = fieldObjectTemp;
    } else if (fieldObject.type === 'dynamicField') {
      const fieldObjectTemp = JSON.parse(JSON.stringify(fieldObject));
      fieldObjectTemp.dynamicSchema = await Object.entries(fieldObject.dynamicSchema).reduce(buildFieldObjects, Promise.resolve({}));
      acc[fieldName] = fieldObjectTemp;
    } else {
      acc[fieldName] = fieldObject;
    }
    //TODO: add schema validation here
    return acc;
  };

  // Validate config and generate default submitObject and fieldsObject according to schema
  const configParser = async (config) => {
    if (!config || config.constructor.name !== "Object" || Object.keys(config).length < 1) {
      return [{}, {}, 'Invalid or empty config.'];
    }

    if (!config.hasOwnProperty('name') || config.name === '') {
      return [{}, {}, 'The "name" property is missing or undefined in the config file.'];
    }
  
    if (!config.hasOwnProperty('client') || config.client === '') {
      return [{}, {}, 'The "client" property is missing or undefined in the config file.'];
    }
  
    if (!config.hasOwnProperty('collectionPath') || config.collectionPath === '') {
      return [{}, {}, 'The "collectionPath" property is missing or undefined in the config file.'];
    }
  
    if (!config.hasOwnProperty('schema') || Object.keys(config.schema).length < 1) {
      return [{}, {}, 'The "schema" property is missing in the config file'];
    }

    for (const fieldObject of Object.values(config.schema)) {
      if (!fieldObject.hasOwnProperty('type')) {
        return [{}, {}, `The "type" property is missing for the input field ${fieldObject.label}.`];
      }

      if (!fieldObject.hasOwnProperty('isHidden')) {
        return [{}, {}, `The "isHidden" flag is missing for the input field ${fieldObject.label}.`];
      }

      if (!fieldObject.hasOwnProperty('disabled')) {
        return [{}, {}, `The "disabled" flag is missing for the input field ${fieldObject.label}.`];
      }

      if (!fieldObject.hasOwnProperty('validation') || fieldObject.validation === undefined) {
        return [{}, {}, `The "validation" object is missing or undefined for the input field ${fieldObject.label}.`];
      }

      if (!fieldObject.hasOwnProperty('isAutoIncrement') && !fieldObject.hasOwnProperty('numberOfDigits')) {
        return [{}, {}, `The "isAutoIncrement" or "numberOfDigits" property is missing for the input field ${fieldObject.label}.`];
      }

      if (fieldObject.hasOwnProperty('controlField')) {
        //TODO: make sure specified control field exists
        if (!fieldObject.hasOwnProperty('controlType')) {
          return [{}, {}, `Missing 'controlType' property for the input field ${fieldObject.label}.`];
        }

        if (!fieldObject.hasOwnProperty('data') && !fieldObject.hasOwnProperty('fsData') && !fieldObject.hasOwnProperty('options')) {
          return [{}, {}, `Missing 'data' or 'options' property for the input field ${fieldObject.label}.`];
        }

        if (fieldObject.hasOwnProperty('data')  && (fieldObject.data === null || (fieldObject.data.constructor.name !== "Object" && !Array.isArray(fieldObject.data)))) {
          return [{}, {}, `Invalid value for the 'data' property in the input field ${fieldObject.label}.`];
        }
      }
  
      if (fieldObject.type === 'dynamicField' && 
      (!fieldObject.hasOwnProperty('dynamicSchema') || Object.keys(fieldObject.dynamicSchema).length < 1)) {
        return [{}, {}, 'Invalid dynamic schema.'];
      }

      if (fieldObject.hasOwnProperty('fsOptions') || fieldObject.hasOwnProperty('fsData')) {
        if(fieldObject.fsOptions) {
          if ((!fieldObject.fsOptions.hasOwnProperty('collectionPath') || fieldObject.fsOptions.collectionPath === '') 
          || !fieldObject.fsOptions.hasOwnProperty('queryConditions')) {
            return [{}, {}, `Invalid Firestore query for the field ${fieldObject.label}.`];
          }
        }
        
        if (fieldObject.fsData) {
          if ((!fieldObject.fsData.hasOwnProperty('collectionPath') || fieldObject.fsData.collectionPath === '') 
          || !fieldObject.fsData.hasOwnProperty('queryConditions')) {
            return [{}, {}, `Invalid Firestore query for the field ${fieldObject.label}.`];
          }
        }
      }

      const validationObject = fieldObject.validation;
      for (const [ruleName, ruleObject] of Object.entries(validationObject)) {
        if (!ruleObject.hasOwnProperty('message')) {
          return [{}, {}, `Invalid validation object rule for the field ${fieldObject.label} - Missing "message" property.`];
        }

        if (ruleName === 'regexCheck') {
          if (!ruleObject.hasOwnProperty('pattern')) {
            return [{}, {}, `Invalid validation object rule for the field "${fieldObject.label}" - Missing "pattern" property.`];
          }

          if (ruleObject.hasOwnProperty('pattern') && !validationObject.regexCheck.pattern) {
            return [{}, {}, `Missing regex pattern configuration for the field ${fieldObject.label}.`];
          }
        }
      }
    }

    const fieldObjects = await Object.entries(config.schema).reduce(buildFieldObjects, Promise.resolve({}));
    const submitObject = Object.entries(fieldObjects).reduce(buildSubmitObject, {});

    return [submitObject, fieldObjects, ''];
  };

  const [fieldsObject, setFieldsObject] = useState(existingFields);
  const [submitObjectConfig, setSubmitObjectConfig] = useState({ test: '' });
  const [configParserLoading, setConfigParserLoading] = useState(true);
  const [configParserError, setConfigParserError] = useState('');

  useEffect(() => {
    const validateConfig = async () => {
      const [submitObjectInitial, fieldsObjectInitial, error] = await configParser(formConfig);
      console.log('configParser returned');
      console.log(submitObjectInitial);
      console.log(fieldsObjectInitial);
      console.log(error);
      if (error) {
        console.log(`Received an error while parsing the config: ${error}`);
        setConfigParserError(error);
      } else {
        // console.log('CALLING setSubmitObjectConfig with');
        // console.log(submitObjectInitial);
        setSubmitObjectConfig(submitObjectInitial);
        setFieldsObject(fieldsObjectInitial);
      }
      setConfigParserLoading(false);
    };

    if (firestore) {
      if (formConfig && Object.keys(formConfig).length > 0) {
        // console.log(`in useConfigParser with formConfig`);
        // console.log(formConfig);
        validateConfig();
      } else if (existingFields && Object.keys(existingFields).length > 0) {
        // console.log(`in useConfigParser with existingFields`);
        // console.log(existingFields);
        const submitObject = Object.entries(existingFields).reduce(buildSubmitObject, {});
        // console.log('CALLING setSubmitObjectConfig with');
        // console.log(submitObject);
        setSubmitObjectConfig(submitObject);
        setFieldsObject(existingFields);
        setConfigParserLoading(false);
      }
    } else {
      setConfigParserError([{}, {}, 'Firebase instance is not valid']);
    }

  }, [formConfig]);

  // console.log('useConfigParser returning');
  // console.log(fieldsObject);
  // console.log(submitObjectConfig);
  // console.log(configParserLoading);
  // console.log(configParserError);

  return {
    fieldsObject,
    submitObjectConfig,
    configParserLoading,
    configParserError,
  };

};

export default useConfigParser;
