import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  container: {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
    padding: '2%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '300px',
    height: '420px',
  },
  link: {
    textDecoration: 'none',
  },
  icon: {
    fontSize: '250px',
    width: '100%',
    height: '100%',
  },
  demoIcon: {
    color: theme.customColors.demoIcon,
    fontSize: '250px',
    width: '100%',
    height: '100%',
  },
});

const LinkCard = (props) => {
  const {classes, microservice, /*hoveredIndex,*/ index, setHoveredIndex} = props;
  const isDisabled = !(microservice.data || microservice.componentName === 'LinkCardSelector') || microservice.disabled;
  return (
    <Grid item id={'cardNum' + index}>
      <ButtonBase
        component={Link}
        to={microservice.path}
        disabled={isDisabled}
        id={isDisabled ? 'disabled' :  microservice.componentName}
      >
        <Card className={classes.card} onMouseOver={() => setHoveredIndex(index)} onMouseOut={() => setHoveredIndex(null)}>
          <CardHeader
            title={microservice.title}
            subheader={microservice.disabled ? 'Under Maintenance' : microservice.componentName === 'Demo' ? 'Demo Version' : ''}
            color='primary'
            align='center'
          />
          <CardContent className={classes.content}>
            <Icon
              className={microservice.componentName === 'Demo' && microservice.data.length && !microservice.disabled ? classes.demoIcon : classes.icon}
              color={!(microservice.data || microservice.componentName === 'LinkCardSelector') || microservice.disabled ? 'disabled' : 'inherit'}
            >
              {microservice.icon}
            </Icon>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

LinkCard.defaultProps = {
  microservice: {},
};

LinkCard.propTypes = {
  microservice: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setHoveredIndex: PropTypes.func.isRequired,
  hoveredIndex:  PropTypes.number,
  index:  PropTypes.number.isRequired,
};

export default withStyles(styles)(LinkCard);
