import React from 'react';
import Highlight from "./highlight";
import {
  AreaSeries,
  Borders,
  Crosshair,
  DiscreteColorLegend,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  makeVisFlexible,
  RectSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis';

const FlexibleXYPlot = makeVisFlexible(XYPlot);

const MultiChart = (props) => {
  const {
    interaction,
    data,
    format,
    xAxis,
    yAxis,
    crosshair
  } = props;
  if (!data) {
    return <div name='No data passed'/>;
  }
    
  const {
    lineSeriesArr,
    markSeries,
    areaSeries,
    rectSeries
  } = data;

  const {
    setLastDrawLocation,
    lastDrawLocation,
    highlightXArr,
    highlighted
  } = interaction;

  const {
    colors,
    horGridLines,
    verGridLines,
    horGridLineValues,
    verGridLineValues,
    legend,
  } = format;

  const {
    values,
    titleFormat,
    itemsFormat
  } = crosshair;

  const maxValue = Math.max(...[
    ...([].concat(...(lineSeriesArr || []))).map(d => d.y),
    ...(markSeries || []).map(d => d.y),
    ...(areaSeries || []).map(d => d.y),
    ...(rectSeries || []).map(d => d.y0)
  ]);

  const yDomain = [0, 1.02 * maxValue];

  const legendItems = legend ? Object.keys(legend).map((key) => ({title: legend[key], color: colors[key], disabled: false})) : [];
  return (
    <React.Fragment>
      {legend && <div className="legend">
        <DiscreteColorLegend
          orientation='horizontal'
          items={legendItems}
          className={'legend'}
        />
      </div>}
      <FlexibleXYPlot
        margin={{top: 20, bottom: 50, left: 40, right: 20}}
        onMouseLeave={() => highlightXArr.map(func => func(null, 0))}
        yDomain={yDomain}
        xDomain={lastDrawLocation && [lastDrawLocation.left, lastDrawLocation.right]}
      >
      {horGridLines && <HorizontalGridLines tickValues={horGridLineValues} />}
      {verGridLines && <VerticalGridLines tickValues={verGridLineValues} />}
        {lineSeriesArr && lineSeriesArr.map((lineSeries, index) => {
          return (<LineSeries
            data={lineSeries}
            onNearestX={highlightXArr[index]}
            stroke={colors.line[index]}
            strokeWidth={2}
          />);
        })}
        {areaSeries && <AreaSeries
          opacity={0.5}
          curve="curveNatural"
          data={areaSeries}
          fill={colors.area}
        />}
        {markSeries && <MarkSeries
          size={1}
          data={markSeries}
          stroke={colors.mark}
          fill={colors.markFill}
          colorType='literal'
          sizeType='literal'
        />}
        {rectSeries && <RectSeries
          size={1}
          data={rectSeries}
          color={colors.rect}
          fill='black'
        />}
        {highlighted && <LineSeries
          data={[
            {x: highlighted && highlighted.x, y: yDomain[0]},
            {x: highlighted && highlighted.x, y: yDomain[1]}
          ]}
          stroke='#ececec'
          strokeWidth={2}
        />}
        {highlighted && <MarkSeries
          data={[{
            x: highlighted && highlighted.x,
            y: highlighted && highlighted.y
          }]}
          strokeWidth={5}
          stroke={colors.line}
          fill='white'
        />}
        <Borders style={{all: {fill: '#fff'}}} />
        <XAxis
          tickSize={xAxis && xAxis.tickSize && xAxis.tickSize}
          tickLabelAngle={xAxis && xAxis.tickAlign && xAxis.tickAlign}
          tickTotal={xAxis && xAxis.tickTotal && xAxis.tickTotal}
          tickValues={xAxis && xAxis.values.length && xAxis.values}
          tickFormat={xAxis && xAxis.tickFormat && xAxis.tickFormat}
        />
        <YAxis
          tickSize={yAxis && yAxis.tickSize && yAxis.tickSize}
          tickLabelAngle={yAxis && yAxis.tickAlign && yAxis.tickAlign}
          tickTotal={yAxis && yAxis.tickTotal && yAxis.tickTotal}
          tickValues={yAxis && yAxis && yAxis.values}
          tickFormat={yAxis && yAxis.tickFormat && yAxis.tickFormat}
        />
        {setLastDrawLocation && <Highlight onBrushEnd={area => setLastDrawLocation(area)}/>}
        <Crosshair
          values={values}
          className={'test-class-name'}
          titleFormat={titleFormat}
          itemsFormat={itemsFormat}
        />
      </FlexibleXYPlot>
    </React.Fragment>
  );
};

export default MultiChart;
