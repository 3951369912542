import React from 'react';
import PropTypes from 'prop-types';
import TableauIFrame from './TableauIFrame';
import Loading from '../app/components/Loading';
import ErrorPage from '../app/components/ErrorPage';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getUnixTime } from '../helpers';

class TableauContainer extends React.Component {
  state = {
    user: this.props.appContext.user,
    url: this.props.appContext.url,
    tableauApiUrl: this.props.appContext.tableauApiUrl,
    apiUrl: this.props.appContext.apiUrl,
    microservice: this.props.ms,
    ticket: null,
    error: false,
    errorCode: null,
    loading: true,
    isFirefox: false,
    unixTimeMount: getUnixTime()
  };

  setLoading = () => {
    this.setState({loading: false});
  };

  serverTimeout = () => {
    if (this.state.loading) {
      this.requestTimeout = undefined;
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        if (this.state.loading && !this.state.error) {
          this.setState({
            error: 'The request to Tableau Server has timed out.',
            errorCode: 408,
            loading: false,
          });
        }
      }, 30000);
    }
  };

  handleError = (response) => {
    if (!response.ok) {
      if (response.statusText === 'TypeError: Cannot read property \'forEach\' of undefined') {
        this.props.appContext.checkClient();
        const errorObj = {error: 'Client Error'};
        throw errorObj;
      } else {
        const errorObj = {
          error: response.statusText,
          errorCode: response.status,
        };
        throw errorObj;
      }
    }
    return response.json();
  };

  componentDidMount() {
    const { tableauApiUrl, user, microservice, unixTimeMount } = this.state;
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      fetch(`${tableauApiUrl}/tableau/ticket`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken,
          },
          body: JSON.stringify({ site: 'default' })
      })
      .then(this.handleError)
      .then(respJson => this.setState({ ticket: `/trusted/${respJson.ticket}${microservice.data}` }))
      .catch(({error, errorCode}) => {
        if (error === 'Client Error') {
          this.setState({loadingText: 'Updating Client'});
        } else if (error && errorCode) {
          this.setState({error, errorCode, loading: false, loadingText: ''});
        } else {
          this.setState({error: 'Tableau dashboards not available', errorCode: 503, loading: false, loadingText: ''});
        }
      });
    })
    .catch(({error, errorCode}) => this.setState({error, errorCode}));
    this.props.appContext.logServiceMount({
      unixTimeMount, 
      uid: user.uid, 
      projectID: user.client,
      serviceID: microservice.componentName, 
      serviceMeta: { msid: this.props.ms.id, title: this.props.ms.title }
    });
  };

  componentWillUnmount() {
    const { unixTimeMount, user } = this.state;
    clearTimeout(this.requestTimeout);
    this.props.appContext.logServiceUnmount({
      unixTimeMount,
      unixTimeUnmount: getUnixTime(),
      uid: user.uid,
    });
  };

  render() {
    const{ mainAreaWidth, mainAreaHeight } = this.props.appContext.dimensions;
    const { ticket, error, errorCode, loading } = this.state;
    if (!error && ticket && ticket !== '') {
      return <TableauIFrame
        appContext={this.props.appContext}
        ms={this.props.ms}
        ticket={ticket}
        height={mainAreaHeight}
        width={mainAreaWidth}
      />;
    } else if (error) {
      return <ErrorPage errorCode={errorCode} error={error}/>;
    } else if (loading) {
      return <Loading text={'Authenticating with Tableau Server'}/>;
    } else {
      return <ErrorPage error={'Error'}/>;
    }
  }
}

TableauContainer.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default TableauContainer;
