export const getUnixTime = () =>
  Math.round(new Date().getTime() / 1000).toString();

export const getCurrentYMD = () => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().padStart(4, '0');

  return `${year}-${month}-${day}`;
};

export const ColorCodes = {
  'In Progress': '#629348',
  Closed: '#e60000',
  Completed: '#008000',
  Delayed: '#ce8d1c',
  Lagging: '#3d0015',
  Low: '#008000',
  Critical: '#b00c0c',
  Rare: '#008000',
  Unlikely: '#629348',
  Possible: '#a9992d',
  Likely: '#e60000',
  'Almost Certain': '#b00c0c',
  Insignificant: '#008000',
  Minor: '#629348',
  Medium: '#a9992d',
  High: '#e60000',
  Severe: '#b00c0c',
};

export const validDatePattern =
  /^([0-9]{4})(-?)(1[0-2]|0[1-9])\2(3[01]|0[1-9]|[12][0-9])$/;

export const generateID = () =>
  String.fromCharCode(65 + Math.floor(Math.random() * 26)) +
  Math.floor(Date.now() * Math.random()).toString(8);

export const isNumeric = (testValue) => {
  const regexObj = new RegExp('^\\s*[+-]?(\\d+|\\d*\\.\\d+|\\d+\.\\d*)([Ee][+-]?\\d+)?\\s*$');
  return regexObj.test(testValue);
};

export const buildFixedLengthNumber = (value, numberOfDigits, defaultValue = '') => {
  const digitsLen = value.toString().length;
  if (Number.isInteger(value) && digitsLen < numberOfDigits) {
    const zerosToAdd = numberOfDigits - digitsLen;
    const leadingZeros = "0".repeat(zerosToAdd);
    return `${defaultValue}${leadingZeros}${value.toString()}`;
  } else {
    return `${defaultValue}${value.toString().padStart(numberOfDigits, '0')}`;
  }
};
