import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { getUnixTime } from '../helpers';

const styles = {
  outerDiv: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "25px",
  }
};

const PowerBIContainer = (props) => {
  const { classes, appContext, ms } = props;
  const { dimensions } = appContext;
  const sidePanelWidth = appContext.drawerOpen
    ? dimensions.openSideNav
    : dimensions.collapsedSideNav;
  const paperHeight = dimensions.height - (dimensions.topPanel + 50); /*2x top and bottom margins*/
  const paperWidth = dimensions.width - (50 + sidePanelWidth);

  React.useEffect(() => {
    const timeMount = getUnixTime();

    appContext.logServiceMount({
      unixTimeMount: timeMount,
      uid: appContext.user.uid,
      projectID: appContext.user.client,
      serviceID: props.ms.componentName,
      serviceMeta: {
        msid: ms.id,
        title: ms.title,
        dashboardUrl: ms.data,
      },
    });

    return () => {
      appContext.logServiceUnmount({
        unixTimeMount: timeMount,
        unixTimeUnmount: getUnixTime(),
        uid: appContext.user.uid,
      });
    };
  }, []);

  return (
    <div className={classes.outerDiv}>
      <iframe
          sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
          title={ms.title}
          src={ms.data}
          frameborder="0"
          width={paperWidth}
          height={paperHeight}
        />
    </div>
  );
};

PowerBIContainer.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  ms: PropTypes.object.isRequired,
};

export default withStyles(styles)(PowerBIContainer);
