import React, { Component } from 'react';
import 'react-vis/dist/style.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Routes from './Routes';
import Presentations from '../presentations/Presentations';
import Embedded from './components/Embedded';
import Maintenance from './components/Maintenance';
import Demo from './components/Demo';
import TableauContainer from '../tableau_container/TableauContainer';
import PowerBiContainer from '../powerbi_container/PowerBIContainer';
import Loading from './components/Loading';
import LinkCardSelector from './components/LinkCardSelector';
import BudgetingTool from '../budgeting_tool/BudgetingTool';
import DownloadData from '../download_data/DownloadData';
import DataQualityReport from '../data_quality_report/DataQualityReport';
import UploadObject from '../upload_object/UploadObject';
import IngestObject from '../ingest_object/IngestObject';
import FormBuilder from '../form_builder/FormBuilder';
import { ProjectTracker } from '../project_tracker/ProjectTracker';
import FormSubmissionViewer from '../form_submission_viewer/FormSubmissionViewer';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-functions';

const config = {
  apiKey: 'AIzaSyCgihRPF53kDSB7RQNsf7ptz43ZLyl59RI',
  authDomain: 'tkg-tkm-echo-old.firebaseapp.com',
  projectId: 'tkg-tkm-echo-old',
  storageBucket: 'tkg-tkm-echo-old.appspot.com',
  messagingSenderId: '744116932386',
  appId: '1:744116932386:web:c9dfc05889322ce843df7b',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

const topPanelSize = 85;
const openSideNavSize = 300;
const collapsedSideNavSize = 85;
const listItemWidthSize = 250;
const listItemHeightSize = 60;
const expandButtonSize = openSideNavSize - listItemWidthSize;
const itemSeparationSize = 16;
const itemHeadingMarginSize = 5;

const primaryColour = {
  main: '#0982b9',
  light: '#59b1ec',
  dark: '#005689',
};

const lightSecondaryColour = {
  main: '#efe3e3',
  light: '#efefef',
  dark: '#a79e9e',
};

const darkSecondaryColour = {
  main: '#232323',
  light: '#2f2f2f',
  dark: '#0e0e0e',
};

const darkCustomColours = {
  sideNavText: '#a0a3a5',
  demoIcon: '#9dcbea',
};

const lightCustomColours = {
  sideNavText: '#000',
  demoIcon: '#9dcbea',
};

const darkTheme = {
  label: 'dark',
  overrides: {
    MuiDrawer: {
      paper: {
        background: darkSecondaryColour.main,
      },
    },
    MuiButtonBase: {
      root: {
        color: primaryColour.main,
      },
    },
    MuiIcon: {
      colorPrimary: {
        color: primaryColour.main,
      },
    },
    MuiModal: {
      root: {
        zIndex: 1,
      },
    },
  },
  typography: {
    fontFamily: '"Poppins",sans-serif',
  },
  palette: {
    primary: primaryColour,
    secondary: darkSecondaryColour,
    disabled: '#808080',
  },
  customColors: darkCustomColours,
  sizes: {
    topPanel: topPanelSize,
    openSideNav: openSideNavSize,
    collapsedSideNav: collapsedSideNavSize,
    listItemWidth: listItemWidthSize,
    listItemHeight: listItemHeightSize,
    expandButton: expandButtonSize,
    itemSeparation: itemSeparationSize,
    itemHeadingMargin: itemHeadingMarginSize,
  },
};

const lightTheme = {
  label: 'light',
  overrides: {
    MuiButtonBase: {
      root: {
        color: primaryColour.main,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          background: lightSecondaryColour.dark,
        },
      },
      selected: {
        backgroundColor: lightSecondaryColour.dark,
      },
    },
    MuiIcon: {
      colorPrimary: {
        color: primaryColour.main,
      },
    },
    MuiModal: {
      root: {
        zIndex: 1,
      },
    },
  },
  typography: {
    fontFamily: '"Poppins",sans-serif',
  },
  palette: {
    primary: primaryColour,
    secondary: lightSecondaryColour,
  },
  customColors: lightCustomColours,
  sizes: {
    topPanel: topPanelSize,
    openSideNav: openSideNavSize,
    collapsedSideNav: collapsedSideNavSize,
    listItemWidth: listItemWidthSize,
    listItemHeight: listItemHeightSize,
    expandButton: expandButtonSize,
    itemSeparation: itemSeparationSize,
    itemHeadingMargin: itemHeadingMarginSize,
  },
};

export const AppContext = React.createContext();

export default class App extends Component {
  constructor(props) {
    super(props);

    this.signUserOut = () => {
      firebase.auth().signOut();
      this.setState({ user: null, drawerOpen: true, theme: 'dark' });
    };

    this.toggleDrawer = () => {
      this.setState(
        (prevState) => ({ drawerOpen: !prevState.drawerOpen }),
        () => {
          firestore
            .collection('users')
            .doc(this.state.user.uid)
            .set(
              {
                settings: { drawerOpen: this.state.drawerOpen },
              },
              {
                merge: true,
              }
            );
        }
      );
    };

    this.setTheme = (value) => {
      this.setState({ theme: value }, () => {
        firestore
          .collection('users')
          .doc(this.state.user.uid)
          .set(
            {
              settings: { theme: value },
            },
            {
              merge: true,
            }
          );
      });
    };

    this.setClient = (uid, newClient) => {
      // Set new client for user
      firestore
        .collection('users')
        .doc(uid)
        .set({ client: newClient }, { merge: true })
        .then(() => {
          // Clear current user config and refresh page to force remount and reload
          this.setState({
            user: null,
            drawerOpen: true,
            theme: 'dark',
            initialLoadComplete: false,
          });
          window.location = '/';
        });
    };

    this.logServiceMount = ({
      unixTimeMount,
      uid,
      projectID,
      serviceID,
      serviceMeta,
    }) => {
      firestore
        .collection('users')
        .doc(uid)
        .collection('usageLogs')
        .doc(unixTimeMount)
        .set({
          unixTimeMount,
          unixTimeUnmount: 0,
          projectID,
          serviceID,
          serviceMeta,
        });
    };

    this.logServiceUnmount = ({ unixTimeMount, unixTimeUnmount, uid }) => {
      firestore
        .collection('users')
        .doc(uid)
        .collection('usageLogs')
        .doc(unixTimeMount)
        .update({
          unixTimeUnmount,
        });
    };

    this.checkClient = () => {
      const { user } = this.state;
      firestore
        .collection('users')
        .doc(user.uid)
        .get()
        .then((userDoc) => {
          const client = userDoc.get('client');
          if (user.client !== client) {
            this.setState({
              user: null,
              drawerOpen: true,
              theme: 'dark',
              initialLoadComplete: false,
            });
            window.location = '/';
          }
        })
        .catch((error) => {
          console.log('Error getting document when checking client:', error);
        });
    };

    this.state = {
      logUserOut: this.signUserOut,
      toggleDrawer: this.toggleDrawer,
      setTheme: this.setTheme,
      setClient: this.setClient,
      logServiceMount: this.logServiceMount,
      logServiceUnmount: this.logServiceUnmount,
      checkClient: this.checkClient,
      initialLoadComplete: false,
      loading: false,
      loadingText: '',
      error: '',
      errorText: '',
      drawerOpen: true,
      theme: 'dark',
      user: null,
      url: 'https://tableau.strategicinsights.co.za',
      tableauApiUrl: 'https://api.echo.strategicinsights.co.za:8080',
      apiUrl: 'https://europe-west1-tkg-tkm-echo-old.cloudfunctions.net',
      earliestSelectionDate: '2016-01-01',
      adminClaims: [],
      dimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
        mainAreaHeight: window.innerHeight - topPanelSize,
        mainAreaWidth: window.innerWidth - openSideNavSize,
        topPanel: topPanelSize,
        openSideNav: openSideNavSize,
        collapsedSideNav: collapsedSideNavSize,
        listItemWidth: listItemWidthSize,
        listItemHeight: listItemHeightSize,
        expandButton: expandButtonSize,
        itemSeparation: itemSeparationSize,
        itemHeadingMargin: itemHeadingMarginSize,
      },
      microservices: [],
      firestore,
    };
  }

  handleResize = () => {
    let resizeTimer = undefined;
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      this.setState({
        dimensions: {
          ...this.state.dimensions,
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    }, 300);
  };

  getComponent = (name, disabled) => {
    if (name === 'TableauContainer') {
      return TableauContainer;
    } else if (name === 'PowerBiContainer') {
      return PowerBiContainer;
    } else if (name === 'LinkCardSelector') {
      return LinkCardSelector;
    } else if (name === 'BudgetingTool' && !disabled) {
      return BudgetingTool;
    } else if (name === 'DownloadData' && !disabled) {
      return DownloadData;
    } else if (name === 'DataQualityReport' && !disabled) {
      return DataQualityReport;
    } else if (name === 'Presentations' && !disabled) {
      return Presentations;
    } else if (name === 'Embedded' && !disabled) {
      return Embedded;
    } else if (name === 'UploadObject' && !disabled) {
      return UploadObject;
    } else if (name === 'IngestObject' && !disabled) {
      return IngestObject;
    } else if (name === 'ProjectTracker' && !disabled) {
      return ProjectTracker;
    } else if (name === 'FormSubmissionViewer' && !disabled) {
      return FormSubmissionViewer;
    } else if (name === 'FormBuilder' && !disabled) {
      return FormBuilder;
    } else {
      return Demo;
    }
  };

  getChildren = (publicChildren, clientChildren) => {
    if (clientChildren.length > 0) {
      return clientChildren.map((clientChild) => {
        const publicChild = publicChildren.find(
          (elem) => elem.id === clientChild.id
        );
        return {
          ...publicChild,
          ...clientChild,
          component: this.getComponent(
            publicChild.componentName,
            clientChild.disabled
          ),
          data: clientChild.data === '' ? publicChild.data : clientChild.data,
          disabled: false,
        };
      });
    } else {
      return publicChildren;
    }
  };

  getPublicComponents = (microservices, clientMicroservices) => {
    // console.log('microservices');
    // console.log(microservices);
    // console.log('clientMicroservices');
    // console.log(clientMicroservices);
    if (!clientMicroservices) {
      return [];
    }
    return clientMicroservices
      .map((clientMS) => {
        const publicMS = microservices.find(
          (publicMS) => publicMS.id === clientMS.id
        );
        if (!publicMS.disabled) {
          const component = this.getComponent(
            publicMS.componentName,
            clientMS.disabled
          );
          return {
            ...publicMS,
            ...clientMS,
            component,
            componentName: component === Demo ? 'Demo' : publicMS.componentName,
            children: this.getChildren(publicMS.children, clientMS.children),
            data: clientMS.data === '' ? publicMS.data : clientMS.data,
            disabled: false,
          };
        } else {
          return {
            ...publicMS,
            ...clientMS,
            component: Maintenance,
            children: this.getChildren(publicMS.children, clientMS.children),
            data: '',
            disabled: true,
          };
        }
      })
      .filter((elem) => elem !== null);
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    // Generic function to handle errors from Firebase
    const handleError = (err) => {
      console.log('Encountered an error during login:');
      console.error(err);
      this.setState({
        errorText: err,
        error: 403,
        loading: false,
        initialLoadComplete: true,
      });
    };
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Auth was successful, update state with new loading status
        this.setState({
          loading: true,
          loadingText: 'Loading Your Settings',
          initialLoadComplete: true,
        });
        //Get admin claims and  it
        firestore
          .collection('settings')
          .doc('adminMicroservices')
          .get()
          .then((adminMicroservicesSnapshot) => {
            const adminClaims = adminMicroservicesSnapshot.get('adminClaims');
            // Get userDoc containing settings from firebase to ensure user can only access microservices they have been assigned
            firestore
              .collection('users')
              .doc(user.uid)
              .get()
              .then((userSnapshot) => {
                // User settings obtained, update state with new loading status
                this.setState({
                  loading: true,
                  loadingText: "Loading Your Organisation's Data",
                });
                const userDoc = userSnapshot.data();
                // Get clientMicroservices from client's collection in firebase
                firestore
                  .collection('clients')
                  .doc(userDoc.client)
                  .get()
                  .then((clientSnapshot) => {
                    const clientMicroservices =
                      clientSnapshot.get('microservices');
                    // clientMicroservices obtained, update state with new loading status
                    this.setState({
                      loading: true,
                      loadingText: 'Loading Your Microservices',
                    });
                    // Load and reorder app microservices
                    firestore
                      .collection('settings')
                      .doc('publicMicroservices')
                      .get()
                      .then((publicMicroservicesSnapshot) => {
                        const publicMicroservices =
                          publicMicroservicesSnapshot.get('microservices');
                        const reIndexedMicroservices = this.getPublicComponents(
                          publicMicroservices,
                          clientMicroservices
                        );
                        // console.log('reIndexedMicroservices');
                        // console.log(reIndexedMicroservices);
                        this.setState({
                          user: { ...user, ...userDoc },
                          drawerOpen: userDoc.settings.drawerOpen,
                          dimensions: {
                            ...this.state.dimensions,
                            mainAreaWidth: userDoc.settings.drawerOpen
                              ? window.innerWidth - openSideNavSize
                              : window.innerWidth - collapsedSideNavSize,
                          },
                          theme: userDoc.settings.theme,
                          microservices: reIndexedMicroservices,
                          loading: false,
                          loadingText: '',
                          initialLoadComplete: true,
                          adminClaims,
                        });
                      })
                      .catch(handleError);
                  })
                  .catch(handleError);
              })
              .catch(handleError);
          })
          .catch(handleError);
      } else {
        // User is false, client has logged out or was logged out
        this.setState({
          user: null,
          drawerOpen: true,
          theme: 'dark',
          initialLoadComplete: true,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const theme = createMuiTheme(
      this.state.theme === 'dark' ? darkTheme : lightTheme
    );
    if (!this.state.initialLoadComplete) {
      return <Loading text='Checking Your Login Status' />;
    } else {
      return (
        <React.Fragment>
          <MuiThemeProvider theme={theme}>
            <AppContext.Provider value={this.state}>
              <Routes />
            </AppContext.Provider>
          </MuiThemeProvider>
        </React.Fragment>
      );
    }
  }
}
