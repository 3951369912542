import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import Loading from '../app/components/Loading';
import ErrorPage from '../app/components/ErrorPage';
import DragAndDrop from '../app/components/DragAndDrop';
import firebase from 'firebase/app';
import 'firebase/storage';

const styles = (theme) => ({
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
  },
  dropHereOuterDiv: {
    flexGrow: 1,
    display: 'flex',
    border: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(9, 130, 185, 0.4)',
  },
  uploadingOuterDiv: {
    flexGrow: 1,
    display: 'flex',
    margin: '25px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
    margin: '25px',
    alignSelf: 'flex-end',
  },
  dragger: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '50%',
    background: 'lightgrey',
  },
  dragCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
  uploadIcon: {
    alignSelf: 'center',
    color: 'white',
  },
  confirmModalPaper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmScrollbarChild: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '15px',
  },
  nameList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    width: '80%',
  },
  sizeList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    width: '20%',
    minWidth: '120px',
  },
  listHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing.unit,
  },
  confirmSubmitDiv: {
    width: '100%',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
  scrollbar: {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
  },
  uploadButton: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
    marginLeft: '25px',
  },
  cancelButton: {
    height: '36px',
    background: theme.palette.primary.main,
    color: 'white',
  },
  uploadDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '20px',
  },
  progressDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  progressBar: {
    flexGrow: 1,
  },
  text: {
    marginRight: '20px',
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalIcon:{
    fontSize: '60px',
  },
});

class IngestObject extends React.Component {
  state = {
    user: this.props.appContext.user,
    error: false,
    errorCode: null,
    loading: false,
    bucketName: this.props.ms.data,
    tableName: this.props.ms.title,
    selectedFiles: [],
    dragging: false,
    modalOpen: true,
    uploading: false,
    progress: [],
    uploadingSucsess: false,
  };

  humanFileSize = (bytes, si) => {
    var thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['KB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
  };

  handleCloseModal = () => {
    this.setState({modalOpen: false});
  };

  handleUploadingSucsessClose = () => {
    this.setState({uploadingSucsess: false});
  };

  handleDrag = (dragState) => {
    this.setState({dragging: dragState});
  };

  handleChangeBucketName = event => {
    this.setState({ bucketName: event.target.value });
  };

  handleCancelUpload = () => {
    this.setState({selectedFiles: []});
  };

  handleAddFiles = files => {
    const {selectedFiles} = this.state;
    const newList = selectedFiles.concat(...files);
    this.setState({selectedFiles: newList, modalOpen: true});
  };

  handleUploadFiles = () => {
    const {bucketName, tableName, selectedFiles} = this.state;
    const storage = firebase.app().storage('gs://' + bucketName + '/' + tableName);
    const metadata = selectedFiles.map(file => ({
      contentType: file.type,
      customMetadata: {
        'uploadedBy': this.props.appContext.user.email,
        'InsightsCloudPlatformVersion': '0.0.0'
    }}));
    const storageRef = storage.ref();
    const progress = selectedFiles.map((file) => {return {percentage: 0, totalBytes: 0, uploadedBytes: 0}});
    this.setState({modalOpen: false, uploading: true, progress});
    selectedFiles.forEach((file, index) => {
      // console.log(`Uploading file ${index} of ${selectedFiles.length}`);
      const uploadTask = storageRef.child(file.name).put(file, metadata[index]);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const {progress} = this.state;
        // console.log('current progress: ');
        // console.log(progress);
        const progressPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const newProgress = progress.splice(0);
        newProgress[index].percentage = progressPercentage;
        newProgress[index].totalBytes = snapshot.totalBytes;
        newProgress[index].uploadedBytes = snapshot.bytesTransferred;
        if (progressPercentage === 100) {
          const checkAllUploads = newProgress.reduce((acc, elem) => {return acc && elem.percentage === 100}, true);
          if (checkAllUploads) {
            this.setState({progress: newProgress, uploading: false, uploadingSucsess: true, selectedFiles: []});
          } else {
            this.setState({progress: newProgress});
          }
        } else {
          this.setState({progress: newProgress});
        }
        // switch (snapshot.state) {
        //   case firebase.storage.TaskState.PAUSED: // or 'paused'
        //     // console.log('Upload is paused');
        //     break;
        //   case firebase.storage.TaskState.RUNNING: // or 'running'
        //     // console.log('Upload is running');
        //     break;
        // }
      }, (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // switch (error.code) {
        //   case 'storage/unauthorized':
        //     // User doesn't have permission to access the object
        //     break;
        //   case 'storage/canceled':
        //     // User canceled the upload
        //     break;
        //   case 'storage/unknown':
        //     // Unknown error occurred, inspect error.serverResponse
        //     break;
        // }
      }, () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
        });
      });
    });
  };

  render() {
    const {classes, appContext} = this.props;
    const {bucketName, error, errorCode, loading, selectedFiles, dragging, modalOpen, progress, uploading, uploadingSucsess} = this.state;
    const {dimensions} = appContext;
    const mainHeight = dimensions.height - dimensions.topPanel;
    const sidePanelWidth = appContext.drawerOpen ? dimensions.openSideNav : dimensions.collapsedSideNav;
    const mainWidth = dimensions.width - sidePanelWidth;
    const circleBasis = Math.min(mainWidth, mainHeight);
    const circleSize = (circleBasis / 100) * 47;
    const totalSize = this.humanFileSize(selectedFiles.reduce((acc, file) => {return acc + file.size}, 0), true);

    const getModalStyle = () => {
      const adjustLeft = (sidePanelWidth / dimensions.width) * 50; // multiply by 50 (not 100) to get half of the adjust value
      const adjustTop = (dimensions.topPanel / dimensions.height) * 50;
      const top = 50 + adjustTop;
      const left = 48 + adjustLeft;
      return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
      };
    };

    if (!loading && !uploading) {
      return (
        <div className={dragging ? classes.dropHereOuterDiv : classes.outerDiv}>
          <DragAndDrop handleDrop={this.handleAddFiles} handleDrag={this.handleDrag}>
            <div className={classes.dragger}>
              <div className={dragging ? classes.dragCircle : classes.circle} style={{width: circleSize, height: circleSize}}>
                <Icon style={{fontSize: 180}} classes={{root: classes.uploadIcon}}>note_add</Icon>
                <Typography>Drop Files Here</Typography>
                <Typography>Or use the upload button</Typography>
              </div>
            </div>
          </DragAndDrop>
          <input
            style={{display:'none'}}
            type='file'
            multiple
            onChange={(event) => this.handleAddFiles(event.target.files)}
            ref={fileInput => this.fileInput = fileInput}
          />
          <Button
            onClick={() => this.fileInput.click()}
            color="primary"
            className={classes.button}
            variant='raised'
            size='small'
          >
            Upload
          </Button>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={selectedFiles.length > 0 && modalOpen}
            onClose={this.handleCloseModal}
          >
            <div style={getModalStyle()} className={classes.confirmModalPaper}>
              <Typography variant='h6'>Confirm Upload</Typography>
              <div className={classes.listHeading}>
                <div className={classes.nameList}><Typography variant='body2'>Name</Typography></div>
                <div className={classes.sizeList}><Typography variant='body2'>Size</Typography></div>
              </div>
              <Scrollbars
                autoHeight
                autoHeightMin={'70px'}
                renderThumbVertical={props => <div {...props} className={classes.scrollbar}/>}
                renderThumbHorizontal={props => <div {...props} className={classes.scrollbar}/>}
              >
                <div className={classes.confirmScrollbarChild}>
                  <div className={classes.listHeading}>
                    <List dense className={classes.nameList}>
                      {selectedFiles.map(file => {
                        return (
                          <ListItem>
                            <ListItemText primary={file.name} secondary={file.type} primaryTypographyProps={{noWrap: true}} secondaryTypographyProps={{noWrap: true}}/>
                          </ListItem>
                        );
                      })}
                    </List>
                    <List dense className={classes.sizeList}>
                      {selectedFiles.map(file => {
                        return (
                          <ListItem>
                            <ListItemText primary={this.humanFileSize(file.size, true)} secondary={`${file.size} B`}/>
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                </div>
              </Scrollbars>
              <div className={classes.listHeading}>
                <div className={classes.nameList}><Typography variant='body2'>{`Total Items: ${selectedFiles.length}`}</Typography></div>
                <div className={classes.sizeList}><Typography variant='body2'>{`Total Size: ${totalSize}`}</Typography></div>
              </div>
              <div className={classes.listHeading}>
                <Typography variant='body2'>{`Target Bucket: ${bucketName}`}</Typography>
              </div>
              <div className={classes.confirmSubmitDiv}>
                <Button
                  className={classes.cancelButton}
                  variant='raised'
                  label='reset'
                  onClick={this.handleCancelUpload}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.uploadButton}
                  variant='raised'
                  onClick={this.handleCloseModal}
                >
                  Add More
                </Button>
                <Button
                  className={classes.uploadButton}
                  variant='raised'
                  onClick={this.handleUploadFiles}
                >
                  Upload
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={uploadingSucsess}
            onClose={this.handleUploadingSucsessClose}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <Icon classes={{root: classes.modalIcon}} color='primary'>done</Icon>
              <Typography variant='subheading' className={classes.text}>Uploading completed successfully</Typography>
            </div>
          </Modal>
        </div>
      );
    } else if (!loading && uploading) {
      return (
        <div className={classes.uploadingOuterDiv}>
          {selectedFiles.map((file, index) => {
            return (
              <div className={classes.uploadDiv} id={index}>
                <Typography variant='body2'>{file.name}</Typography>
                <div className={classes.progressDiv}>
                  <LinearProgress className={classes.progressBar} variant='determinate' value={progress[index].percentage}/>
                  <Typography variant='caption'>{`${this.humanFileSize(progress[index].uploadedBytes, true)} / ${this.humanFileSize(progress[index].totalBytes, true)}`}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (error) {
      return <ErrorPage errorCode={errorCode} error={error}/>;
    } else if (loading) {
      return <Loading text='Loading IngestObject'/>;
    } else {
      return <ErrorPage error='Error'/>;
    }
  }
}

IngestObject.propTypes = {
  appContext: PropTypes.object.isRequired,
};

export default withStyles(styles)(IngestObject);
