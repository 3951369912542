import { getCurrentYMD, ColorCodes } from '../../helpers';
import { schemaProps, stringTypeDefaultValidation } from './schemaMasterData';

const activities = [
  'Gather Target data structures and requirements',
  'Conduct gap analysis between staging table and target tables',
  'Create staging environment to collate data',
  'Create version control mechanism',
  'Ensure that Version N and Version N-1 is maintained',
  'Conduct data quality assessement of data in staging tables',
  'create profiling reports & quality report for target table',
  'Create mediation strategy to resolve incorrect data before load',
  'Create final table implementing DQ rules',
  'Export to target',
  'Identifying source system & data to be migrated',
  'Data Inventory for current/existing systems in scope for L2C',
  'Data Dictionary for systems listed',
  'Create staging tables to collate data from source systems',
  'Create profiling reports & quality report',
  'Present fallouts',
  'Initial cleansing',
];

const activityDeliverableMap = {
  'Gather Target data structures and requirements': 'Target Data Structure',
  'Conduct gap analysis between staging table and target tables': 'Source to target mapping & sign-off document',
  'Create staging environment to collate data': 'EDW Staging environment',
  'Create version control mechanism': 'Version control mechanism',
  'Ensure that Version N and Version N-1 is maintained': 'Versioned table control in EDW',
  'Conduct data quality assessement of data in staging tables': 'Data Quality validation dashboard/report - based on predefined DQ rules & data profiling ',
  'create profiling reports & quality report for target table': 'Profiling report, quality report, fallouts',
  'Create mediation strategy to resolve incorrect data before load': 'Mediation strategy (cleansing process)',
  'Create final table implementing DQ rules': 'Final table',
  'Export to target': 'Data placed in SFG & sign-off document of receipt',
  'Identifying source system & data to be migrated': 'Signed-off Document',
  'Data Inventory for current/existing systems in scope for L2C': 'Data Inventory',
  'Data Dictionary for systems listed': 'Data Dictionary',
  'Create staging tables to collate data from source systems': 'Creation of staging tables',
  'Create profiling reports & quality report': 'Profiling report, quality report, fallouts',
  'Present fallouts': 'Sign-off document',
  'Initial cleansing': 'Cleaning process',
};

const activityCategoryMap = {
  'Gather Target data structures and requirements': 'Gather',
  'Identifying source system & data to be migrated': 'System Identification',
  'Conduct gap analysis between staging table and target tables': 'Gather',
  'Create staging environment to collate data': 'Creation',
  'Create version control mechanism': 'Creation',
  'Ensure that Version N and Version N-1 is maintained': 'Creation',
  'Conduct data quality assessement of data in staging tables': 'DQ & Profiling',
  'create profiling reports & quality report for target table': 'DQ & Profiling',
  'Create mediation strategy to resolve incorrect data before load': 'Cleaning',
  'Create final table implementing DQ rules': 'Final table & export',
  'Export to target': 'Final table & export',
  'Identifying source system & data to be migrated': 'System Identification',
  'Data Inventory for current/existing systems in scope for L2C': 'Data Source Identification',
  'Data Dictionary for systems listed': 'Data Source Identification',
  'Create staging tables to collate data from source systems': 'Staging Table Creation',
  'Create profiling reports & quality report': 'DQ & Profiling',
  'Present fallouts': 'DQ & Profiling',
  'Initial cleansing': 'Data Cleansing',
};

export const l2cDataMigrationTaskSchemaDemo = {
  name: 'L2C Data Migration Tasks',
  icon: 'playlist_add',
  client: 'demoClient',
  collectionPath: 'l2cDataMigrationTasks',
  subCollection: '',
  isSubCollection: false,
  disableDelete: false,
  rowsPerPageDefault: 10,
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  orderByDefault: 'name',
  // requiresProjects: false,
  // hasProjectsDropdown: true,
  // hasElementItems: false,
  // hasControlField: false,
  // controlField: '',
  schema: {
    activityNumber: {
      label: 'Activity Number',
      name: 'activityNumber',
      ...schemaProps,
      defaultValue: '#B0',
      // xs: 4,
      validation: {
        required: {
          value: true,
          message: 'Please enter the Activity Number',
        },
        regexCheck: {
          pattern: '#B\\d\\d\\d',
          message: 'Please ensure Activity Number matches the regex pattern "#B000"',
        },
      },
    },
    system: {
      label: 'System',
      name: 'system',
      ...schemaProps,
      type: 'dropdownText',
      fsOptions: {
        collectionPath: 'clients/demoClient/l2cDataMigrationTasks',
        queryConditions: [],
        fieldUsedAsOptionLabel: 'system'
      },
      // xs: 4,
      validation: {},
    },
    userStory: {
      label: 'User Story',
      name: 'userStory',
      ...schemaProps,
      // xs: 4,
      type: 'dropdownText',
      fsOptions: {
        collectionPath: 'clients/demoClient/l2cDataMigrationTasks',
        queryConditions: [],
        fieldUsedAsOptionLabel: 'userStory'
      },
      validation: {
        required: {
          value: true,
          message: 'Please enter or select a User Story',
        },
        regexCheck: {
          pattern: '[A-Z]{3}-[0-9]{4}[a-z]?',
          message: 'Please ensure User Story matches the regex pattern "[A-Z]{3}-[0-9]{4}[a-z]{1}"',
        },
      },
    },
    userStoryDesc: {
      label: 'User Story Description',
      name: 'userStoryDesc',
      ...schemaProps,
      // xs: 4,
      type: 'dropdownText',
      controlField: 'userStory',
      controlType: 'autoChange',
      controlReturnedToUserValue: 'unknownData',
      fsData: {
        collectionPath: 'clients/demoClient/l2cDataMigrationTasks',
        queryConditions: [],
        postProcessObject: ([docId, docData]) => ({ [docData.userStory]: docData.userStoryDesc }),
      },
      variant: 'standard',
      defaultValue: '',
      defaultLabel: 'Please enter a User Story Description',
      validation: {},
    },
    mainActivityGrouping: {
      label: 'Main Activity Grouping',
      name: 'mainActivityGrouping',
      ...schemaProps,
      // xs: 4,
      type: 'dropdown',
      options: activities,
      validation: {
        required: {
          value: true,
          message: 'Please select a Main Activity Grouping',
        },
        allowedValues: {
          value: activities,
          message: 'Please select from the list provided',
        },
      },
    },
    deliverable: {
      label: 'Deliverable',
      name: 'deliverable',
      ...schemaProps,
      // xs: 6,
      type: 'dropdown',
      controlField: 'mainActivityGrouping',
      controlType: 'autoChange',
      data: activityDeliverableMap,
      variant: 'standard',
      defaultValue: '',
      validation: {
        required: {
          value: true,
          message: 'Please select a Deliverable',
        },
        allowedValues: {
          value: Object.values(activityDeliverableMap),
          message: 'Please select a deliverable from the list',
        },
      },
    },
    activityCategory: {
      label: 'Activity Category',
      name: 'activityCategory',
      ...schemaProps,
      // xs: 2,
      type: 'dropdown',
      controlField: 'mainActivityGrouping',
      controlType: 'autoChange',
      data: activityCategoryMap,
      variant: 'standard',
      defaultValue: '',
      validation: {
        required: {
          value: true,
          message: 'Please select an activity category',
        },
        allowedValues: {
          value: Object.values(activityCategoryMap),
          message: 'Please select an activity category from the list',
        },
      },
    },
    comments: {
      label: 'Comments',
      name: 'comments',
      ...schemaProps,
      multiline: true,
      // xs: 8,
      validation: {
        maxLength: {
          value: 140,
          message: 'Please use 140 chracters or less',
        },
      },
    },
    resource: {
      label: 'Resource',
      name: 'resource',
      ...schemaProps,
      type: 'dropdownText',
      fsOptions: {
        collectionPath: 'clients/demoClient/l2cDataMigrationTasks',
        queryConditions: [],
        fieldUsedAsOptionLabel: 'resource'
      },
      // xs: 4,
      validation: {},
    },
    startDate: {
      label: 'Start Date',
      name: 'startDate',
      ...schemaProps,
      type: 'date',
      // xs: 4,
      defaultValue: '',
      chipStyle: (chipItem) => {
        return { padding: '0px', fontSize: '1em' };
      },
      InputLabelProps: {},
      InputProps: {
        inputProps: { min: '1970-01-01', max: '' },
      },
      validation: {
        required: { value: true, message: 'Please pick the start date' },
      },
    },
    estimatedEndDate: {
      label: 'Estimated End Date',
      name: 'estimatedEndDate',
      ...schemaProps,
      type: 'date',
      // xs: 4,
      defaultValue: '',
      chipStyle: (chipItem) => {
        return { padding: '0px', fontSize: '1em' };
      },
      InputLabelProps: {},
      InputProps: {
        inputProps: { min: '1970-01-01', max: '' },
      },
      validation: {
        required: { value: true, message: 'Please pick the estimated end date' },
      },
    },
    actualEndDate: {
      label: 'Actual End Date',
      name: 'actualEndDate',
      ...schemaProps,
      type: 'date',
      // xs: 4,
      defaultValue: '',
      InputLabelProps: { shrink: true },
      InputProps: {
        inputProps: {
          min: '1970-01-01',
          max: '',
        },
      },
      validation: {
        required: { value: true, message: 'Please pick the actual end date' },
      },
    },
    ragStatus: {
      label: 'RAG Status',
      name: 'ragStatus',
      ...schemaProps,
      type: 'dropdown',
      options: ['Red', 'Amber', 'Green', 'Blue'],
      // xs: 4,
      validation: {
        allowedValues: {
          value: ['Red', 'Amber', 'Green', 'Blue'],
          message: 'Please select from the list provided',
        },
      },
    },
  },
}
