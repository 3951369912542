import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loading from './Loading';
import { isNumeric, buildFixedLengthNumber } from '../../helpers';
import DropdownComponent from '../../form_builder/DropdownComponent';
import DynamicFieldComponent from '../../form_builder/DynamicFieldComponent';
import RadioGroupComponent from '../../form_builder/RadioGroupComponent';
import TextFieldComponent from '../../form_builder/TextFieldComponent';
import useConfigParser from '../hooks/useConfigParser';

// Constants
const validationRules = {
  required: (testValue, validationObject) => {
    let checkResult = true;
    if (
      validationObject.required.hasOwnProperty('value')
      && validationObject.required.value
    ) {
      checkResult = Boolean(testValue);
    }
    return checkResult;
  },
  numeric: (testValue, validationObject) => {
    let checkResult = true;
    if (
      validationObject.numeric.hasOwnProperty('value')
      && validationObject.numeric.value
    ) {
      checkResult = isNumeric(testValue);
    }
    return checkResult;
  },
  regexCheck: (testValue, validationObject) => {
    let checkResult = true;
    try {
      // console.log(`Checking test value "${testValue}" against pattern "${validationObject.regexCheck.pattern}"`);
      const regexObj = new RegExp(`${validationObject.regexCheck.pattern}`);
      checkResult = regexObj.test(testValue);
      // console.log(`Got result: ${checkResult}`);
    } catch {
      console.log(`Bad regex pattern: "${validationObject.regexCheck.pattern}"`);
      checkResult = false;
    }
    return checkResult;
  },
  minLength: (testValue, validationObject) => {
    // console.log(`in minLength with "${testValue}" | is string: ${typeof testValue === 'string'} | is valid: ${testValue.length > validationObject.minLength.value}`);
    return typeof testValue === 'string'
      && testValue.length >= validationObject.minLength.value;
  },
  maxLength: (testValue, validationObject) => {
    // console.log(`in maxLength with "${testValue}" | is string: ${typeof testValue === 'string'} | is valid: ${testValue.length < validationObject.maxLength.value}`);
    return typeof testValue === 'string'
      && testValue.length <= validationObject.maxLength.value;
  },
  minValue: (testValue, validationObject) => {
    return isNumeric(testValue)
      && Number(testValue) >= validationObject.minValue.value;
  },
  maxValue: (testValue, validationObject) => {
    return isNumeric(testValue)
      && Number(testValue) <= validationObject.maxValue.value;
  },
  allowedValues: (testValue, validationObject) => {
    return validationObject.allowedValues.hasOwnProperty('value')
      && validationObject.allowedValues.value.includes(testValue);
  },
};

const styles = (theme) => ({
  container: {
    // height: '100%',
    padding: (theme.spacing.unit * 3),
    // overflowY: 'auto',
  },
});

// Generates a new object with incremented values for auto-increment fields and default values for all other fields
const autoIncrement = (fieldsObject, submitObject) =>
Object.entries(fieldsObject).reduce((resultObject, [fieldName, fieldObject]) => {
  if (fieldObject.isAutoIncrement) {
    if (typeof fieldObject.defaultValue === 'number') {
      resultObject[fieldName] = submitObject[fieldObject.name] + 1;
    } else {
      const incrementedValue = parseInt(submitObject[fieldObject.name].slice(2)) + 1;
      resultObject[fieldName] = buildFixedLengthNumber(
        incrementedValue,
        fieldObject.numberOfDigits,
        fieldObject.defaultValue.slice(0, 2)
      );
    }
  } else {
    resultObject[fieldName] = fieldObject.defaultValue;
  }
  return resultObject;
}, {});

// FormEngine has 2 use cases:
//   1. Default: pass in raw config via `formConfig`, FormEngine processes it via `useConfigParser`
//   2. Child: pass in existing, processed fields via `existingFields`
// Both use cases also accommodate for existing submissions/data flowing from parent component
//   - Existing data must be passed via `existingSubmission` along with `existingSubmissionId`
//   - If state updates in the parent component are needed, pass the update function via `setExistingSubmission`
//   - To prevent cyclic updates, FormEngine only updates its data from `existingSubmission` when `existingSubmissionId` changes
const FormEngine = (props) => {
  const {
    appContext,
    resetEnabled,
    submissionEnabled,
    formConfig,
    existingFields,
    existingErrors,
    existingSubmission,
    existingSubmissionId,
    existingCollectionRef,
    setExistingSubmission,
    formTitleTypographyVariant,
    classes,
    level,
  } = props;
  // console.log(`FormEngine props on level: ${level}`);
  // console.log(props);
  const { firestore } = appContext;
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [formTitle, setFormTitle] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');
  const [submission, setSubmission] = useState({});
  const [submissionId, setSubmissionId] = useState('');
  const [submissionIsValid, setSubmissionIsValid] = useState(true);
  const [submissionDefault, setSubmissionDefault] = useState({});
  const [fieldsObject, setFieldsObject] = useState({});
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isChild, setIsChild] = useState(false);

  // Mount Process
  // 1. Call `useConfigParser` to create `fieldsObjectInitial`, `submissionInitial` and `configParserLoading`.
  // 2. Default use case, where a raw form config is passed as a prop to be processed into fieldsObject internally
  // 2.1 Set `formTitle` and `buttonLabel`

  // 2.2 If existing data is not passed
  // 2.2.1 Set `submission` passing the value from `submissionInitial`
  // 2.2.2 Set `isChild`, `formTitle` according to the name from `formConfig`
  // 2.2.3 Set `fieldsObject` passing the value from `fieldsObjectInitial`
  // 2.2.4 Set `loading` to `false` if its currently `true`

  // 2.3 If existing data is passed
  // 2.3.1 Set `fieldsObject` passing the value from `fieldsObjectInitial`
  // 2.3.2 Set `loading` to `false` if its currently `true`

  // ------------------------------

  // 3. Child use case, where existing processed fields are passed in via the `existingFields` prop
  // 3.1 Set `fieldsObject`
  // 3.2 Set loading to `false` if its currently `true`
  // 3.3 Set `isChild` to `true`
  // 3.4 Set `formTitle` and `buttonLabel` to `''`

  // 3.5 If existing data is not passed
  // 3.5.1 Set `submission` passing the value from `submissionInitial`
  // 3.5.2 Set `formTitle` and `buttonLabel` to accommodate child use case
  // 3.5.3

  // 3.6 If existing data is passed
  // 3.6.1
  // 3.6 

  // ------------------------------

  // Update process after internal change
  // 1

  // ------------------------------

  // Update process after external change
  // 1

  // console.log(`Step 1`);
  const {
    fieldsObject: fieldsObjectInitial,
    submitObjectConfig: submissionInitial,
    configParserLoading,
  } = useConfigParser(firestore, formConfig, existingFields);

  // All use cases. Check if parent component requires updates, if it does, use external update function (expect updates from props)
  let setSubmissionActual = setExistingSubmission === false ?
    (newSubmission) => setSubmission(newSubmission) :
    (newSubmission) => setExistingSubmission(newSubmission);

  // Default use case. Raw form config is passed as a prop to be processed into fieldsObject internally
  useEffect(() => {
    if (formConfig && Object.keys(formConfig).length > 0) {
      // console.log(`Step 2.1`);
      setFormTitle(`Add ${formConfig.name}`);
      setButtonLabel(`Submit ${formConfig.name}`);
    }
  }, [formConfig]);

  // Child use case. Config has already been processed into fieldsObject and passed as prop
  useEffect(() => {
    if (existingFields && Object.keys(existingFields).length > 0) {
      // console.log(`Step 3.1`);
      setFieldsObject(existingFields);
      if (loading) {
        // console.log(`Step 3.2`);
        setLoading(false);
      }
    }
  }, [existingFields]);

  // Child use case, function to update state in the parent component received as a prop
  useEffect(() => {
    // console.log(`Got setExistingSubmission with "${existingSubmissionId}"`);
    if (setExistingSubmission !== false) {
      // console.log(`Step 3.3`);
      setIsChild(true);
      // console.log(`Step 3.4`);
      setFormTitle('');
      setButtonLabel('');
    }
  }, [setExistingSubmission]);

  // All use cases, handle `submissionInitial` and `fieldsObjectInitial` returned by the `useConfigParser` custom hook
  useEffect(() => {
    if (!configParserLoading) {
      // console.log('useEffect triggered with submissionInitial');
      // console.log(submissionInitial);
      // console.log('With formConfig');
      // console.log(formConfig);
      // Only when data is not being passed, do we set `submission` and reset the `formTitle` and `buttonLabel`
      if (!existingSubmission || (existingSubmission && Object.keys(existingSubmission).length === 0)) {
        // console.log(`Step 2.2.1/3.5.1`);
        setSubmission(submissionInitial);
        setSubmissionDefault(submissionInitial);
        if (formConfig && Object.keys(formConfig).length > 0) {
          // console.log(`Step 2.2.2`);
          setFormTitle(`Add ${formConfig.name}`);
          setButtonLabel(`Submit ${formConfig.name}`);
        } else {
          // console.log(`Step 3.5.2`);
          !isChild && setFormTitle(`Add`);
          setButtonLabel(`Submit`);
        }
      }
      if (fieldsObjectInitial && Object.keys(fieldsObjectInitial).length > 0) {
        // console.log(`Step 2.2.3/2.3.1`);
        setFieldsObject(fieldsObjectInitial);
        if (loading) {
          // console.log(`Step 2.2.4/2.3.2`);
          setLoading(false);
        }
      }
    }
  }, [configParserLoading]);

  // All use cases with existing data. Data is used as default values for fields, only refreshes when submission ID changes
  // useEffect(() => {
  //   if ((submissionId !== existingSubmissionId) || (
  //     submissionId === ''
  //     && existingSubmissionId == ''
  //     && existingSubmission
  //     && Object.keys(existingSubmission).length > 0
  //   )) {
  //     setSubmissionId(existingSubmissionId);
  //     setSubmissionDefault(existingSubmission);
  //     setSubmission(existingSubmission);
  //     if (formConfig && Object.keys(formConfig).length > 0) {
  //       setFormTitle(`Update ${formConfig.name}`);
  //       setButtonLabel(`Update ${formConfig.name}`);
  //     }
  //   }
  // }, [existingSubmission, existingSubmissionId]);

  useEffect(() => {
    // console.log(`handling existingSubmission with`);
    // console.log(existingSubmission);
    // console.log(`existingSubmissionId: "${existingSubmissionId}"`);
    // console.log(`submissionId: "${submissionId}"`);
    if (submissionId !== existingSubmissionId) {
      // console.log(`submission changed!`);
      setSubmissionId(existingSubmissionId);
      if (existingSubmission && Object.keys(existingSubmission).length > 0) {
        setSubmissionDefault(existingSubmission);
        setSubmission(existingSubmission);
      } else {
        setSubmissionDefault(submissionInitial);
        setSubmission(submissionInitial);
      }
    } else {
      setSubmission(existingSubmission);
    }
    if (Object.keys(formConfig).length === 0) {
      setFormTitle(`Update`);
      setButtonLabel(`Update`);
    }
  }, [existingSubmission, existingSubmissionId]);

  useEffect(() => {
    if (Object.keys(existingErrors).length > 0) {
      const errorsTemp = JSON.parse(JSON.stringify(existingErrors));
      setErrors(errorsTemp);
    }
  }, [existingErrors])

  useEffect(() => {
    const didUpdate = {
      submission: false,
      fieldsObject: false,
      errors: false
    };
    const errorsTemp = JSON.parse(JSON.stringify(errors));
    const submissionTemp = JSON.parse(JSON.stringify(submission));
    const fieldsObjectTemp = JSON.parse(JSON.stringify(fieldsObject));

    Object.entries(fieldsObjectTemp).forEach((fieldObjectEntry) => {
      const searchForControlField = ([fieldName, fieldObject], fieldsObject, submissionTemp, errorsTemp, didUpdate) => {
        if (fieldObject.hasOwnProperty('controlField') && fieldObject.hasOwnProperty('data')) {
          //TODO: think of allowing control fields on different levels. Only same level supported currently
          let optionsNew = [];
          const fieldValue = submissionTemp[fieldName];
          const fieldValueIsDefault = submissionTemp[fieldName] === fieldObject.defaultValue;
          const controlFieldValue = submissionTemp[fieldObject.controlField];
          const controlFieldAllowedValues = Object.keys(fieldObject.data);
          const controlFieldValueIsValid = controlFieldAllowedValues.includes(controlFieldValue);
          const fieldCurrentlyInSubmission = submissionTemp.hasOwnProperty(fieldName);
          const controlFieldDefaultValue = fieldsObject[fieldObject.controlField].defaultValue;
          const controlFieldValueIsDefault = controlFieldValue === controlFieldDefaultValue;
          const controlFieldHasChanged = controlFieldValue !== controlFieldDefaultValue;
          // console.log(`Found controlled field: "${fieldName}" | controlFieldValueIsValid: ${controlFieldValueIsValid}`);

          if (fieldObject.controlType === 'autoChange') {
            if (controlFieldValueIsValid && fieldCurrentlyInSubmission) {
              // console.log(`Processing "autoChange" for field "${fieldName}"`);
              // console.log('fieldObject.data');
              // console.log(fieldObject.data);
              const valueAccordingToControlField = fieldObject.data[controlFieldValue];
              // console.log('valueAccordingToControlField');
              // console.log(valueAccordingToControlField);
              optionsNew = [valueAccordingToControlField];
              if (fieldValue !== valueAccordingToControlField) {
                // console.log(`Adding field ${fieldName} to submission`);
                submissionTemp[fieldName] = valueAccordingToControlField;
                didUpdate.submission = true;
                delete errorsTemp[fieldName];
                didUpdate.errors = true;
              }
              if (fieldObject.hasOwnProperty('controlReturnedToUser')) {
                delete fieldObject.controlReturnedToUser;
                didUpdate.fieldsObject = true;
              }
            } else if (!controlFieldValueIsValid && fieldCurrentlyInSubmission && fieldObject.hasOwnProperty('controlReturnedToUserValue')) {
              const fieldValueIsAutoSelected = Object.values(fieldObject.data).includes(submissionTemp[fieldName]);
              const userHasControl = fieldObject.hasOwnProperty('controlReturnedToUser') && fieldObject.controlReturnedToUser;
              const returnedToUserValueFound = controlFieldValue === fieldObject.controlReturnedToUserValue;
              const returnedToUserUnknownDataFound = fieldObject.controlReturnedToUserValue === 'unknownData' && !Object.keys(fieldObject.data).includes(controlFieldValue);
              if (!controlFieldValueIsValid && !controlFieldValueIsDefault && fieldValueIsAutoSelected && !fieldValueIsDefault) {
                // Reset field when controlField parent becomes invalid
                submissionTemp[fieldName] = fieldObject.defaultValue;
                didUpdate.submission = true;
              }
              if ((returnedToUserValueFound || returnedToUserUnknownDataFound) && !userHasControl) {
                // console.log(`Adding ${fieldName} to user control`);
                fieldObject.controlReturnedToUser = true;
                didUpdate.fieldsObject = true;
              } else if (!(returnedToUserValueFound || returnedToUserUnknownDataFound) && userHasControl) {
                // console.log(`Removing ${fieldName} from user control`);
                delete fieldObject.controlReturnedToUser;
                didUpdate.fieldsObject = true;
              }
            }
          } else if (fieldObject.controlType === 'autoOption') {
            optionsNew = fieldObject.data[controlFieldValue];
            if (optionsNew && ![...optionsNew, fieldObject.defaultValue].includes(fieldValue)) {
              // console.log(`Adding field ${fieldName} to submission`);
              submissionTemp[fieldName] = fieldObject.defaultValue;
              didUpdate.submission = true;
              delete errorsTemp[fieldName];
              didUpdate.errors = true;
            }
          }
          if (controlFieldValueIsValid && (['autoChange', 'autoOption'].includes(fieldObject.controlType))) {
            // console.log(`setting optionsNew for field "${fieldName}"`);
            // console.log(optionsNew);
            fieldsObject[fieldName].options = optionsNew;
            didUpdate.fieldsObject = true;
          }

          if (fieldObject.type === 'dynamicField') {
            submissionTemp[fieldName].forEach((submissionDynamicSchemaElem, index) => {
              Object.entries(fieldObject.dynamicSchema).forEach((fieldObjectEntry) => {
                searchForControlField(fieldObjectEntry, fieldObject.dynamicSchema, submissionDynamicSchemaElem, errorsTemp[fieldName][index], didUpdate);
              });
            });
          }
        }
      };

      const searchForHiddenFields = ([fieldName, fieldObject], fieldsObject, submissionTemp, errorsTemp, didUpdate) => {
        if (fieldObject.isHidden) {
          let shouldBeHidden = fieldObject.isHidden === true;
          const controlFieldValue = submissionTemp[fieldObject.controlField];
          const isCurrentlyHidden = !submissionTemp.hasOwnProperty(fieldName);
          console.log(`Found hidden field: "${fieldName}" | controlFieldValue: ${controlFieldValue}`);

          if (!shouldBeHidden && fieldObject.isHidden === 'controlBased') {
            const allowedValuesForVisibility = fieldObject.hasOwnProperty('data') ? Object.keys(fieldObject.data) : [];
            if (fieldObject.controlType === 'visibility' && allowedValuesForVisibility.length > 0) {
              const controlFieldValueIsAllowed = allowedValuesForVisibility.includes(controlFieldValue);
              shouldBeHidden = !controlFieldValueIsAllowed;
            } else {
              const controlFieldDefaultValue = fieldsObject[fieldObject.controlField].defaultValue;
              const controlFieldHasChanged = ![undefined, null, controlFieldDefaultValue].includes(controlFieldValue);
              shouldBeHidden = !controlFieldHasChanged;
            }
          }

          // console.log(`Post checks | isCurrentlyHidden: ${isCurrentlyHidden} | shouldBeHidden: ${shouldBeHidden}`);
          if (isCurrentlyHidden && !shouldBeHidden) {
            // console.log(`Adding field ${fieldName} to submission`);
            submissionTemp[fieldName] = fieldObject.defaultValue;
            didUpdate.submission = true;
          } else if (!isCurrentlyHidden && shouldBeHidden) {
            delete submissionTemp[fieldName];
            delete errorsTemp[fieldName];
            didUpdate.submission = true;
            didUpdate.errors = true;
          }

          if (fieldObject.type === 'dynamicField') {
            submissionTemp[fieldName].forEach((submissionDynamicSchemaElem, index) => {
              Object.entries(fieldObject.dynamicSchema).forEach((fieldObjectEntry) => {
                searchForHiddenFields(fieldObjectEntry, fieldObject.dynamicSchema, submissionDynamicSchemaElem, errorsTemp[fieldName][index], didUpdate);
              });
            });
          }
        }
        return didUpdate;
      };

      searchForControlField(fieldObjectEntry, fieldsObjectTemp, submissionTemp, errorsTemp, didUpdate);
      searchForHiddenFields(fieldObjectEntry, fieldsObjectTemp, submissionTemp, errorsTemp, didUpdate);
      // console.log(`done post update submission processing for field "${fieldObjectEntry[0]}", didUpdate: ${didUpdate}`);
      // if (didUpdate) {
      //   console.log(submission);
      //   console.log(submissionTemp);
      // }
    });


    if (didUpdate.submission) {
      console.log('Setting submissionTemp');
      console.log(submissionTemp);
      setSubmissionActual(submissionTemp);
    }
    if (didUpdate.fieldsObject) {
      // console.log('Setting fieldsObjectTemp');
      // console.log(fieldsObjectTemp);
      setFieldsObject(fieldsObjectTemp);
    }
    if (didUpdate.errors) {
      // console.log('Setting errorsTemp');
      setErrors(errorsTemp);
    }
  }, [submission]);

  const handleValidation = (submission, fieldsObject, errors) => {
    const validateField = (validateAcc, [fieldName, fieldValue]) => {
      const fieldObject = fieldsObject[fieldName];
      if (fieldObject.type === 'dynamicField') {
        // TODO: Add min length check for number of elements in dynamic field
        errors[fieldName] = [];
        const dynamicSchemaIsValid = fieldValue.reduce(
          (acc, dynamicSchemaElem, index) => {
            errors[fieldName][index] = {}
            const elemValid = handleValidation(dynamicSchemaElem, fieldObject.dynamicSchema, errors[fieldName][index]);
            if (Object.keys(errors[fieldName][index]).length === 0) {
              errors[fieldName] = errors[fieldName].slice(index, index + 1);
            }
            return acc && elemValid;
          }, true
        );
        validateAcc = validateAcc && dynamicSchemaIsValid;
      } else {
        const validationObject = fieldObject.validation;
        const valueIsValid = Object.entries(validationRules).reduce((fieldAcc, [ruleName, ruleFunction]) => {
          const shouldPreformCheck = validationObject.hasOwnProperty(ruleName);
          let errorMessage = 'Error in field';
          if (shouldPreformCheck) {
            errorMessage = validationObject[ruleName].message;
          } else if (shouldPreformCheck) {
            console.log(`Invalid validation config for "${fieldObject.name}" on rule ${ruleName}. Using default error message.`);
          }
          const checkPassed = shouldPreformCheck && ruleFunction(fieldValue, validationObject);
          // console.log(`Done check for "${fieldName}" | ruleName: ${ruleName} | shouldPreformCheck: ${shouldPreformCheck} | checkPassed: ${checkPassed}`);
          // console.log(`Logic test: ${!shouldPreformCheck || (shouldPreformCheck && checkPassed)} | fieldAcc: ${fieldAcc}`);
          if (!shouldPreformCheck || (shouldPreformCheck && checkPassed)) return fieldAcc;
          errors[fieldName] = errorMessage;
          return false;
        }, true);
        validateAcc = validateAcc && valueIsValid;
      }
      return validateAcc;
    };

    return Object.entries(submission).reduce(validateField, true);
  };

  const processSubmission = (submission, fieldsObject) => {
    Object.entries(submission).forEach(([fieldName, fieldValue]) => {
      if (fieldsObject[fieldName].type === 'dynamicField') {
        submission[fieldName].forEach((dynamicSchemaElem) => {
          processSubmission(dynamicSchemaElem, fieldsObject[fieldName].dynamicSchema);
        });
      } else if (fieldsObject[fieldName].type === 'date') {
        submission[fieldName] = Date.parse(submission[fieldName]);
      } else if (fieldsObject[fieldName].hasOwnProperty('fsOptions')) {
        // console.log('submission[fieldName]');
        // console.log(submission[fieldName]);
        // submission[fieldName] = fieldsObject[fieldName].fsOptions.filter((opt) => opt.id === fieldValue);
      } else if (fieldsObject[fieldName].hasOwnProperty('fsData')) {
        // console.log('submission[fieldName]');
        // console.log(submission[fieldName]);
        // submission[fieldName] = fieldsObject[fieldName].data.filter((opt) => opt.id === fieldValue);
      }
      // TODO: Add logic that ensures the schema of all submitted objects are consistent by adding missing fields with null values
    });
  };

  const handleDataSubmission = async () => {
    setFormError(false);
    let errorsTemp = {};
    const validationPassed = handleValidation(submission, fieldsObject, errorsTemp);
    setSubmissionIsValid(validationPassed);

    if (validationPassed) {
      const submissionTemp = JSON.parse(JSON.stringify(submission));
      processSubmission(submissionTemp, fieldsObject);
      try {
        const collectionRef = existingCollectionRef ? existingCollectionRef : firestore.collection(`clients/${formConfig.client}/${formConfig.collectionPath}`);
        //TODO: pass selectedID as a prop to ensure "id" is not a normal field and that we are really updating an existing
        if (existingSubmissionId && formConfig) {
          collectionRef.doc(existingSubmissionId).set(submissionTemp);
        } else {
          collectionRef.doc().set(submissionTemp);
        }
  
        setFormSuccess(`Submitted the from successfully`);
        setSubmissionActual(autoIncrement(fieldsObject, submission));
        // setSubmissionActual(submissionDefault);
        setTimeout(() => setFormSuccess(false), 5000);
      } catch {
        setFormError(`Unable to submit the form, please check your network or the console for further details.`);
      }
    } else {
      console.log('Form has errors');
      console.log(errorsTemp);
      setErrors(errorsTemp);
    }
  };

  // console.log(`submission on level ${level}:`);
  // console.log(submission);
  // console.log('fieldsObject:');
  // console.log(fieldsObject);
  // console.log('errors:');
  // console.log(errors);
  if (loading) {
    return formConfig ?
      <Loading text={`Loading Form`} /> :
      <Loading text={`Loading ${formConfig.name} Form`} />;
  } else if (submission) {
    const renderFieldObject = (
      [fieldName, fieldObject],
      submissionRender = {},
      setSubmissionRender = () => { },
      errorsRender = {},
      setErrorsRender = () => { }
    ) => {
      const setErrorsRemoveField = (index = false) => {
        const errorsRenderTemp = JSON.parse(JSON.stringify(errorsRender));
        if (index === false) {
          delete errorsRenderTemp[fieldName];
          setErrorsRender(errorsRenderTemp);
          setSubmissionIsValid(true);
        } else if (Array.isArray(errorsRenderTemp[fieldName]) && errorsRenderTemp[fieldName].length > 0) {
          errorsRenderTemp[fieldName].splice(index, 1);
          setErrorsRender(errorsRenderTemp);
          if (Array.isArray(errorsRenderTemp[fieldName]) && errorsRenderTemp[fieldName].length === 0) {
            setSubmissionIsValid(true);
          }
        }
      };

      const setErrorsUpdateField = (fieldErrorValue, index = false) => {
        const errorsRenderTemp = JSON.parse(JSON.stringify(errorsRender));
        if (index === false) {
          errorsRenderTemp[fieldName] = fieldErrorValue;
        } else {
          errorsRenderTemp[fieldName][index] = fieldErrorValue;
        }
        setErrorsRender(errorsRenderTemp);
        setSubmissionIsValid(true);
      };

      const setSubmissionWithEvent = (event) => {
        setErrorsRemoveField();
        const submissionRenderTemp = JSON.parse(JSON.stringify(submissionRender));
        const oldValue = submissionRenderTemp[fieldName];
        submissionRenderTemp[fieldName] = event.target.value;
        if (!['text', 'date'].includes(fieldObject.type)
          && (submissionRenderTemp[fieldName] === '' || submissionRenderTemp[fieldName] === oldValue)
          && fieldObject.hasOwnProperty('isHidden') && fieldObject.isHidden
        ) {
          delete submissionRenderTemp[fieldName];
        }
        setSubmissionRender(submissionRenderTemp);
      };

      const setSubmissionWithOutEvent = (value) => {
        setErrorsRemoveField();
        const submissionRenderTemp = JSON.parse(JSON.stringify(submissionRender));
        // console.log('submissionRender');
        // console.log(submissionRender);
        const oldValue = submissionRenderTemp[fieldName];
        submissionRenderTemp[fieldName] = value;
        if (fieldObject.type !== 'text' && fieldObject.type !== 'dropdownText' && (submissionRenderTemp[fieldName] === '' || submissionRenderTemp[fieldName] === oldValue)) {
          delete submissionRenderTemp[fieldName];
          // console.log('deleting!!!!')
        }
        // console.log('submissionRenderTemp');
        // console.log(submissionRenderTemp);
        setSubmissionRender(submissionRenderTemp);
      };

      const isControlled = fieldObject.hasOwnProperty('controlField')
        && fieldObject.controlField
        && fieldObject.controlType;
      const isAutoChange = isControlled && fieldObject.controlType === 'autoChange';
      const fieldObjectProperties = {
        appContext,
        fieldName,
        fieldObject,
        isAutoChange,
        isControlled,
        submissionValue: submissionRender[fieldName],
        setSubmission: setSubmissionRender,
        setSubmissionWithEvent,
        setSubmissionWithOutEvent,
        errorValue: errorsRender.hasOwnProperty(fieldName) ? errorsRender[fieldName] : '',
        setErrorsUpdateField,
        setErrorsRemoveField,
        level,
      };

      // start of processing input elements
      if (!submissionRender.hasOwnProperty(fieldName)) {
        return <React.Fragment key={fieldName}></React.Fragment>;
      }

      if (fieldObject.type.includes('text')) {
        return (
          <TextFieldComponent
            key={fieldName}
            {...fieldObjectProperties}
            textFieldExtraProps={{
              multiline: fieldObject.multiline,
              rows: fieldObject.rows,
            }}
          />);
      } else if (fieldObject.type.includes('date')) {
        return (
          <TextFieldComponent
            key={fieldName}
            {...fieldObjectProperties}
            textFieldExtraProps={{
              type: 'date',
              InputProps: {
                inputProps: fieldObject.inputAttributes,
              },
            }}
          />
        );
      } else if (fieldObject.type.includes('dropdown')) {
        return (
          <DropdownComponent
            key={fieldName}
            {...fieldObjectProperties}
            // TODO: check if we can rather not pass this prop, use the default `isControlled` value, or always rather assume that it is true internally, instaed of forcing it via props.
            isControlled={true}
          />
        );
      } else if (fieldObject.type.includes('radio')) {
        return (
          <RadioGroupComponent
            key={fieldName}
            {...fieldObjectProperties}
          />
        );
      } else if (fieldObject.type === 'dynamicField') {
        return (
          <DynamicFieldComponent
            key={fieldName}
            submission={submissionRender}
            {...fieldObjectProperties}
          />
        );
      } else {
        // TODO: add switches
        // TODO: add sliders
        // TODO: add checkboxes
        console.log(`Unknown type: ${fieldObject.type}`);
        return <React.Fragment key={fieldName}></React.Fragment>;
      }
    };

    return (
      <Grid
        container
        alignContent='flex-start'
        spacing={8}
        className={classes.container}
      >
        {!isChild && <Grid item>
          <Typography variant={formTitleTypographyVariant}>{formTitle}</Typography>
        </Grid>}
        <Grid item container spacing={16}>
          {fieldsObject &&
            Object.keys(fieldsObject).length > 0 &&
            Object.entries(fieldsObject).map((fieldObjectEntry) => {
              return renderFieldObject(fieldObjectEntry, submission, setSubmissionActual, errors, setErrors);
            })
          }
          {submissionEnabled && <Button
            style={{ margin: '8px 5px' }}
            fullWidth
            type='submit'
            onClick={handleDataSubmission}
            variant='contained'
            disabled={!submissionIsValid}
          >
            {buttonLabel}
          </Button>}
          {submissionEnabled && formSuccess && (
            <span style={{ color: 'green', margin: '10px 0px 10px 10px', fontFamily: "'Poppins', sans-serif" }}>
              {formSuccess}
            </span>
          )}
          {formError && (
            <span style={{ color: 'red', margin: '10px 0px 10px 10px' }}>
              {formError}
            </span>
          )}
          {resetEnabled && <Button
            style={{ margin: '8px 5px' }}
            fullWidth
            onClick={() => setSubmissionActual(submissionDefault)}
            variant='contained'
          >
            Reset
          </Button>}
        </Grid>
      </Grid>
    );
  }
};

FormEngine.defaultProps = {
  formConfig: {},
  resetEnabled: true,
  submissionEnabled: false,
  existingFields: {},
  existingErrors: {},
  existingSubmission: {},
  existingSubmissionId: '',
  setExistingSubmission: false,
  existingCollectionRef: false,
  level: 0,
};

FormEngine.propTypes = {
  appContext: PropTypes.object.isRequired,
  resetEnabled: PropTypes.bool,
  submissionEnabled: PropTypes.bool,
  formConfig: PropTypes.object,
  existingFields: PropTypes.object,
  existingErrors: PropTypes.object,
  existingSubmission: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  existingSubmissionId: PropTypes.string,
  setExistingSubmission: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  existingCollectionRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  level: PropTypes.number,
};

export default withStyles(styles)(FormEngine);
