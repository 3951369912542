import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const styles = {
  outerDiv: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paperContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
};

const TableauIFrame = (props) => {
  const { classes, appContext, ms, ticket } = props;
  const { dimensions, url } = appContext;
  const sidePanelWidth = appContext.drawerOpen ? dimensions.openSideNav : dimensions.collapsedSideNav;
  const paperHeight = dimensions.height - (dimensions.topPanel + 50 /*2x top and bottom margins*/);
  const paperWidth = dimensions.width - (50 + sidePanelWidth);
  return (
    <div className={classes.outerDiv}>
      <Paper
        className={classes.paperContainer}
        elevation={14}
        square
        id='tableauPaper'
      >
        <iframe
          title={ms.title}
          src={`${url}${ticket}`}
          frameborder='0'
          width={paperWidth}
          height={paperHeight}
          name='tableauFrame'
          id='tableauFrame'
        />
      </Paper>
    </div>
  );
};

TableauIFrame.propTypes = {
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  ms: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableauIFrame);
