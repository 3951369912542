import { getCurrentYMD, ColorCodes } from '../../helpers';
import { schemaProps, stringTypeDefaultValidation } from './schemaMasterData';

const statusOptions = [
  'To Do',
  'In Progress',
  'Lagging',
  'Delayed',
  'Closed',
  'Completed',
];

export const taskFormSchema = {
  name: 'Task',
  icon: 'add_task',
  client: 'tHA-HPP',
  filterField: 'status',
  filterOptions: statusOptions,
  collectionPath: '/projects/#/tasks',
  subCollection: 'tasks',
  isSubCollection: true,
  requiresProjects: true,
  hasProjectsDropdown: true,
  hasElementItems: true,
  schema: {
    name: {
      label: 'Task Name',
      name: 'name',
      ...schemaProps,
      sm: 4,
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter task name',
        },
      },
    },
    assignTo: {
      label: 'Assign To',
      name: 'assignTo',
      ...schemaProps,
      fieldIndex: 4,
      sm: 4,
      selectUser: true,
      chipStyle: (chipItem) => {
        return { padding: '0px', fontSize: '1em' };
      },
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please select name',
        },
        minLength: {
          value: 1,
          message: 'Please assign at least one person to the task',
        },
      },
    },
    priority: {
      label: 'Priority',
      name: 'priority',
      ...schemaProps,
      select: true,
      fieldIndex: 2,
      sm: 4,
      options: ['Low', 'Medium', 'High', 'Critical'],
      chipStyle: (chipItem) => {
        return {
          padding: '0px',
          fontSize: '1em',
          color: 'white',
          background: Object.keys(ColorCodes)
            .filter((key) => key.includes(chipItem))
            .reduce((obj, key) => {
              return ColorCodes[key];
            }, ''),
        };
      },
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter task priority',
        },
      },
    },
    startDate: {
      label: 'Start Date',
      name: 'startDate',
      ...schemaProps,
      type: 'date',
      fieldIndex: 0,
      defaultValue: getCurrentYMD(),
      chipStyle: (chipItem) => {
        return { padding: '0px', fontSize: '1em' };
      },
      InputLabelProps: {},
      InputProps: {
        inputProps: { min: '1970-01-01', max: '' },
      },
      validation: {
        required: { value: true, message: 'Please pick the start date' },
      },
    },
    plannedEndDate: {
      label: 'Planned End Date',
      name: 'plannedEndDate',
      ...schemaProps,
      type: 'date',
      fieldIndex: 1,
      defaultValue: getCurrentYMD(),
      chipStyle: (chipItem) => {
        return { padding: '0px', fontSize: '1em' };
      },
      InputLabelProps: {},
      InputProps: {
        inputProps: { min: '1970-01-01', max: '' },
      },
      validation: {
        required: { value: true, message: 'Please pick the planned end date' },
      },
    },
    status: {
      label: 'Status',
      name: 'status',
      ...schemaProps,
      select: true,
      fieldIndex: 3,
      sm: 4,
      options: statusOptions,
      controlFieldAllValues: statusOptions,
      controlledFields: ['actualEndDate'],
      chipStyle: (chipItem) => {
        return {
          padding: '0px',
          fontSize: '1em',
          color: 'white',
          background: Object.keys(ColorCodes)
            .filter((key) => key.includes(chipItem))
            .reduce((obj, key) => {
              return ColorCodes[key];
            }, ''),
        };
      },
      validation: {
        ...stringTypeDefaultValidation,
        required: {
          ...stringTypeDefaultValidation.required,
          message: 'Please enter task status',
        },
      },
    },
    actualEndDate: {
      label: 'Actual End Date',
      name: 'actualEndDate',
      ...schemaProps,
      type: 'date',
      controlField: 'status',
      controlFieldAllowedValues: 'not_default',
      sm: 4,
      defaultValue: getCurrentYMD(),
      InputLabelProps: { shrink: true },
      InputProps: {
        inputProps: {
          min: '1970-01-01',
          max: getCurrentYMD(),
        },
      },
      validation: {
        required: { value: true, message: 'Please pick the actual end date' },
      },
    },
    comments: {
      label: 'Notes/Comments',
      name: 'comments',
      ...schemaProps,
      multiline: true,
      sm: 4,
      validation: {
        required: { value: false, message: '' },
      },
    },
  },
};
